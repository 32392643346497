import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CakeIcon from "@mui/icons-material/Cake";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Checkbox, Divider, FormControlLabel, Grid, IconButton, InputLabel, Radio, RadioGroup } from "@material-ui/core";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { useHistory } from "react-router";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Box, Button } from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import PersonIcon from "@mui/icons-material/Person";
import Drawer from "@mui/material/Drawer";
import EmailIcon from "@mui/icons-material/Email";
import BackupIcon from "@mui/icons-material/Backup";
import { FixedSizeList as List } from "react-window";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import CallIcon from "@mui/icons-material/Call";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CelebrationIcon from "@mui/icons-material/Celebration";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Groups2Icon from "@mui/icons-material/Groups2";
import "./style.css";
import "./filter.sass";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FileUploader from "./FileUploader";
import ModeIcon from "@mui/icons-material/Mode";
import dayjs from "dayjs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Slider } from "@mui/material";
import AvatarEditor from "react-avatar-editor";

import {
  getEmployeeList,
  getMyTeamDetails,
  editEmployeeById,
  getPayrollAggregate,
  getEmployeeDepartment,
  getEmployeeStateName,
  getLocationList,
  getUserProfileImage,
  updateImage,
  getMedicalInfo
} from "../../../actions/loginActions";
import {
  getBirthdayReminder,
  getDisplayHolidaysList,
  getAttendanceMaster,
  getEmploymentDetails,
  getCertificates,
  AddMedicalInfo, 
  EditMedicalInfo,
  deleteMedicalInfo,
  getEducationDetails
} from "../../actions/EmployeeActions";
import { svSE } from "@mui/x-data-grid";
import { getDecorators } from "typescript";
import { kMaxLength } from "buffer";
import { Dialog } from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddExperienceModal from "./addExperienceModal";
import EditExperienceModal from "./editExperienceModal";
import DeleteExperienceModal from "./DeleteExperienceModal";
import AddCerificateModal from "./addCerificateModal";
import EditCertificate from "./editCertificate";
import DeleteCerificate from "./deleteCerificate";
import AddEducation from "./addEducation";
import EditEducation from "./editEducation";
import DeleteEducation from "./deleteEducation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

interface Props {
  getDisplayHolidaysList: Function;
  holidayList: any;
  getEmployeeList: any;
  employeesList: any;
  getBirthdayReminder: any;
  birthday_reminder: any;
  editEmployeeById: any;
  getAttendanceMaster: Function;
  attendanceData: any;
  getMyTeamDetails: any;
  team_details: any;
  leaveComponents: any;
  getPayrollAggregate: any;
  payroll_aggregate: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  locationList: any;
  getLocationList: any;
  getEmployeeStateName: any;
  employeeStateName: any;
  getUserProfileImage: any;
  getProfileImage: any;
  updateImage: any;
  postProfileImage: any;
  getEmploymentDetails:any;
  employmentDetailsList:any;
  certificateList:any;
  getCertificates:any;
  AddMedicalInfo:any;
  getMedicalInfo:any;
  medicalData:any;
  EditMedicalInfo:any;
  getEducationDetails:any;
  educationList:any;
}

interface PayrollInfo {
  salary: string;
  bankAccount: string;
  taxRate: string;
}
const CustomerLeadFilterPage: React.FC<Props> = ({
  getDisplayHolidaysList,
  holidayList,
  getEmployeeList,
  employeesList,
  getBirthdayReminder,
  birthday_reminder,
  editEmployeeById,
  getAttendanceMaster,
  attendanceData,
  getMyTeamDetails,
  team_details,
  leaveComponents,
  getPayrollAggregate,
  payroll_aggregate,
  getEmployeeDepartment,
  departmentNames,
  locationList,
  getLocationList,
  getEmployeeStateName,
  employeeStateName,
  getUserProfileImage,
  getProfileImage,
  updateImage,
  postProfileImage,
  getEmploymentDetails,
  employmentDetailsList,
  certificateList,
  getCertificates,
  AddMedicalInfo,
  getMedicalInfo,
  medicalData,
  EditMedicalInfo,
  getEducationDetails,
  educationList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showSalary, setShowSalary] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [changePosition, setChangePosition] = React.useState(false);
  const [gmail, setGmail] = React.useState(false);
  const [manager, setManager] = React.useState(false);
  const dif = dayjs(attendanceData.check_in).diff(
    dayjs(attendanceData.check_out)
  );
  const [editMode, seteditMode] = useState<boolean>(false);
  const [editContType, setEditContType] = React.useState("");
  const [editContTag, setEditContTag] = React.useState("");
  const [relationType, setRelationType] = React.useState("");
  const [employeeUUID, setEmployeeUUID] = useState<any>(employeesList[0]?.uuid);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [fileLink, setFileLink] = useState<any>("");
  const [empDepartmentId, setEmpDepartmentId] = useState<any>();
  const [openAddExpModel, setOpenAddExpModel] = React.useState(false);
  const [openEditExpModel, setOpenEditExpModel] = React.useState(false);
  const [openDeleteExpModal, setOpenDeleteExpModal] = React.useState(false);
  const [openAddCertificationModal, setOpenAddCertificationModal] = React.useState(false);
  const [openEditCertificationModal, setOpenEditCertificationModal] = React.useState(false);
  const [selectedEdit, setSelectedEdit] = React.useState(null);
  const [selectedCertificateData, setSelectedCertificateData] = React.useState(null);
  const [selectedDelete, setSelectedDelete] = React.useState(null);
  const [deleteId, setDeleteId]= React.useState(null);
  const [openDeleteCertModal, setOpenDeleteCertModal] = React.useState(false);
  const [formatedData,setFormatedData] = React.useState<any>([])
  const [selecetdEducation, setSelecetdEducation] = React.useState(null)
  const [openAddEducation, setOpenAddEducation] = React.useState(false);
  const [openEditEducation, setOpenEditEducation] = React.useState(false);
  const [openDeleteEducation, setOpenDeleteEducation] = React.useState(false);
  const timer = useRef<any>(0);

  const [emp, setEmp] = useState({
    empl_id: "",
    fullName: "",
    designation: "",
    contact_number: "",
    address: "",
    email: "",
    date_of_birth: "",
    gender: "",
    department: "",
    date_of_joining: "",
    blood_group: "",
    challenged: "",
    marital_status: "",
    Spouse: "",
    emergency_number: "",
    spouse_dob: "",
    anniversary: "",
    emp_stage: "",
    emp_type: "",
    emp_grade: "",
    prob_end_date: "",
    date_confirm: "",
    notice_period: "",
    exit_date: "",
    employee_current_state: "",
    city: "",
    postal_code: "",
    country: "",
    add_type: "",
    add_line_one: "",
    add_type_two: "",
    cont_type: "",
    cont_tag: "",
    relation_type: "",
    dep_first_name: "",
    dep_last_name: "",
    dep_emergency_number: "",
    emp_manager_id: "",
    empl_manager_email: "",
    empl_manager_name: "",
    address_line_one: "",
  });

  useEffect(()=>{
    if(medicalData?.length){
      setIsCovidVaccinated(medicalData[0]?.is_covid_vaccinated)
      
    }
  },[medicalData])

  useEffect(() => {
    if (employeesList && employeesList.count > 0) {
      const firstEmployee = employeesList.results[0];

      if (firstEmployee) {
        setEmployeeUUID(firstEmployee.uuid);
        setEmp({
          empl_id: firstEmployee.employee_id,
          fullName: firstEmployee.fullname,
          designation: firstEmployee.employee_designation,
          contact_number: firstEmployee.contact_number,
          email: firstEmployee.email,
          date_of_birth: firstEmployee.date_of_birth,
          gender: firstEmployee.gender,
          department: firstEmployee.department,
          date_of_joining: firstEmployee.date_of_joining,
          blood_group: firstEmployee.blood_group,
          challenged: firstEmployee.challenged,
          marital_status: firstEmployee.marital_status,
          Spouse: firstEmployee.spouse,
          address: firstEmployee.address,
          emergency_number: firstEmployee.emergency_contact_number,
          spouse_dob: firstEmployee.spouse_dob,
          anniversary: firstEmployee.anniversary,
          emp_stage: firstEmployee.employment_stage,
          emp_type: firstEmployee.employment_type,
          emp_grade: firstEmployee.employment_grade,
          prob_end_date: firstEmployee.probation_end_date,
          date_confirm: firstEmployee.date_confirmation,
          notice_period: firstEmployee.notice_period,
          exit_date: firstEmployee.exit_date,
          employee_current_state: firstEmployee.employee_current_state,
          city: firstEmployee.city,
          postal_code: firstEmployee.postal_code,
          country: firstEmployee.country,
          add_type: firstEmployee.add_type,
          add_line_one: firstEmployee.add_line_one,
          add_type_two: firstEmployee.add_type_two,
          cont_type: firstEmployee.cont_type,
          cont_tag: firstEmployee.cont_tag,
          relation_type: firstEmployee.relation_type,
          dep_first_name: firstEmployee.dep_first_name,
          dep_last_name: firstEmployee.dep_last_name,
          dep_emergency_number: firstEmployee.dep_emergency_number,
          emp_manager_id: firstEmployee.manager_id,
          empl_manager_email: firstEmployee.manager_email,
          empl_manager_name: firstEmployee.manager_name,
          address_line_one: firstEmployee.address,
        });
      }
    }
  }, [employeesList]);


  useEffect(()=>{
    if(employmentDetailsList?.length){
      const data = groupByCompany(employmentDetailsList)
      setFormatedData(data)
    }
  },[employmentDetailsList])

  const toggleeditMode = () => {
    seteditMode(!editMode);
  };

  const saveChanges = async (index: any) => {
    seteditMode(false);
    const body = { ...emp };
    // const employeeUUID = employeesList[0]?.uuid;
    await editEmployeeById(body, employeeUUID, empDepartmentId);
    // history.push("/dashboard/payrollManager/employee-master");
  };

  const [editAdd, setEditAdd] = React.useState<any>({
    addtype: "",
    addLineOne: "",
    addLineTwo: "",
    countryName: "",
    contState: "",
    city: "",
    postalCode: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e: any) => {
    const value = e.target.value;

    if (/^[6789]\d{0,9}$/.test(value) || value == "") {
      setEmp({
        ...emp,
        contact_number: value,
      });
      setError("");
    } else {
      setError(
        "Contact number must start with 6, 7, 8, or 9 and be 10 digits long"
      );
    }
  };
  {console.log(fileLink,"fileLink>>")}
  useEffect(() => {
    getDisplayHolidaysList();
    getBirthdayReminder();
    getAttendanceMaster();
    getMyTeamDetails();
    getPayrollAggregate("");
    getEmployeeList("");
    getEmployeeDepartment("");
    setEmployeeUUID(employeesList.uuid);
    getEmploymentDetails();
    getCertificates();
    getMedicalInfo();
    getEducationDetails();
  }, []);
  const toggleShowSalary = () => {
    setShowSalary(!showSalary);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setIsDrawerOpen(open);
    };

  // States for three sections
  const [showSection, setShowSection] = useState<any>([
    {
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: false,
    },
  ]);
  useEffect(() => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: false,
    }));
  }, []);

  const handleUserData = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: false,
    }));
  };

  const handleProfileSection = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: true,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: false,
    }));
  };



  const handleExperience= () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: false,
      showDocs: false,
      showExperience: true,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: false,
    }));
  };

  const handleCertification = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: true,
      showMedicalInfo: false,
      showEducation: false,
    }));
  };

  const handleMedicalTab = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: true,
      showEducation: false,
    }));
  };

  const handleEducationTab = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: false,
      showDocs: false,
      showExperience: false,
      showCertification: false,
      showMedicalInfo: false,
      showEducation: true,
    }));
  };


  useEffect(() => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: true,
    }));
    setProfileProperty(() => ({
      ...profileProperty,
      repOffice: true,
    }));
    setShowDocument(() => ({
      ...showDocuments,
      userIdentity: true,
    }));
  }, []);

  const [showProperty, setShowProperty] = useState<any>({
    showEditAbout: false,
    showAbout: false,
    showAdd: false,
    showContact: false,
    showBiodata: false,
    showDoB: false,
    showDep: false,
    updateAdd: false,
    updateContact: false,
    updateDependents: false,
    addCard: false,
    contactCard: false,
    depCard: false,
  });

  const handleShow = (newShowstate: any) => {
    let oldShowState = {
      showEditAbout: false,
      showAbout: false,
      showAdd: false,
      showContact: false,
      showBiodata: false,
      showDoB: false,
      showDep: false,
      updateAdd: false,
      updateContact: false,
      updateDependents: false,
      addCard: false,
      contactCard: false,
      depCard: false,
    };

    setShowProperty(() => ({
      ...oldShowState,
      ...newShowstate,
    }));
  };

  // Work Profiles
  const [profileProperty, setProfileProperty] = useState<any>({
    workDet: false,
    repOffice: false,
    currExp: false,
    pastExp: false,
  });

  const handleWorkProfile = (newProfileState: any) => {
    let oldProfileState = {
      workDet: false,
      repOffice: false,
      currExp: false,
      pastExp: false,
    };
    setProfileProperty(() => ({
      ...oldProfileState,
      ...newProfileState,
    }));
  };

  const [showDocuments, setShowDocument] = useState<any>({
    userIdentity: false,
    userOthers: false,
    editUserIdentity: false,
    editOthers: false,
  });

  const handleDocs = (newDocState: any) => {
    let oldDocState = {
      userIdentity: false,
      userOthers: false,
      editUserIdentity: false,
      editOthers: false,
    };
    setShowDocument(() => ({
      ...oldDocState,
      ...newDocState,
    }));
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const [fileTypeErrorSalary, setFileTypeErrorSalary] = useState<any>("");
  const [file_nameSalary, setFileNameSalary] = React.useState<string>("");
  const [fileSalary, setFileSalary] = React.useState<any>([]);
  const [buttonState, setButtonState] = useState<boolean>(false);

  const handleFileChangeSalary = (e: React.ChangeEvent<{ files: any }>) => {
    setFileSalary(e.target.files[0]);
    if (e.target.files[0]?.type === "text/csv") {
      setFileNameSalary(e.target.files[0].name);
      setFileTypeErrorSalary("");
    } else {
      setFileTypeErrorSalary("Please select csv file");
    }
  };

  const submitFileSalary = async () => {
    const formData = new FormData();
    formData.append("file", fileSalary);
    // await postBulkUploaderEmployee(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
    setFileNameSalary("");
    setFileSalary("");
  };

  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [editorOpen, setEditorOpen] = useState<any>(false);
  const [scale, setScale] = useState<any>(1);
  const editorRef = useRef<any>(null);
  const [imageURL2, setImageURL2] = useState<any>("");
  const [file, setFile] = React.useState<any>();
  const [isCovidVaccinated, setIsCovidVaccinated] = useState<any>(false);
  const [errors, setErrors] = useState({
    fileSize: false,
});

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setImageUrl(reader.result);
        setEditorOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (employeeUUID) {
      getUserProfileImage(`${employeeUUID}`);
    }
  }, [employeeUUID]);

  useEffect(() => {
    if (getProfileImage) {
      setImageURL2(getProfileImage.profile_picture);
    }
  }, [getProfileImage]);

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      setImageUrl(canvas.toDataURL());
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          const file = new File([blob], "profile-image.png", {
            type: "image/png",
          });
          setImage(file);
          console.log("File:", file);
        }
      }, "image/png");
      setEditorOpen(false);
      handleUploadImage();
      history.push("/dashboard/employee");
    }
  };

  const handleUploadImage = () => {
    const formData = new FormData();
    formData.append("profile_picture", image);
    console.log(image, "file1");
    updateImage({ id: employeeUUID, data: formData });
  };

const calculateDateDifference = (startDate:any, endDate:any) => {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date();

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return { years: 0, months: 0 };
  }

  const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  return { years, months };
};

const calculateTotalExperience = (roles:any) => {
  let totalMonths = 0;
  roles.forEach((role:any) => {
    const { years, months } = calculateDateDifference(role.start_date, role.end_date);
    totalMonths += years * 12 + months;
  });

  const totalYears = Math.floor(totalMonths / 12);
  const remainingMonths = totalMonths % 12;
  return { totalYears, remainingMonths };
};

const groupByCompany = (data:any) => {
  return data.reduce((acc:any, current:any) => {
    const normalizedCompany = current.company_name.trim().toLowerCase();
    if (!acc[normalizedCompany]) {
      acc[normalizedCompany] = [];
    }
    acc[normalizedCompany].push(current);
    return acc;
  }, {});
};

const formatDate = (dateString:any) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' }; 
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const handleRedirect = (URL:any) => {
  if (URL) {
    window.location.href = URL;
  }
};

const yearFormat = (dateString:any) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric'}; 
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const fileSelectedHandler = async (e: React.ChangeEvent<{ files: any }>) => {
  const selectedFile = e.target.files[0];
  if (selectedFile.size > 10 * 1024 * 1024) {
      setErrors((prev) => ({ ...prev, fileSize: true }));
  } else {
      setFile(selectedFile);
      setErrors((prev) => ({ ...prev, fileSize: false }));
  }
};

const validateForm = () => {
  const newErrors = {
      fileSize: file && file.size > 10 * 1024 * 1024,
  };

  setErrors(newErrors);

  return !Object.values(newErrors).includes(true);
};
  const handleSubmit = async() =>{
  const isValid = validateForm();
  if (!isValid) {
      return;
  }
  const formData = new FormData();
      const empId = employeesList?.results &&
      employeesList?.results?.length > 0 &&
      employeesList?.results[0]?.uuid;
      formData.append("employee", empId);
      formData.append("is_covid_vaccinated", isCovidVaccinated);
      if(file){
      formData.append("document", file);
      }

      await AddMedicalInfo(formData);
      setIsCovidVaccinated(false);
      setFile(null);
      await getMedicalInfo()
  }

  const handleEditSubmit = async() =>{
    const isValid = validateForm();
    if (!isValid) {
        return;
    }
    const formData = new FormData();
        const empId = employeesList?.results &&
        employeesList?.results?.length > 0 &&
        employeesList?.results[0]?.uuid;
        formData.append("employee", empId);
        formData.append("is_covid_vaccinated", isCovidVaccinated);
        if(file){
        formData.append("document", file);
        }
  
        await EditMedicalInfo(formData , medicalData[0]?.id);
        setIsCovidVaccinated(false);
        setFile(null);
        await getMedicalInfo()
  }

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="contentHeader">
          <div className="leftContentHeader">
            <h2 style={{ textAlign: "center", color: "rgb(14, 63, 108)" }}>
              Welcome to Celara{" "}
            </h2>
          </div>
        </div>

        <div className="contentContainer">
          <div className="card-main">
            <div className="left-card-cont">
              <Card
                sx={{
                  minWidth: "320px",
                  maxWidth: "100%",
                  boxShadow: "lg",
                }}
                style={{ backgroundColor: "#e9ecef", width: "357px" }}
                className="user-card"
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    width: "auto",
                  }}
                >
                  <div className="profile-card">
                    <div style={{ textAlign: "center" }}>
                      {/* <Avatar
                        src="/static/images/avatar/1.jpg"
                        style={{ margin: "10px auto" }}
                        sx={{ "--Avatar-size": "8rem" }}
                      /> */}
                      <div style={{ padding: "0px 60px" }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="upload-button"
                        />
                        <label htmlFor="upload-button">
                          <Avatar
                            // alt="User Avatar"
                            src={imageURL2 || "/static/images/avatar/1.jpg"}
                            sx={{ "--Avatar-size": "8rem" }}
                            style={{
                              margin: "10px auto",
                              backgroundColor: "rgb(189 189 189 / 55%)",
                              cursor: "pointer",
                              border: "1px solid black",
                            }}
                          />
                        </label>

                        <Dialog
                          open={editorOpen}
                          onClose={() => setEditorOpen(false)}
                        >
                          <div style={{ padding: "20px" }}>
                            {imageUrl && (
                              <AvatarEditor
                                ref={editorRef}
                                image={imageUrl}
                                width={200}
                                height={200}
                                borderRadius={100}
                                border={50}
                                scale={scale}
                              />
                            )}
                            <Slider
                              value={scale}
                              min={1}
                              max={3}
                              step={0.01}
                              onChange={(e: any, newValue: any) =>
                                setScale(newValue)
                              }
                            />
                            <Button color="primary" onClick={handleSave}>
                              Upload
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => setEditorOpen(false)}
                            >
                              Cancel
                            </Button>{" "}
                          </div>
                        </Dialog>
                      </div>

                      <div style={{ padding: "8px 10px" }}>
                        <Typography level="title-lg">
                          {employeesList?.results &&
                            employeesList?.results?.length > 0 &&
                            employeesList?.results[0]?.fullName}
                        </Typography>
                        <Typography
                          level="body-sm"
                          sx={{
                            maxWidth: "24ch",
                            marginLeft: "auto  ",
                            marginRight: "auto",
                            fontSize: "1.5rem",
                            fontWeight: "700",
                          }}
                        >
                          {employeesList?.results &&
                            employeesList?.results?.length > 0 &&
                            employeesList?.results[0]?.fullname}
                        </Typography>
                      </div>
                    </div>

                    <div>
                      <div className="userDetails">
                        <ul>
                          <li className="user-item">
                            {employeesList?.results &&
                            employeesList?.results?.length > 0 ? (
                              <span>
                                <DeveloperModeIcon />{" "}
                                {employeesList?.results &&
                                  employeesList?.results?.length > 0 &&
                                  employeesList?.results[0]?.employee_designation}
                              </span>
                            ) : (
                              <span>
                                <DeveloperModeIcon /> Position
                              </span>
                            )}
                          </li>
                          <li className="user-item">
                            {employeesList?.results &&
                            employeesList?.results?.length > 0 ? (
                              <span>
                                <EmailIcon />
                                {employeesList?.results &&
                                  employeesList.results.length > 0 &&
                                  employeesList.results[0]?.email &&
                                  (employeesList.results[0].email.length >
                                  20 ? (
                                    <span>
                                      {employeesList.results[0].email.slice(
                                        0,
                                        21
                                      )}
                                      ...
                                    </span>
                                  ) : (
                                    <span>
                                      {employeesList.results[0].email}
                                    </span>
                                  ))}
                              </span>
                            ) : (
                              <span>
                                <EmailIcon />
                                Gmail
                              </span>
                            )}
                          </li>
                          <li className="user-item">
                            {employeesList?.results &&
                            employeesList?.results?.length > 0 ? (
                              <span>
                                <ManageAccountsIcon />
                                {employeesList &&
                                  employeesList?.results?.length > 0 &&
                                  employeesList?.results[0]?.manager_name}
                              </span>
                            ) : (
                              <span>
                                <ManageAccountsIcon /> Manager
                              </span>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "fit-content",
                        }}
                        className="text-center d-flex flex-column align-items-center justify-content-center gap-2"
                      >
                        <AvatarGroup max={4} onClick={toggleDrawer(true)}>
                          <Avatar
                            style={{ backgroundColor: "grey", color: "white" }}
                            src="1"
                          />
                        </AvatarGroup>
                        <p
                          className="pt-2"
                          style={{ color: "#413c9f", fontWeight: 600 }}
                        >
                          My Team
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="cards-container">
              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "8px 10px",
                    padding: "10px 15px",
                    backgroundColor: "#e9ecef",
                    overflowY: "scroll",
                    height: "260px",
                  }}
                  className="hover-scrollbar  card-comp"
                >
                  <Typography level="title-md" className="card-head">
                    <CelebrationIcon /> Upcoming Celebrations
                  </Typography>
                  <CardContent>
                    <ul style={{ padding: 0 }}>
                      {birthday_reminder &&
                        birthday_reminder.length > 0 &&
                        birthday_reminder?.map(
                          (birthday: any, index: number) => (
                            <li
                              key={birthday.employee_id}
                              style={{
                                listStyleType: "none",
                                marginBottom: "8px",
                              }}
                            >
                              <CakeIcon /> {birthday.date_of_birth} :{" "}
                              {birthday.fullname}
                            </li>
                          )
                        )}
                    </ul>
                  </CardContent>
                </Card>
              </div>

              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "8px 10px",
                    padding: "10px 15px",
                    backgroundColor: "#e9ecef",
                    overflowY: "scroll",
                    height: "260px",
                  }}
                  className="hover-scrollbar card-comp"
                >
                  <Typography level="title-md" className="card-head">
                    <FormatListBulletedIcon /> Attendance Records{" "}
                  </Typography>

                  {attendanceData.res &&
                    attendanceData?.length > 0 &&
                    attendanceData.map((user: any, index: number) => {
                      return index === 0 ? (
                        <CardContent>
                          <ul>
                            <li>
                              <AccessAlarmIcon />
                              Total Hours: {dif}
                            </li>
                            <li>
                              <HourglassBottomIcon />
                              Status: {user.status}
                            </li>
                            <li>
                              <ArrowOutwardIcon />
                              Punch In Time : {user.check_in.slice(11, 19)}
                            </li>
                            <li>
                              <CallReceivedIcon />
                              Punch Out Time: {user.check_out.slice(11, 19)}
                            </li>
                          </ul>
                          <Button
                            onClick={() =>
                              history.push(
                                `/dashboard/payrollManager/Attendance-Master`
                              )
                            }
                            style={{
                              padding: "5px 6px",
                              backgroundColor: "rgb(14, 63, 108)",
                              color: "white",
                            }}
                          >
                            Show More
                          </Button>
                        </CardContent>
                      ) : null;
                    })}
                </Card>
              </div>

              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "8px 10px",
                    padding: "10px 15px",
                    backgroundColor: "#e9ecef",
                    overflowY: "scroll",
                    height: "260px",
                  }}
                  className="card-comp"
                >
                  <Typography level="title-md" className="card-head">
                    <PaymentsIcon /> Payroll
                  </Typography>
                  {/* <CardContent>
                    <ul>
                      <li>
                        <AccountBalanceIcon />
                        Bank Account:
                        {showSalary ? employeesList && employeesList?.length > 0 && employeesList[0]?.bank_account_number : "XXXXXXXXXXXX"}
                      </li>
                      <li>
                        <CurrencyRupeeIcon /> Salary:{" "}
                        {showSalary ? payroll_aggregate.net_amount : "XXXXX"}
                        {showSalary && (
                          <span className="eye-icon" onClick={toggleShowSalary}>
                            <RemoveRedEyeIcon />
                          </span>
                        )}
                        {!showSalary && (
                          <span className="eye-icon" onClick={toggleShowSalary}>
                            <VisibilityOffIcon />
                          </span>
                        )}
                      </li>
                    </ul>

                    <Button
                      style={{
                        padding: "5px 6px",
                        backgroundColor: "rgb(14, 63, 108)",
                        color: "white",
                      }}

                      onClick={() => history.push(`/dashboard/payrollManager/payslip`)}
                    >
                      Show More
                    </Button>
                  </CardContent> */}
                </Card>
              </div>

              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "8px 10px",
                    padding: "10px 15px",
                    backgroundColor: "#e9ecef",
                    overflowY: "scroll",
                    height: "260px",
                    scrollbarWidth: "thin",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="card-head"
                  >
                    <EmojiEmotionsIcon style={{ marginRight: "10px" }} />
                    Upcoming Holidays
                  </Typography>
                  <CardContent>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {holidayList &&
                        holidayList.length > 0 &&
                        holidayList?.map((holiday: any, index: number) => (
                          <li key={index}>
                            {holiday.holiday_name} {holiday.is_optional ? "(Optional)" : ""} - {holiday.date}
                          </li>
                        ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>

         <section className="user-sec">
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px ",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <div className="user-Section">
                <div className="inner-cont">
                  <div className="user-info " onClick={handleUserData}>
                    <button id="info-btn">Personal Info</button>
                  </div>
                  <div className="user-work" onClick={handleProfileSection}>
                    <button id="work">Reporting Office</button>
                  </div>
                  <div className="user-doc" onClick={handleExperience}>
                    <button id="doc">Experience</button>
                  </div>
                  <div className="user-doc" onClick={handleCertification}>
                    <button id="doc">Certification</button>
                  </div>
                  <div className="user-doc" onClick={handleMedicalTab}>
                    <button id="doc">Medical History</button>
                  </div>
                  <div className="user-doc" onClick={handleEducationTab}>
                    <button id="doc">Education</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>

          {showSection.showUserdata && (
            <section id="user-details">
              <Grid
                container
                spacing={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <div className="ud-container">
                    <div className="ud-left-outer">
                      <div className="ud-left-inner">
                        <div
                          className="us-about"
                          onClick={() => {
                            handleShow({ showAbout: true });
                          }}
                        >
                          <PersonIcon id={"ud-left-icon"} />
                          <h6>About</h6>
                        </div>
                        <div
                          className="us-biodata"
                          onClick={() => {
                            handleShow({ showBiodata: true });
                          }}
                        >
                          <DescriptionIcon id={"ud-left-icon"} />
                          <h6>Biodata</h6>
                        </div>

                        <div
                          className="us-dates"
                          onClick={() => {
                            handleShow({ showDoB: true });
                          }}
                        >
                          <CalendarMonthIcon id={"ud-left-icon"} />
                          <h6>Important dates</h6>
                        </div>
                      </div>
                    </div>

                    <div className="middle">
                      <div className="scroll-vertical"></div>
                    </div>

                    <div className="ud-right">
                      <div className="ud-right-cont">
                        <div className="ud-right-inner">
                          <div className="edit-bio">
                            <div className="user-biodata">
                              {/* Show About */}
                              {showProperty.showAbout && (
                                <div
                                  className="user-about"
                                  onClick={() => {
                                    handleShow({ showAbout: true });
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={12} sm={12}>
                                      <div className="aboutEditSec">
                                        {employeesList &&
                                          employeesList.results &&
                                          employeesList.count > 0 &&
                                          employeesList.results.map(
                                            (employee: any, index: number) => {
                                              return (
                                                <>
                                                  <div className="aboutSec">
                                                    <div className="empl_id">
                                                      <h6>Employee Id:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          disabled
                                                          name="empl_id"
                                                          value={emp.empl_id}
                                                          onChange={(e) => {
                                                            setEmp({
                                                              ...emp,
                                                              empl_id:
                                                                e.target.value,
                                                            });
                                                          }}
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.empl_id
                                                            ? emp.empl_id
                                                            : employee.employee_id}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="name">
                                                      <h6>Full Name:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={emp.fullName}
                                                          onChange={(e) => {
                                                            setEmp({
                                                              ...emp,
                                                              fullName:
                                                                e.target.value,
                                                            });
                                                          }}
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.fullName
                                                            ? emp.fullName
                                                            : employee.fullname}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="designation">
                                                      <h6>Designation:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={
                                                            emp.designation
                                                          }
                                                          onChange={(e) => {
                                                            setEmp({
                                                              ...emp,
                                                              designation:
                                                                e.target.value,
                                                            });
                                                          }}
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.designation
                                                            ? emp.designation
                                                            : "NA"}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="contact">
                                                      <h6>Address:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={emp.address}
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              address:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.address
                                                            ? emp.address
                                                            : employee.address}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="contact">
                                                      <h6>Contact Number:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={
                                                            emp.contact_number
                                                          }
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              contact_number:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.contact_number
                                                            ? emp.contact_number
                                                            : employee.contact_number}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="email">
                                                      <h6>E-Mail:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="email"
                                                          value={emp.email}
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              email:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.email
                                                            ? emp.email
                                                            : employee.email}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="dOB">
                                                      <h6>Date of Birth:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={
                                                            emp.date_of_birth
                                                          }
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              date_of_birth:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.date_of_birth
                                                            ? emp.date_of_birth
                                                            : employee.date_of_birth}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="gender">
                                                      <h6>Gender:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={emp.gender}
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              gender:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.gender
                                                            ? emp.gender
                                                            : employee.gender}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="department">
                                                      <h6>Department:</h6>
                                                      {editMode ? (
                                                        <input
                                                          type="text"
                                                          value={emp.department}
                                                          onChange={(e) =>
                                                            setEmp({
                                                              ...emp,
                                                              department:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.department
                                                            ? emp.department
                                                            : employee.department}
                                                        </p>
                                                      )}
                                                    </div>

                                                    <div className="doj">
                                                      <h6>Date Of Joining:</h6>
                                                      {editMode ? (
                                                        <input
                                                          disabled
                                                          type="text"
                                                          value={
                                                            emp.date_of_joining
                                                              ? emp.date_of_joining
                                                              : employee.date_of_joining
                                                          }
                                                        />
                                                      ) : (
                                                        <p>
                                                          {emp.date_of_joining
                                                            ? emp.date_of_joining
                                                            : employee.date_of_joining}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>

                                                  {/* <div className="save-btn">
                                                    {editMode ? (
                                                      <Button
                                                        onClick={saveChanges}
                                                      >
                                                        Save
                                                      </Button>
                                                    ) : (
                                                      <button
                                                        onClick={toggleeditMode}
                                                      >
                                                        Edit
                                                      </button>
                                                    )}
                                                  </div> */}
                                                </>
                                              );
                                            }
                                          )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}

                              {/* Show Address  */}
                              {showProperty.showAdd && (
                                <Grid container xs={12} md={12} sm={12}>
                                  <Grid item xs={12} md={12} sm={12}>
                                    <div className="user-add">
                                      {!showProperty.updateAdd ? (
                                        <div className="add-div">
                                          <div className="add-details">
                                            <h3>Address Details</h3>
                                            <div className="add-head">
                                              <h6>Add your address!</h6>
                                              <button
                                                className="add-btn"
                                                onClick={() => {
                                                  handleShow({
                                                    showAdd: true,
                                                    updateAdd: true,
                                                  });
                                                }}
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                          <div className="location">
                                            <div>
                                              <img
                                                className="add-img"
                                                src="	https://redcliffelabs.sumhr.io/static/media/address.fa5f1936.svg"
                                                alt="Navigate"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {!showProperty.addCard ? (
                                            <div className="updateAdd">
                                              <Grid
                                                container
                                                xs={12}
                                                md={12}
                                                sm={12}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <div className="aboutEditSec">
                                                    <div
                                                      className="contEditSec"
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    >
                                                      <div>
                                                        <h5>Address Type</h5>
                                                        <FormControl
                                                          sx={{
                                                            m: 1,
                                                            minWidth: 120,
                                                          }}
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                        >
                                                          <Select
                                                            id={"AddType"}
                                                            value={emp.add_type}
                                                            onChange={(
                                                              event: any
                                                            ) => {
                                                              setEmp({
                                                                ...emp,
                                                                add_type:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            required
                                                            inputProps={{
                                                              "aria-label":
                                                                "Without label",
                                                            }}
                                                          >
                                                            <MenuItem value="">
                                                              <em>
                                                                {" "}
                                                                Select Address
                                                                Type
                                                              </em>
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "Present Address"
                                                              }
                                                            >
                                                              Present Address
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "Permamnent Address"
                                                              }
                                                            >
                                                              Permamnent Address
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"Other"}
                                                            >
                                                              Other
                                                            </MenuItem>
                                                          </Select>
                                                          <FormHelperText></FormHelperText>
                                                        </FormControl>
                                                      </div>
                                                      <div>
                                                        <h5>
                                                          Address Line One
                                                        </h5>
                                                        <TextField
                                                          id="AddLineOne"
                                                          variant="outlined"
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                          onChange={(
                                                            event: any
                                                          ) => {
                                                            setEmp({
                                                              ...emp,
                                                              address_line_one:
                                                                event.target
                                                                  .value,
                                                            });
                                                          }}
                                                        />
                                                      </div>
                                                      {/* <div>
                                                        <h5>
                                                          Address Line Two
                                                          (Optional)
                                                        </h5>
                                                        <TextField
                                                          id="AddLineTwo"
                                                          variant="outlined"
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                          onChange={(
                                                            event: any
                                                          ) => {
                                                            setEmp({
                                                              ...emp,
                                                              add_type_two:
                                                                event.target
                                                                  .value,
                                                            });
                                                          }}
                                                        />
                                                      </div> */}
                                                      <div>
                                                        <h5>State</h5>
                                                        <Autocomplete
                                                          style={{
                                                            width: "400px",
                                                          }}
                                                          onChange={(
                                                            event,
                                                            obj
                                                          ) => {
                                                            if (obj) {
                                                              setEmp({
                                                                ...emp,
                                                                employee_current_state:
                                                                  obj,
                                                              });
                                                            }
                                                          }}
                                                          id="sub-department-name"
                                                          options={
                                                            employeeStateName ||
                                                            []
                                                          }
                                                          onInputChange={(
                                                            event,
                                                            newInputValue
                                                          ) => {
                                                            clearTimeout(
                                                              timer.current
                                                            );
                                                            timer.current =
                                                              setTimeout(() => {
                                                                getEmployeeStateName(
                                                                  `name=${newInputValue}`
                                                                );
                                                              }, 1000);
                                                          }}
                                                          getOptionLabel={(
                                                            option: any
                                                          ) => {
                                                            return option;
                                                          }}
                                                          renderOption={(
                                                            option: any
                                                          ) => {
                                                            return option;
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              className="input"
                                                              {...params}
                                                              label="State Name"
                                                              variant="outlined"
                                                              style={{
                                                                width: "400px",
                                                                marginLeft:
                                                                  "10px",
                                                                padding: "0",
                                                              }}
                                                              inputProps={{
                                                                ...params.inputProps,
                                                                onKeyDown: (
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.key ===
                                                                    "Enter"
                                                                  ) {
                                                                    e.stopPropagation();
                                                                  }
                                                                },
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      </div>

                                                      <div>
                                                        <h5>City</h5>
                                                        <Autocomplete
                                                          style={{
                                                            width: "400px",
                                                          }}
                                                          onChange={(
                                                            event,
                                                            obj
                                                          ) => {
                                                            if (obj) {
                                                              const loc =
                                                                obj.location;
                                                              setEmp({
                                                                ...emp,
                                                                address: loc,
                                                              });
                                                            }
                                                          }}
                                                          id={"City"}
                                                          options={
                                                            locationList || []
                                                          }
                                                          onInputChange={(
                                                            event,
                                                            newInputValue
                                                          ) => {
                                                            clearTimeout(
                                                              timer.current
                                                            );
                                                            timer.current =
                                                              setTimeout(() => {
                                                                getLocationList(
                                                                  `name=${newInputValue}`
                                                                );
                                                              }, 1000);
                                                          }}
                                                          getOptionLabel={(
                                                            option: any
                                                          ) => {
                                                            return option.location;
                                                          }}
                                                          renderOption={(
                                                            option: any
                                                          ) => {
                                                            return option.location;
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              className="input"
                                                              {...params}
                                                              label="Employee Location"
                                                              required
                                                              variant="outlined"
                                                              style={{
                                                                width: "400px",
                                                                marginLeft:
                                                                  "10px",
                                                                padding: "0",
                                                              }}
                                                              inputProps={{
                                                                ...params.inputProps,
                                                                onKeyDown: (
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.key ===
                                                                    "Enter"
                                                                  ) {
                                                                    e.stopPropagation();
                                                                  }
                                                                },
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      </div>

                                                      <div>
                                                        <h5>Postal Code</h5>
                                                        <TextField
                                                          value={
                                                            emp.postal_code
                                                          }
                                                          id="Postal-Code"
                                                          variant="outlined"
                                                          style={{
                                                            width: "400  px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                          onChange={(
                                                            event: any
                                                          ) => {
                                                            setEmp({
                                                              ...emp,
                                                              postal_code:
                                                                event.target
                                                                  .value,
                                                            });
                                                          }}
                                                          inputProps={{
                                                            "aria-label":
                                                              "Without label",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <div className="save-btn">
                                                        <Button
                                                          onClick={() => {
                                                            handleShow({
                                                              showAdd: true,
                                                              addCard: true,
                                                              updateAdd: true,
                                                            });
                                                            saveChanges(0);
                                                          }}
                                                        >
                                                          Save
                                                        </Button>
                                                        {/* <Button onClick={}>Cancel</Button> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          ) : (
                                            <div className="addCard">
                                              <Grid
                                                container
                                                xs={12}
                                                md={12}
                                                sm={12}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    {employeesList &&
                                                      employeesList.results &&
                                                      employeesList.results.map(
                                                        (
                                                          employee: any,
                                                          index: number
                                                        ) => {
                                                          return (
                                                            <div>
                                                              <div className="aboutSec">
                                                                <div className="empl_id">
                                                                  <h6>
                                                                    Address
                                                                    type:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      disabled
                                                                      name="empl_id"
                                                                      value={
                                                                        emp.add_type ||
                                                                        editAdd.addType
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          add_type:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.add_type
                                                                        ? emp.add_type
                                                                        : employee.add_type}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="name">
                                                                  <h6>
                                                                    Address:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.add_line_one
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          add_line_one:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.add_line_one
                                                                        ? emp.add_line_one
                                                                        : employee.add_line_one}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="state">
                                                                  <h6>
                                                                    State :
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.employee_current_state
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          employee_current_state:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.employee_current_state
                                                                        ? emp.employee_current_state
                                                                        : employee.employee_current_state}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="city">
                                                                  <h6>
                                                                    City :
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.city
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          city: e
                                                                            .target
                                                                            .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.city
                                                                        ? emp.city
                                                                        : employee.city}
                                                                    </p>
                                                                  )}
                                                                </div>
                                                              </div>

                                                              <div className="save-btn">
                                                                {editMode ? (
                                                                  <Button
                                                                    onClick={
                                                                      saveChanges
                                                                    }
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                ) : (
                                                                  <button
                                                                    onClick={
                                                                      toggleeditMode
                                                                    }
                                                                  >
                                                                    Edit
                                                                  </button>
                                                                )}
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              )}

                              {/* Show Contact */}
                              {showProperty.showContact && (
                                <Grid container xs={12} md={12} sm={12}>
                                  <Grid item xs={12} md={12} sm={12}>
                                    <div className="user-cont">
                                      {!showProperty.updateContact ? (
                                        <div>
                                          <div className="cont-div">
                                            <div className="cont-dtl">
                                              <h3>Contact Details</h3>
                                              <div className="cont-head">
                                                <h6>
                                                  Add your Contact number here!
                                                </h6>
                                                <button
                                                  className="cont-btn"
                                                  onClick={() => {
                                                    handleShow({
                                                      showContact: true,
                                                      updateContact: true,
                                                    });
                                                  }}
                                                >
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                            <div
                                              className="cont-no"
                                              style={{
                                                display: "flex",
                                                marginLeft: "auto",
                                                paddingTop: "3rem",
                                                paddingRight: "1rem",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  className="cont-img"
                                                  src="	https://redcliffelabs.sumhr.io/static/media/contact.4c8fb414.svg"
                                                  alt="Navigate"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {!showProperty.contactCard ? (
                                            <div className="updateAdd">
                                              <div
                                                className="edit-add-container"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              >
                                                <Grid
                                                  container
                                                  xs={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sm={12}
                                                  >
                                                    <div
                                                      className="contEditSec"
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    >
                                                      <div>
                                                        <h5>Contact Type</h5>
                                                        <FormControl
                                                          sx={{
                                                            m: 1,
                                                            minWidth: 120,
                                                          }}
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                        >
                                                          <Select
                                                            id={"ContactType"}
                                                            value={
                                                              emp.cont_type
                                                            }
                                                            onChange={(
                                                              event: SelectChangeEvent
                                                            ) => {
                                                              setEmp({
                                                                ...emp,
                                                                cont_type:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            inputProps={{
                                                              "aria-label":
                                                                "Without label",
                                                            }}
                                                          >
                                                            <MenuItem value="">
                                                              <em>
                                                                Contact Type
                                                              </em>
                                                            </MenuItem>

                                                            <MenuItem
                                                              value={"Mobile"}
                                                            >
                                                              Mobile
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "Desk Phone"
                                                              }
                                                            >
                                                              Desk Phone
                                                            </MenuItem>

                                                            <MenuItem
                                                              value={
                                                                "WhatsApp Number"
                                                              }
                                                            >
                                                              WhatsApp Number
                                                            </MenuItem>
                                                          </Select>
                                                          <FormHelperText></FormHelperText>
                                                        </FormControl>
                                                      </div>

                                                      <div>
                                                        <h5>Contact Tag</h5>
                                                        <FormControl
                                                          sx={{
                                                            m: 1,
                                                            minWidth: 120,
                                                          }}
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            height: "65px",
                                                          }}
                                                        >
                                                          <Select
                                                            id={"ContactTag"}
                                                            value={emp.cont_tag}
                                                            onChange={(
                                                              event: SelectChangeEvent
                                                            ) => {
                                                              setEmp({
                                                                ...emp,
                                                                cont_tag:
                                                                  event.target
                                                                    .value,
                                                              });
                                                            }}
                                                            inputProps={{
                                                              "aria-label":
                                                                "Without label",
                                                            }}
                                                          >
                                                            <MenuItem value="">
                                                              <em>
                                                                Contact Tag
                                                              </em>
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "Work Bussiness"
                                                              }
                                                            >
                                                              Work Bussiness
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"Personal"}
                                                            >
                                                              Personal
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"Other"}
                                                            >
                                                              Other
                                                            </MenuItem>
                                                          </Select>
                                                          <FormHelperText></FormHelperText>
                                                        </FormControl>
                                                      </div>

                                                      <div>
                                                        <h5>
                                                          Enter Contact Number :
                                                        </h5>
                                                        <TextField
                                                          id="outlined-basic"
                                                          variant="outlined"
                                                          style={{
                                                            width: "400px",
                                                            padding: "8px 5px",
                                                            minHeight: "65px",
                                                            background:
                                                              "transparent",
                                                            marginLeft: "10px",
                                                          }}
                                                          defaultValue={
                                                            emp.contact_number
                                                              ? emp.contact_number
                                                              : ""
                                                          }
                                                          // onChange={(e: any) => {

                                                          //   const value = e.target.value;
                                                          //   if (/^[6789]\d{0,9}$/.test(value)) {
                                                          //     setEmp({
                                                          //       ...emp,
                                                          //       contact_number: value,
                                                          //     });
                                                          //   }
                                                          // }}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          inputProps={{
                                                            maxLength: "10",
                                                            minLength: "10",
                                                          }}
                                                          error={!!error}
                                                          helperText={error}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="save-btn">
                                                      <Button
                                                        onClick={() => {
                                                          handleShow({
                                                            showContact: true,
                                                            contactCard: true,
                                                            updateContact: true,
                                                          });
                                                          saveChanges(0);
                                                        }}
                                                      >
                                                        Save
                                                      </Button>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="contCard">
                                              <Grid
                                                container
                                                xs={12}
                                                md={12}
                                                sm={12}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    {employeesList &&
                                                      employeesList.results &&
                                                      employeesList.count > 0 &&
                                                      employeesList.results.map(
                                                        (
                                                          employee: any,
                                                          index: number
                                                        ) => {
                                                          return (
                                                            <div>
                                                              <div className="aboutSec">
                                                                <div className="cont-type">
                                                                  <h6>
                                                                    Contact
                                                                    type:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      disabled
                                                                      name="cont-type"
                                                                      value={
                                                                        emp.cont_type
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          cont_type:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.cont_type
                                                                        ? emp.cont_type
                                                                        : employee.cont_type}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="name">
                                                                  <h6>
                                                                    Contact Tag:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.cont_tag
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          cont_tag:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.cont_tag
                                                                        ? emp.cont_tag
                                                                        : employee.cont_tag}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="contact-number">
                                                                  <h6>
                                                                    Contact
                                                                    Number :
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.contact_number
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          contact_number:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.contact_number
                                                                        ? emp.contact_number
                                                                        : employee.contact_number}
                                                                    </p>
                                                                  )}
                                                                </div>
                                                              </div>

                                                              
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              )}

                              {/* Show Biodata */}

                              {showProperty.showBiodata && (
                                <div className="user-bio">
                                  {employeesList &&
                                    employeesList.results &&
                                    employeesList.count > 0 &&
                                    employeesList.results.map(
                                      (employee: any, index: number) => {
                                        return (
                                          <>
                                            <div className="aboutSec">
                                              <div className="empl_id">
                                                <h6>Gender</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    disabled
                                                    name="empl_id"
                                                    value={emp.gender}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        gender: e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.gender
                                                      ? emp.gender
                                                      : employee.Gender}
                                                  </p>
                                                )}
                                              </div>
                                              <div className="contact">
                                                <h6>Address:</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.address}
                                                    onChange={(e) =>
                                                      setEmp({
                                                        ...emp,
                                                        address: e.target.value,
                                                      })
                                                    }
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.address
                                                      ? emp.address
                                                      : employee.address}
                                                  </p>
                                                )}
                                              </div>
                                              <div className="contact">
                                                <h6>
                                                  Emergency Contact Number:
                                                </h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.emergency_number}
                                                    onChange={(e) =>
                                                      setEmp({
                                                        ...emp,
                                                        emergency_number:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.emergency_number
                                                      ? emp.emergency_number
                                                      : employee.emergency_contact_number}
                                                  </p>
                                                )}
                                              </div>
                                            </div>

                                           
                                          </>
                                        );
                                      }
                                    )}
                                </div>
                              )}

                              {/* Show DoB */}
                              {showProperty.showDoB && (
                                <div
                                  className="user-dob"
                                  onClick={() => {
                                    handleShow({ showDoB: true });
                                  }}
                                >
                                  {employeesList &&
                                    employeesList.results &&
                                    employeesList.count > 0 &&
                                    employeesList.results.map(
                                      (employee: any, index: number) => {
                                        return (
                                          <>
                                            <div className="aboutSec">
                                              <div className="empl_id">
                                                <h6>Birth Date:</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    disabled
                                                    name="empl_id"
                                                    value={emp.date_of_birth}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        date_of_birth:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.date_of_birth
                                                      ? emp.date_of_birth
                                                      : employee.date_of_birth}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                </div>
                              )}

                              {/* Show Dependency  */}
                              {showProperty.showDep && (
                                <Grid container xs={12} md={12} sm={12}>
                                  <Grid item xs={12} md={12} sm={12}>
                                    <div className="user-cont">
                                      {!showProperty.updateDependents ? (
                                        <div
                                          className="user-dep"
                                          onClick={() => {
                                            handleShow({
                                              showDep: true,
                                              updateDependents: true,
                                            });
                                          }}
                                        >
                                          <div className="dep-div">
                                            <div className="dep-details">
                                              <h3>Family Dependents!</h3>
                                              <div className="dep-head">
                                                <h6>
                                                  A little about the people you
                                                  love
                                                </h6>
                                                <button
                                                  className="dep-btn"
                                                  onClick={() => {
                                                    handleShow({
                                                      updateDependents: true,
                                                    });
                                                  }}
                                                >
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                            <div className="dependency">
                                              <div>
                                                <img
                                                  className="dep-img"
                                                  src="	https://redcliffelabs.sumhr.io/static/media/dependents.95c55efd.svg"
                                                  alt="Navigate"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {!showProperty.depCard ? (
                                            <Grid
                                              container
                                              xs={12}
                                              md={12}
                                              sm={12}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                sm={12}
                                              >
                                                <div className="editDependents">
                                                  <div>
                                                    <h5>Dependents</h5>
                                                    <FormControl
                                                      sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                      }}
                                                      style={{
                                                        width: "400px",
                                                        padding: "8px 5px",
                                                        height: "65px",
                                                      }}
                                                    >
                                                      <Select
                                                        id={"RelationType"}
                                                        value={
                                                          emp.relation_type
                                                        }
                                                        onChange={(
                                                          event: SelectChangeEvent
                                                        ) => {
                                                          setEmp({
                                                            ...emp,
                                                            relation_type:
                                                              event.target
                                                                .value,
                                                          });
                                                        }}
                                                        displayEmpty
                                                        inputProps={{
                                                          "aria-label":
                                                            "Without label",
                                                        }}
                                                      >
                                                        <MenuItem value="">
                                                          <em>
                                                            Relationship Type
                                                          </em>
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Father"}
                                                        >
                                                          Father
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Mobile"}
                                                        >
                                                          Mother
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Sister"}
                                                        >
                                                          Sister
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"FaceBook"}
                                                        >
                                                          Brother
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Husband"}
                                                        >
                                                          Husband
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"GitHub"}
                                                        >
                                                          Wife
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Partner"}
                                                        >
                                                          Partner
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={
                                                            "Father-in-Law"
                                                          }
                                                        >
                                                          Father-in-Law
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={
                                                            "Mother-in-Law"
                                                          }
                                                        >
                                                          Mother-in-Law
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={
                                                            "Brother-in-Law"
                                                          }
                                                        >
                                                          Brother-in-Law
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={
                                                            "Sister-in-Law"
                                                          }
                                                        >
                                                          Sister-in-Law
                                                        </MenuItem>
                                                        <MenuItem value={"Son"}>
                                                          Son
                                                        </MenuItem>
                                                        <MenuItem
                                                          value={"Daughter"}
                                                        >
                                                          Daughter
                                                        </MenuItem>
                                                      </Select>
                                                      <FormHelperText></FormHelperText>
                                                    </FormControl>
                                                  </div>
                                                  <div>
                                                    <h5>First Name</h5>
                                                    <TextField
                                                      id="outlined-basic"
                                                      label="Outlined"
                                                      variant="outlined"
                                                      style={{
                                                        width: "400px",
                                                        padding: "8px 5px",
                                                        height: "65px",
                                                      }}
                                                      value={emp.dep_first_name}
                                                      onChange={(e: any) => {
                                                        setEmp({
                                                          ...emp,
                                                          dep_first_name:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div>
                                                    <h5>Last Name </h5>
                                                    <TextField
                                                      id="outlined-basic"
                                                      label="Outlined"
                                                      variant="outlined"
                                                      style={{
                                                        width: "400px",
                                                        padding: "8px 5px",
                                                        height: "65px",
                                                      }}
                                                      value={emp.dep_last_name}
                                                      onChange={(e: any) => {
                                                        setEmp({
                                                          ...emp,
                                                          dep_last_name:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div>
                                                    <h5>
                                                      Emergency Contact Number
                                                    </h5>
                                                    <TextField
                                                      id="outlined-basic"
                                                      label="Contact Number"
                                                      variant="outlined"
                                                      style={{
                                                        width: "400px",
                                                        padding: "8px 5px",
                                                        height: "65px",
                                                      }}
                                                      value={
                                                        emp.dep_emergency_number
                                                      }
                                                      onChange={(e: any) => {
                                                        setEmp({
                                                          ...emp,
                                                          dep_emergency_number:
                                                            e.target.value,
                                                        });
                                                      }}
                                                      inputProps={{
                                                        maxLength: "10",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="save-btn">
                                                    <Button
                                                      onClick={() => {
                                                        handleShow({
                                                          showDep: true,
                                                          updateDependents:
                                                            true,
                                                          depCard: true,
                                                        });
                                                      }}
                                                    >
                                                      Save
                                                    </Button>
                                                    {/* <Button onClick={}>Cancel</Button> */}
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          ) : (
                                            <div className="depCard">
                                              <Grid
                                                container
                                                xs={12}
                                                md={12}
                                                sm={12}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    {employeesList &&
                                                      employeesList.results &&
                                                      employeesList.count > 0 &&
                                                      employeesList.results.map(
                                                        (
                                                          employee: any,
                                                          index: number
                                                        ) => {
                                                          return (
                                                            <div>
                                                              <div className="aboutSec">
                                                                <div className="relation-type">
                                                                  <h6>
                                                                    Relationship
                                                                    type:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      disabled
                                                                      name="relation-type"
                                                                      value={
                                                                        emp.relation_type
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          relation_type:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.relation_type
                                                                        ? emp.relation_type
                                                                        : employee.relation_type}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="name">
                                                                  <h6>
                                                                    First Name:
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.dep_first_name
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          dep_first_name:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.dep_first_name
                                                                        ? emp.dep_first_name
                                                                        : employee.dep_first_name}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="last-name">
                                                                  <h6>
                                                                    Last Name :
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.dep_last_name
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          dep_last_name:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.dep_last_name
                                                                        ? emp.dep_last_name
                                                                        : employee.dep_last_name}
                                                                    </p>
                                                                  )}
                                                                </div>

                                                                <div className="designation">
                                                                  <h6>
                                                                    {emp.dep_first_name +
                                                                      emp.dep_last_name}
                                                                    's Contact
                                                                    Number :
                                                                  </h6>
                                                                  {editMode ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        emp.dep_emergency_number
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setEmp({
                                                                          ...emp,
                                                                          dep_emergency_number:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        });
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    <p>
                                                                      {emp.dep_emergency_number
                                                                        ? emp.dep_emergency_number
                                                                        : employee.dep_emergency_number}
                                                                    </p>
                                                                  )}
                                                                </div>
                                                              </div>

                                                              {/* <div className="save-btn">
                                                                {editMode ? (
                                                                  <Button
                                                                    onClick={
                                                                      saveChanges
                                                                    }
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                ) : (
                                                                  <button
                                                                    onClick={
                                                                      toggleeditMode
                                                                    }
                                                                  >
                                                                    Edit
                                                                  </button>
                                                                )}
                                                              </div> */}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* {showProperty.showeditBtn &&
                        <div className="edit-btn"
                          onClick={() => {
                            handleShow({ showEditAbout: true, });
                          }}
                        >
                          < ModeIcon />
                        </div>
                      } */}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          )}

          {showSection.showUserProfile && (
            <section id="user-profile, user-details">
              <Grid
                container
                spacing={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <div className="ud-container">
                    <div className="ud-left-outer">
                      <div className="ud-left-inner">
                        {/* <div
                          className="us-about work-details"
                          onClick={() => {
                            handleWorkProfile({ workDet: true });
                          }}
                        >
                          <PersonIcon />
                          <h6>Work Details</h6>
                        </div> */}

                        <div
                          className="us-address rep-office"
                          onClick={() => {
                            handleWorkProfile({ repOffice: true });
                          }}
                        >
                          <CallIcon />
                          <h6>Reporting Office</h6>
                        </div>

                        {/* <div
                          className="us-contact us-current-exp"
                          onClick={() => {
                            handleWorkProfile({ currExp: true });
                          }}
                        >
                          <PermContactCalendarIcon />
                          <h6>Current Experience</h6>
                        </div>

                        <div
                          className="us-biodata past-exp"
                          onClick={() => {
                            handleWorkProfile({ pastExp: true });
                          }}
                        >
                          <DescriptionIcon />
                          <h6>Past Experience</h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="middle">
                      <div className="scroll-vertical"></div>
                    </div>

                    <div className="ud-right">
                      <div className="ud-right-cont">
                        <div className="ud-right-inner">
                          <div className="edit-bio">
                            <div className="user-biodata">
                              {/* Show Work Profiles */}
                              {profileProperty.workDet && (
                                <div className=" work-profile">
                                  {employeesList &&
                                    employeesList.results &&
                                    employeesList.count > 0 &&
                                    employeesList.results.map(
                                      (employee: any, index: number) => {
                                        return (
                                          <>
                                            <div className="aboutSec">
                                              <div className="empl_id">
                                                <h6>Date Of Joining:</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    disabled
                                                    name="empl_id"
                                                    value={emp.date_of_joining}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        date_of_joining:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.date_of_joining
                                                      ? emp.date_of_joining
                                                      : employee.date_of_joining}
                                                  </p>
                                                )}
                                              </div>
                                              {/* <div className="empl_id">
                                                <h6>Employeement Stage:</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    disabled
                                                    name="empl_id"
                                                    value={emp.emp_stage}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        emp_stage:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.emp_stage
                                                      ? emp.emp_stage
                                                      : employee.employeement_stage}
                                                  </p>
                                                )}
                                              </div> */}

                                              <div className="name">
                                                <h6>Employeement Type:</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.emp_type}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        emp_type:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.emp_type
                                                      ? emp.emp_type
                                                      : employee.employeement_type}
                                                  </p>
                                                )}
                                              </div>

                                              {/* <div className="designation">
                                                <h6>Employeement Grade :</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.emp_grade}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        emp_grade:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.emp_grade
                                                      ? emp.emp_grade
                                                      : employee.employeement_grade}
                                                  </p>
                                                )}
                                              </div> */}

                                              {/* <div className="designation">
                                                <h6>Probation End Date :</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.prob_end_date}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        prob_end_date:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.prob_end_date
                                                      ? emp.prob_end_date
                                                      : employee.probation_end_date}
                                                  </p>
                                                )}
                                              </div> */}

                                              <div className="date-of-confirmation">
                                                <h6>Date Of Confirmation :</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.date_confirm}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        date_confirm:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.date_confirm
                                                      ? emp.date_confirm
                                                      : employee.date_confirmation}
                                                  </p>
                                                )}
                                              </div>

                                              {/* <div className="designation">
                                                <h6>
                                                  Notice Period Start Date :
                                                </h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.notice_period}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        notice_period:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.notice_period
                                                      ? emp.notice_period
                                                      : employee.notice_period}
                                                  </p>
                                                )}
                                              </div> */}

                                              <div className="date-of-exit">
                                                <h6>Date Of Exit :</h6>
                                                {editMode ? (
                                                  <input
                                                    type="text"
                                                    value={emp.exit_date}
                                                    onChange={(e) => {
                                                      setEmp({
                                                        ...emp,
                                                        exit_date:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <p>
                                                    {emp.exit_date
                                                      ? emp.exit_date
                                                      : employee.exit_date}
                                                  </p>
                                                )}
                                              </div>
                                            </div>

                                            {/* <div className="save-btn">
                                              {editMode ? (
                                                <Button onClick={saveChanges}>
                                                  Save
                                                </Button>
                                              ) : (
                                                <button
                                                  onClick={toggleeditMode}
                                                >
                                                  Edit
                                                </button>
                                              )}
                                            </div> */}
                                          </>
                                        );
                                      }
                                    )}
                                </div>
                              )}

                              {/* Show Reporting Address  */}
                              {profileProperty.repOffice && (
                                <div
                                  className="user-add"
                                  onClick={() => {
                                    handleWorkProfile({ workRepOffice: true });
                                  }}
                                >
                                  <div
                                    className="rep-div"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="add-details rep-location-cont">
                                      <div
                                        className="add-head  rep-location  city-add"
                                        style={{
                                          paddingTop: "0",
                                          marginTop: "0",
                                        }}
                                      >
                                        <h3>Here's Your address!</h3>

                                        <div className="rep-city-cont">
                                          <div className="rep-city">
                                            <div className="ofc-city">
                                              <h6>Noida H-11</h6>
                                            </div>
                                            <div className="ofc-street">
                                              <h6>
                                                H-11, Electronic City, Sector-63{" "}
                                              </h6>
                                            </div>
                                            <div className="city-name">
                                              <h6>Noida</h6>
                                            </div>
                                            <div className="ofc-state">
                                              <h6>Uttar Pradesh</h6>
                                            </div>
                                            <div className="city-pin">
                                              <h6>201301</h6>
                                            </div>
                                            <div className="ofc-country">
                                              <h6>India</h6>
                                            </div>
                                            <div className="dep-head">
                                              <button className="dep-btn loc-btn">
                                                Current
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="location"
                                      style={{ paddingRight: "1rem" }}
                                    >
                                      <img
                                        className="rep-img exp-img add-exp"
                                        src="	https://redcliffelabs.sumhr.io/static/media/address.fa5f1936.svg"
                                        alt="Navigate"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Show Experience */}
                              {profileProperty.currExp && (
                                <div
                                  className="user-cont "
                                  onClick={() => {
                                    handleWorkProfile({ currExp: true });
                                  }}
                                >
                                  <div
                                    className="cont-div"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <div className="cont-dtl user-curr-exp">
                                      <h3>Current Experience!</h3>
                                      <div className="cont-head">
                                        <div className="user-exp">
                                          <div className="designation">
                                            <h6>Intern</h6>
                                          </div>
                                          <div className="ofc-name">
                                            <h6>
                                              Red Cliffe Labs Private Limited
                                            </h6>
                                          </div>
                                          <div
                                            className="user-duration"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h6
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              06-02-2024
                                            </h6>
                                            <h6
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              to
                                            </h6>
                                            <h6
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              Present
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="cont-no"
                                      style={{ paddingRight: "1rem" }}
                                    >
                                      <div>
                                        <img
                                          className="cont-img exp-img"
                                          src="	https://redcliffelabs.sumhr.io/static/media/contact.4c8fb414.svg"
                                          alt="Navigate"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Show Past EXperience */}
                              {profileProperty.pastExp && (
                                <div
                                  className="user-dep"
                                  onClick={() => {
                                    handleWorkProfile({ pastExp: true });
                                  }}
                                >
                                  <div className="dep-div">
                                    <div className="dep-details">
                                      <h3>Your Past Experiences!</h3>
                                      <div className="dep-head">
                                        <h6 style={{ lineBreak: "auto" }}>
                                          Success and failure is nothing but
                                          experience. Tell us more about your
                                          experience below.
                                        </h6>
                                        <button className="dep-btn">Add</button>
                                      </div>
                                    </div>
                                    <div
                                      className="dependency"
                                      style={{
                                        paddingTop: "3rem",
                                        paddingRight: "1rem",
                                      }}
                                    >
                                      <div>
                                        <img
                                          className="dep-img exp-img"
                                          src="https://redcliffelabs.sumhr.io/static/media/work-experience.e387d663.svg"
                                          alt="Navigate"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {profileProperty.showeditBtn && (
                          <div className="edit-btn">
                            <ModeIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          )}
          {showSection.showDocs && (
            <section id="user-profile, user-details">
              <Grid
                container
                spacing={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <div className="ud-container">
                    <div className="ud-left-outer">
                      <div className="ud-left-inner">
                        <div
                          className="us-about work-details"
                          onClick={() =>
                            handleDocs({
                              userIdentity: true,
                              userOthers: false,
                            })
                          }
                        >
                          <PersonIcon />
                          <h6>Identity</h6>
                        </div>

                        <div
                          className="us-address rep-office"
                          onClick={() =>
                            handleDocs({
                              userIdentity: false,
                              userOthers: true,
                            })
                          }
                        >
                          <CallIcon />
                          <h6>others</h6>
                        </div>
                      </div>
                    </div>

                    <div className="middle">
                      <div className="scroll-vertical"></div>
                    </div>

                    <div className="ud-right">
                      <div className="ud-right-inner">
                        <div className="edit-bio">
                          <div className="user-biodata">
                            {showDocuments.userIdentity && (
                              <Grid container xs={12} md={12} sm={12}>
                                <Grid item xs={12} md={12} sm={12}>
                                  {!showDocuments.editUserIdentity ? (
                                    <div className="user-dep">
                                      <div
                                        className="dep-div"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="dep-details ">
                                          <h3>Identity Documents Details</h3>
                                          <div className="dep-head">
                                            <h6 style={{ lineBreak: "auto" }}>
                                              Add your documents.
                                            </h6>
                                            <button
                                              className="dep-btn"
                                              onClick={() => {
                                                handleDocs({
                                                  editUserIdentity: true,
                                                  userIdentity: true,
                                                });
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="dependency"
                                          style={{
                                            marginLeft: "auto",
                                            paddingTop: "3rem",
                                            paddingRight: "1rem",
                                          }}
                                        >
                                          <div>
                                            <img
                                              className="dep-img"
                                              src="https://redcliffelabs.sumhr.io/static/media/education.2db0edb1.svg"
                                              alt="Navigate"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <Grid container xs={12} md={12} sm={12}>
                                        <Grid item xs={12} md={12} sm={12}>
                                          <div>
                                            <Box sx={{ flexGrow: 1 }}>
                                              <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                  <div
                                                    className="upload-cont"
                                                    style={{
                                                      position: "absolute",
                                                      textAlign: "center",
                                                      left: "35%",
                                                      right: "0",
                                                      marginTop: "30px",
                                                      marginBottom: "30px",
                                                    }}
                                                  >
                                                    <div
                                                      className="uplaod-inner"
                                                      style={{
                                                        height: "100%",
                                                        width: "800px",
                                                        border:
                                                          "2px dashed grey",
                                                        borderRadius: "10px",
                                                        textAlign: "center",
                                                        padding: "30px 10px",
                                                      }}
                                                    >
                                                      <div
                                                        className="files-first"
                                                        style={{}}
                                                      >
                                                        <BackupIcon
                                                          style={{
                                                            margin: "0 5px",
                                                            width: "100px",
                                                            color: "blue",
                                                          }}
                                                        />
                                                        <h5>Upload File</h5>
                                                      </div>
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={8}
                                                      >
                                                        <input
                                                          className="button-17"
                                                          type="text/csv"
                                                          style={{
                                                            height: "50px",
                                                            padding: "6px 10px",
                                                            marginLeft: "255px",
                                                          }}
                                                          placeholder="File Name"
                                                          value={
                                                            file_nameSalary
                                                          }
                                                          disabled
                                                        />
                                                        <p
                                                          style={{
                                                            color: "red",
                                                            marginLeft: "255px",
                                                          }}
                                                        >
                                                          {fileTypeErrorSalary}
                                                        </p>
                                                      </Grid>

                                                      <div className="upload-btn">
                                                        <Button
                                                          className="button-17"
                                                          style={{
                                                            height: "56px",
                                                          }}
                                                          component="label"
                                                          id="upload-btn"
                                                        >
                                                          Select CSV File
                                                          <input
                                                            type="file"
                                                            name="file"
                                                            id="file"
                                                            hidden
                                                            onChange={
                                                              handleFileChangeSalary
                                                            }
                                                          />
                                                        </Button>
                                                      </div>

                                                      <div className="file-det">
                                                        <span
                                                          style={{
                                                            marginRight: "10px",
                                                          }}
                                                        >
                                                          Supported file type:{" "}
                                                        </span>
                                                        <strong>
                                                          Note : Only CSV File
                                                          Can Be upload
                                                        </strong>
                                                      </div>

                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                        >
                                                          <Button
                                                            className="button-17"
                                                            startIcon={
                                                              <CloudUploadIcon />
                                                            }
                                                            component="label"
                                                            style={{
                                                              height: "56px",
                                                              width: "100%",
                                                              marginLeft:
                                                                "-50px",
                                                              marginTop: "10px",
                                                            }}
                                                            onClick={
                                                              submitFileSalary
                                                            }
                                                            disabled={
                                                              buttonState ||
                                                              file_nameSalary ===
                                                                "" ||
                                                              fileTypeErrorSalary !==
                                                                ""
                                                            }
                                                          >
                                                            Upload
                                                          </Button>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                        >
                                                          <a
                                                            href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/c7f5b136-c9ea-4ff5-9369-d4f991145e3a.csv"
                                                            target=""
                                                          >
                                                            <Button
                                                              className="button-17"
                                                              fullWidth
                                                              style={{
                                                                height: "56px",
                                                                width: "100",
                                                                marginTop:
                                                                  "10px",
                                                              }}
                                                              startIcon={
                                                                <CloudDownloadIcon />
                                                              }
                                                              component="label"
                                                            >
                                                              Download Sample
                                                              CSV File
                                                            </Button>
                                                          </a>
                                                        </Grid>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            )}

                            {showDocuments.userOthers && (
                              <Grid container xs={12} md={12} sm={12}>
                                <Grid item xs={12} md={12} sm={12}>
                                  <div>
                                    {showDocuments.editOthers ? (
                                      <div>
                                        <FileUploader />
                                        <div
                                          className="save-btn"
                                          style={{
                                            display: "block",
                                            width: "100%",
                                          }}
                                        >
                                          <Button
                                            onClick={() => {
                                              handleShow({ userOthers: true });
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="user-dep user-other-doc">
                                        <div
                                          className="dep-div other-doc-div"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <div className="dep-details other-doc">
                                            <h3>Other Documents!</h3>
                                            <div className="dep-head other-doc-head">
                                              <h6 style={{ lineBreak: "auto" }}>
                                                Start by uploading some of your
                                                personal documents
                                              </h6>
                                              <button
                                                className="dep-btn other-doc-btn"
                                                onClick={() => {
                                                  handleDocs({
                                                    editOthers: true,
                                                    userOthers: true,
                                                  });
                                                }} // Set editOthers to true and userOthers to false
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="dependency"
                                            style={{
                                              marginLeft: "auto",
                                              paddingTop: "3rem",
                                              paddingRight: "1rem",
                                            }}
                                          >
                                            <div>
                                              <img
                                                className="dep-img"
                                                src="https://redcliffelabs.sumhr.io/static/media/education.2db0edb1.svg"
                                                alt="Navigate"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          )}

          {showSection.showExperience && (
            <section id="user-profile, user-details">
              <Grid
                container
                spacing={4}

                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12}>
                  <div className="ud-container">
                    <Grid container spacing={4} style={{height:"fit-content", padding:"30px"}}>
                      <Grid item xs={12}>
                      <Grid container className="centerItem">
                      <Grid item >
                        <Typography className="ExpTitle">Experience</Typography>
                      </Grid>
                      <Grid item style={{marginLeft:"auto"}}>
                        <Button  variant="contained" style={{background:"#413c9f", color:"white" }} onClick={(e:any)=>setOpenAddExpModel(true)}>
                          Add
                        </Button>      
                      </Grid>
                    </Grid>
                      </Grid>
                      <Grid item xs={12}>
                      <Grid container>
                      <Grid item xs={12}>
                    {Object.keys(formatedData).map((company,companyIndex) => {
                      const roles = formatedData[company];
                      const { totalYears, remainingMonths } = calculateTotalExperience(roles);
                      const employmentType = roles[0].employment_type;
                      const location = roles[0].location;

                      return (
                        <div key={company}>
                            <Typography className="companyName">{company.charAt(0).toUpperCase() + company.slice(1)}</Typography>
                            <Typography fontSize={"20px"} fontWeight={600} lineHeight={0.8} >{employmentType === "full_time" ? "Full-time" : employmentType === "part_time" ? "Part-time" : employmentType } . {totalYears !== 0 && totalYears} {totalYears !== 0 && "yrs"} {remainingMonths !== 0 && remainingMonths} {remainingMonths !== 0 && "mos"} </Typography>
                            <Typography fontSize={"20px"} fontWeight={500} textColor={"gray"} marginBottom={"10px"} lineHeight={1.5} >{location}</Typography>
                            {roles.map((role:any, index:any) => {
                              const { years, months } = calculateDateDifference(role.start_date, role.end_date);
                              const startDateFormatted = formatDate(role.start_date);
                              const endDateFormatted = role.is_currently_working ? 'Present' : formatDate(role.end_date);
                              return (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                   <div style={{display:"flex" , alignItems:"center"}}>
                                  <Typography className="companyName">{role.job_title}</Typography>
                                  {role?.document && 
                                    <IconButton onClick={(e:any)=>{setOpenPdf(true); setFileLink(role?.document)}}>
                                    <VisibilityIcon style={{color:"#413c9f"}}/>
                                  </IconButton>}
                                  <IconButton onClick={(e:any)=>{setOpenEditExpModel(true); setSelectedEdit(role) }}>
                                    <EditIcon style={{color:"#413c9f"}}/>
                                  </IconButton>
                                  <IconButton onClick={(e:any)=>{setOpenDeleteExpModal(true); setSelectedDelete(role?.id)}}>
                                    <DeleteIcon style={{color:"#413c9f"}}/>
                                  </IconButton>
                                  </div>
                                  <Typography fontSize={"20px"} fontWeight={600} lineHeight={0.8} marginBottom={"10px"}>{startDateFormatted} - {endDateFormatted}. {years !== 0 && years} {years !== 0 && "yrs"} {months !== 0 && months} {months !== 0 && "mos"} </Typography>
                                  <Typography fontSize={"20px"} fontWeight={500}>{role.description}</Typography>
                                </div>
                              );
                            })}
                            {companyIndex < Object.keys(formatedData).length - 1 && <div style={{paddingTop:"20px" , paddingBottom:"20px"}}><Divider /></div>}
                        </div>
                          );
                        })}
                      </Grid>
                        
                      </Grid>
                      </Grid>
                    </Grid>
                    
                  </div>
                </Grid>
              </Grid>
            </section>
          )}

          {showSection.showCertification && (
           <section id="user-profile, user-details">
           <Grid
             container
             spacing={4}

             style={{ display: "flex", justifyContent: "center" }}
           >
             <Grid item xs={12}>
               <div className="ud-container">
                 <Grid container spacing={4} style={{height:"fit-content", padding:"30px"}}>
                   <Grid item xs={12}>
                   <Grid container className="centerItem">
                   <Grid item >
                     <Typography className="ExpTitle">Cerifications</Typography>
                   </Grid>
                   <Grid item style={{marginLeft:"auto"}}>
                     <Button  variant="contained" style={{background:"#413c9f", color:"white" }} onClick={(e:any)=>setOpenAddCertificationModal(true)}>
                       Add
                     </Button>      
                   </Grid>
                 </Grid>
                   </Grid>
                   <Grid item xs={12}>
                   <Grid container spacing={3}>
                   {certificateList && certificateList?.length !== 0 && certificateList?.map((data:any, index:any)=>{
                     return(
                       <>
                       <Grid item xs={12} >
                         <div style={{display:"flex" , alignItems:"center"}}>
                         <Typography className="companyName">{data?.certificate_name}</Typography>
                         {data?.document && 
                         <IconButton onClick={(e:any)=>{setOpenPdf(true); setFileLink(data?.document)}}>
                           <VisibilityIcon style={{color:"#413c9f"}}/>
                         </IconButton>}
                         <IconButton onClick={(e:any)=>{setOpenEditCertificationModal(true); setSelectedCertificateData(data)}}>
                           <EditIcon style={{color:"#413c9f"}}/>
                         </IconButton>
                         <IconButton onClick={(e:any)=>{setOpenDeleteCertModal(true); setDeleteId(data?.id)}}>
                           <DeleteIcon style={{color:"#413c9f"}}/>
                         </IconButton>
                         </div>
                         <Typography fontSize={"22px"} fontWeight={600} >{data?.issued_by_organization}</Typography>
                         <Typography fontSize={"20px"} fontWeight={600} textColor={"gray"} >{formatDate(data?.issue_date)} - {data?.is_lifetime_valid ? "Lifetime" : formatDate(data?.expiration_date)}</Typography>
                         {/* <Typography fontSize={"20px"} fontWeight={600} textColor={"gray"} >Credential Id {data?.credential_Id}</Typography> */}
                         {data?.certificate_url &&
                         <button className="showCredential" onClick={()=>handleRedirect(data?.certificate_url)} >
                            Show credential
                        </button>}
                         {index < certificateList?.length - 1 && <div style={{paddingTop:"30px"}}><Divider /></div>}
                       </Grid>
                       </>
                     )
                   })}
                   </Grid>
                   </Grid>
                 </Grid>                 
               </div>
             </Grid>
           </Grid>
         </section>
          )}
        {showSection.showMedicalInfo && (
           <section id="user-profile, user-details">
           <Grid
             container
             spacing={4}

             style={{ display: "flex", justifyContent: "center" }}
           >
             <Grid item xs={12}>
               <div className="ud-container">
                 <Grid container spacing={4} style={{height:"fit-content", padding:"30px"}}>
                   <Grid item xs={12} >
                     <Typography className="ExpTitle">Medical Information</Typography>
                   </Grid>
                   <Grid item xs={12}>
                   <Grid container spacing={3}>
                      <Card className="shadow" style={{width:"100%"}}>
                          <Grid item xs={12}  style={{display:"flex", justifyContent:"start", alignItems:"center"}}>
                          <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCovidVaccinated}
                                    disabled={medicalData[0]?.is_covid_vaccinated}
                            onChange={(e) =>
                            {
                                setIsCovidVaccinated(e.target.checked);
                            }
                                
                            }
                            name="isCovidVaccinated"
                              style={{
                                margin: "0",
                                marginLeft: "10px",
                                color:"#413c9f",
                                marginRight:"20px"
                              }}
                             />
                            }
                            style={{ paddingTop: "7px", width:"fit-content" }}
                            label="Are You Covid Vaccinated ?"
                            labelPlacement="start"
                        />
                        {isCovidVaccinated && 
                        <>
                         <FormControl style={{marginRight:"20px"}}>
                                <input
                                    className="medicalUploadField"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={fileSelectedHandler}
                                />
                                {errors.fileSize && <p style={{ color: "red" }}>File size should not exceed 10 MB</p>}
                            </FormControl>
                            {medicalData?.length !== 0 ? 
                            <Button
                              variant="contained"
                              color="primary"
                              style={{marginRight:"20px"}}
                              onClick={handleEditSubmit}
                            >
                                Edit Medical
                            </Button>
                            :
                            <Button
                              variant="contained"
                              color="primary"
                              style={{marginRight:"20px"}}
                              onClick={handleSubmit}
                            >
                                Add Medical
                            </Button>}
                            {medicalData?.length === 0 &&
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{marginRight:"20px"}}
                              onClick={() => {setIsCovidVaccinated(false);setFile(null)}}
                              >
                                  Cancel
                              </Button> }
                              {medicalData[0]?.document &&
                              <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<VisibilityIcon />}
                              onClick={(e:any)=>{setOpenPdf(true); setFileLink(medicalData[0]?.document)}}
                              >
                                View Document
                              </Button>
                                                          
                            }
                              </>
                          }
                              </Grid>
                      </Card>
                   </Grid>
                   </Grid>
                 </Grid>                 
               </div>
             </Grid>
           </Grid>
         </section>
          )}

          {showSection.showEducation && (
            <section id="user-profile, user-details">
              <Grid
                container
                spacing={4}

                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12}>
                  <div className="ud-container">
                    <Grid container spacing={4} style={{height:"fit-content", padding:"30px"}}>
                      <Grid item xs={12}>
                      <Grid container className="centerItem">
                      <Grid item >
                        <Typography className="ExpTitle">Education</Typography>
                      </Grid>
                      <Grid item style={{marginLeft:"auto"}}>
                        <Button  variant="contained" style={{background:"#413c9f", color:"white" }} onClick={(e:any)=>setOpenAddEducation(true)}>
                          Add
                        </Button>      
                      </Grid>
                    </Grid>
                      </Grid>
                      <Grid item xs={12}>
                   <Grid container spacing={3}>
                   {educationList && educationList?.length !== 0 && educationList?.map((data:any, index:any)=>{
                     return(
                       <>
                       <Grid item xs={12} >
                         <div style={{display:"flex" , alignItems:"center"}}>
                         <Typography className="companyName">{data?.school_college_name}</Typography>
                         {data?.document && 
                         <IconButton onClick={(e:any)=>{setOpenPdf(true); setFileLink(data?.document)}}>
                           <VisibilityIcon style={{color:"#413c9f"}}/>
                         </IconButton>}
                         <IconButton onClick={(e:any)=>{setOpenEditEducation(true); setSelecetdEducation(data)}}>
                           <EditIcon style={{color:"#413c9f"}}/>
                         </IconButton>
                         <IconButton onClick={(e:any)=>{setOpenDeleteEducation(true); setDeleteId(data?.id)}}>
                           <DeleteIcon style={{color:"#413c9f"}}/>
                         </IconButton>
                         </div>
                         <Typography fontSize={"22px"} fontWeight={600} >{data?.degree}, {data?.field_of_study} </Typography>
                         <Typography fontSize={"20px"} fontWeight={600} textColor={"gray"} marginBottom={"20px"} >{yearFormat(data?.start_date)} - {data?.is_currently_studying ? "Currently Studying" : yearFormat(data?.end_date)}</Typography>
                         {data?.grade && <Typography fontSize={"20px"} fontWeight={600} marginBottom={"20px"}>Grade: {data?.grade}</Typography> }
                        {data?.description && <Typography fontSize={"20px"} fontWeight={600} >Description: {data?.description}</Typography> }
                         {index < educationList?.length - 1 && <div style={{paddingTop:"30px"}}><Divider /></div>}
                       </Grid>
                       </>
                     )
                   })}
                   </Grid>
                   </Grid>
                    </Grid>
                    
                  </div>
                </Grid>
              </Grid>
            </section>
          )}
        </div>

        <Drawer
          anchor={"right"}
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box style={{ margin: "70px 20px", padding: "0" }}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <TextField
                style={{ width: "325px" }}
                placeholder="Search"
                onChange={(e) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getMyTeamDetails(`search=${e.target.value}`);
                  }, 1000);
                }}
              />
            </div>
            <List
              height={7000} // Adjust based on your needs
              itemCount={team_details.length}
              itemSize={180} // Adjust based on item height
              width={350}
              
            >
              {({ index, style } : any) => {
                const data = team_details[index];
                return (
                  <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  key={index}
                  orientation="horizontal"
                  sx={{
                    width: 330,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                    <Avatar
                      src="/static/images/avatar/1.jpg"
                      sx={{ "--Avatar-size": "4rem" }}
                    />
                    <CardContent>
                      <Typography level="title-md" id="card-description">
                        {data?.fullname}
                      </Typography>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="sm"
                        sx={{ pointerEvents: "none" }}
                      >
                        {data?.designation}
                      </Chip>
                      <Typography
                        level="body-sm"
                        aria-describedby="card-description"
                        mb={1}
                      >
                        <CallIcon style={{ width: "15px" }} />
                        {data?.phonenumber}
                      </Typography>
                      <Typography
                        level="body-sm"
                        aria-describedby="card-description"
                        mb={1}
                        style={{ display: "flex" }}
                      >
                        <EmailIcon style={{ width: "15px" }} />
                        {data?.email !== null ? data.email : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              }}
            </List>
          </Box>
        </Drawer>

        {openPdf === true && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPdf}
            onClose={handlePdfModelClose}
            closeAfterTransition
          >
            <Fade in={openPdf}>
              {fileLink?.includes(".jpg") ||
              fileLink?.includes(".jpeg") ||
              fileLink?.includes(".png") ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${fileLink}`}
                    style={{ width: "95vh", height: "80%" }}
                    title="IMAGE Document"
                  />
                </div>
              ) : fileLink?.includes(".doc") || fileLink?.includes(".docx") ? (
                <div id="mera-doc-viewer">
                  <DocViewer
                    style={{ height: "-webkit-fill-available" }}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: fileLink, fileType: "doc" }]}
                  />
                </div>
              ) : (
                <iframe
                  src={`${fileLink}`}
                  style={{ width: "80%", height: "750px" }}
                  title="PDF Document"
                ></iframe>
              )}
            </Fade>
          </Modal>
        )}

        {openAddExpModel && 
          (<AddExperienceModal 
            openAddExpModel={openAddExpModel} 
            setOpenAddExpModel={setOpenAddExpModel}
          />)
        }
        {openEditExpModel && 
          (<EditExperienceModal 
            openEditExpModel={openEditExpModel} 
            setOpenEditExpModel={setOpenEditExpModel}
            selectedData={selectedEdit}
          />)
        }
         {openDeleteExpModal && 
          (<DeleteExperienceModal 
            openDeleteExpModal={openDeleteExpModal} 
            setOpenDeleteExpModal={setOpenDeleteExpModal}
            selectedId={selectedDelete}
          />)
        }
        {openAddCertificationModal && 
          (<AddCerificateModal
            openModal={openAddCertificationModal} 
            setOpenModal={setOpenAddCertificationModal}
          />)
        }
         {openEditCertificationModal && 
          (<EditCertificate
            openModal={openEditCertificationModal} 
            setOpenModal={setOpenEditCertificationModal}
            selectedData={selectedCertificateData}
          />)
        }
        {
          <DeleteCerificate 
            openDeleteCertModal={openDeleteCertModal} 
            setOpenDeleteCertModal={setOpenDeleteCertModal}
            selectedId={deleteId}
          />
        }
        {openAddEducation && 
          (<AddEducation 
            openAddEducation={openAddEducation} 
            setOpenAddEducation={setOpenAddEducation}
          />)
        }
        {openEditEducation && 
          (<EditEducation 
            openEditEducation={openEditEducation} 
            setOpenEditEducation={setOpenEditEducation}
            selectedData={selecetdEducation}
          />)
        }
        {
          <DeleteEducation 
            openDeleteEducation={openDeleteEducation} 
            setOpenDeleteEducation={setOpenDeleteEducation}
            selectedId={deleteId}
          />
        }
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  holidayList: state.EmployeeReducer.holidayList,
  employeesList: state.loginReducer.employeesList,
  birthday_reminder: state.EmployeeReducer.birthday_reminder,
  attendanceData: state.EmployeeReducer.attendanceData,
  team_details: state.loginReducer.team_details,
  leaveComponents: state.loginReducer.leaveComponents,
  payroll_aggregate: state.loginReducer.payroll_aggregate,
  departmentNames: state.loginReducer.departmentNames,
  locationList: state.loginReducer.locationList,
  employeeStateName: state.loginReducer.employeeStateName,
  getProfileImage: state.loginReducer.getProfileImage,
  postProfileImage: state.loginReducer.postProfileImage,
  employmentDetailsList:state.EmployeeReducer.employmentDetailsList,
  certificateList:state.EmployeeReducer.certificateList,
  medicalData:state.loginReducer.medicalData,
  educationList: state.PayrollReducer.educationList

});

export default connect(mapStateToProps, {
  getDisplayHolidaysList,
  getEmployeeList,
  getBirthdayReminder,
  editEmployeeById,
  getAttendanceMaster,
  getMyTeamDetails,
  getPayrollAggregate,
  getEmployeeDepartment,
  getLocationList,
  getEmployeeStateName,
  getUserProfileImage,
  updateImage,
  getEmploymentDetails,
  getCertificates,
  AddMedicalInfo,
  getMedicalInfo,
  EditMedicalInfo,
  getEducationDetails
})(CustomerLeadFilterPage);
