import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { TextField, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getMyTeamDetails, getShiftList } from "../../../actions/loginActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Drawer from "@mui/material/Drawer";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import dayjs, { Dayjs } from "dayjs";
import Chip from "@mui/material/Chip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "190%",
      marginTop: "50px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      padding: "10px 20px",
      gap: "10px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },

    tableContainer: {
      position: "sticky",
      padding: "20px",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
      border: "1px solid #fff", // Add this line
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
      border: "1px solid #fff", // Add this line
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      border: "1px solid #fff", // Add this line if you want a border around the entire row
    },
  })
)(TableRow);

interface Props {
  getMyTeamDetails: any;
  team_details: any;
  getShiftList:any;
  shiftList: any;

}

const WeeklyRoster: React.FC<Props> = ({ getMyTeamDetails, team_details, getShiftList , shiftList}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<any>(false);
  const [dates, setDates] = useState<any>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [shiftListData, setshiftListData] = useState<any>("");
  const [userShiftTime, setUserShiftTime] = useState<any>("");
  const [WeekOffDays, setWeekOffDays] = useState<any>("");
  const [PreviousWeekOffDays, setPreviousWeekOffDays] = useState<any>([]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const handleAutocompleteChange = (event: any, newValue: any) => {
      if (newValue) {
        let obj = JSON.parse(JSON.stringify(newValue, null, ""));
        setshiftListData(obj.id);
        setUserShiftTime(`Total Hours: ${obj.total_hour}`);
      }
    };

  const getNext7Days = (selectedDate: Date) => {
    const Dates = [];
    const startDate = new Date(selectedDate);

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);
      Dates.push(
        nextDate.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
      );
    }
    return Dates;
  };

  const checkSelectedEmployees = (event: any, empId: number) => {
    if (selectedEmployees?.length < 10) {
      !event.target.checked
        ? setSelectedEmployees(
            selectedEmployees.filter((emp: any) => emp !== empId)
          )
        : setSelectedEmployees((prev: any) => [...prev, empId]);
    
    } else {
      return false;
    }


  };


  const handleChange = (event: any) => {
    const selectedDate = event.target.value;
    const selectedDates = getNext7Days(selectedDate);
    setDates(selectedDates);
  };

  useEffect(() => {
    getMyTeamDetails();
  }, []);

  return (
    <>
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <div className="data-table">
            <h4
              style={{
                color: "rgb(14, 63, 108)",
                padding: "10px 20px",
              }}
            >
              Weekly Roster
            </h4>
          </div>

          <div>
            <input
              type="date"
              id="start"
              name="Date"
              onChange={handleChange}
              style={{
                padding: "5px 20px",
                border: "1px solid grey",
                borderRadius: "10px",
              }}
            />
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "2px 15px" }}
              onClick={(event) => {
                toggleDrawer(true)(event);
              }}
              disabled={selectedEmployees.length === 0}
            >
              Assign
            </Button>
            {(["right"] as const).map((anchor) => (
              <>
                <Drawer
                  anchor={anchor}
                  open={isDrawerOpen}
                  onClose={toggleDrawer(false)}
                >
                 
                 <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                        padding: "12px 20px",
                        borderRadius: "8px",
                        backgroundColor: "#f0f4f8",
                        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                        fontFamily: "'Poppins', sans-serif",
                        border: "1px solid #dcdcdc",
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "translateY(-3px)";
                        e.currentTarget.style.boxShadow =
                          "0 6px 12px rgba(0, 0, 0, 0.15)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "translateY(0)";
                        e.currentTarget.style.boxShadow =
                          "0 3px 10px rgba(0, 0, 0, 0.1)";
                      }}
                    >
                      <h6
                        style={{
                          margin: 0,
                          fontSize: "16px",
                          color: "#2c3e50",
                          fontWeight: 500,
                          letterSpacing: "0.3px",
                          textAlign: "center",
                          lineHeight: 1.4,
                        }}
                      >
                        Assign Shift
                      </h6>
                    </div>

                    <div>
                      <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                        Select employees
                      </label>
                    </div>

                    <div>
                      <Autocomplete
                        multiple
                        limitTags={0}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let packs: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              packs.push(obj);
                            });
                            setEmployeeList(packs);
                          }
                        }}
                        defaultValue={team_details.filter((employee: any) =>
                          selectedEmployees.includes(employee.employee_id))}
                        id="checkboxes-tags-demo"
                        options={team_details || []}
                        getOptionLabel={(option: any) => {
                          return option?.fullname + " " + option.employee_id;
                        }}
                        renderOption={(option: any) => {
                          return (
                            <p style={{ margin: "0 10px" }}>
                              <strong style={{ color: "blue" }}>
                                {option?.fullname.toString()}
                              </strong>
                              ({option?.employee_id?.toUpperCase()})
                            </p>
                          );
                        }}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            newInputValue &&
                              getMyTeamDetails(`employee_id=${newInputValue}`);
                          }, 1000);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <div style={{ padding: "8px 0" }}></div>

                    <div>
                      <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                       Week-off range
                      </label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                className="input"
                name="start_date"
                id="noTyping"
                type="date"
                label="Start Date"
               // value={start_date}
                variant="outlined"
                inputProps={{
                  max: moment().format("DD-MM-YYYY"),
                  min: moment().format("DD-MM-YYYY"),
                }}
                // onChange={(e) =>
                //   String(e.target.value).length <= 10
                //     ? setStart_date(e.target.value as string)
                //     : ""
                // }
                style={{ width: "100%" }}
              />
              <TextField
                className="input"
                name="end_date"
                id="noTyping"
                type="date"
                label="End Date"
              //  value={start_date}
                variant="outlined"
                inputProps={{
                  max: moment().format("DD-MM-YYYY"),
                  min: moment().format("DD-MM-YYYY"),
                }}
                // onChange={(e) =>
                //   String(e.target.value).length <= 10
                //     ? setStart_date(e.target.value as string)
                //     : ""
                // }
                style={{ width: "100%" }}
              />
                    
                    </div>

                    <div style={{ padding: "5px 0" }}></div>

                    <div>
                      <label
                        htmlFor="reporting-manager"
                        style={{ fontWeight: "bold" }}
                      >
                        Shift plan
                      </label>
                    </div>

                    <div>
                    <Autocomplete
                    onChange={handleAutocompleteChange}
                    id="shift-list"
                    options={shiftList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getShiftList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return userShiftTime
                        ? userShiftTime
                        : option.start_time + " To " + option.end_time;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}> Shift :</b>
                          <strong>
                            {option?.start_time} To {option.end_time}
                          </strong>
                          <br />
                          <b style={{ color: "red" }}>Total Hours:</b>
                          {option?.total_hour}
                        </p>
                      );
                    }}
                    defaultValue={userShiftTime ? userShiftTime : null}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                    </div>

                    <div style={{ padding: "5px 0" }}></div>

                    {/* <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Clock-In method
                      </label>
                    </div> */}
{/* 
                    <div>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        // onChange={(e: any) =>
                        //   handleInputChange("nationality", e.target.value)
                        // }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value="indian">App</MenuItem>
                        <MenuItem value="others">Bio-metric</MenuItem>
                      </TextField>
                    </div> */}


                    <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Week-off
                      </label>
                    </div>

                    <div>
                    <Autocomplete
                    id="ticket_status"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item.value);
                      }
                      setWeekOffDays(sourceIds);
                    }}
                    options={[
                      {
                        text: "Monday",
                        value: "Monday",
                      },
                      {
                        text: "Tuesday",
                        value: "Tuesday",
                      },
                      {
                        text: "Wednesday",
                        value: "Wednesday",
                      },
                      {
                        text: "Thursday",
                        value: "Thursday",
                      },

                      {
                        text: "Friday",
                        value: "Friday",
                      },
                      {
                        text: "Saturday",
                        value: "Saturday",
                      },
                      {
                        text: "Sunday",
                        value: "Sunday",
                      },
                    ]}
                    freeSolo
                    defaultValue={PreviousWeekOffDays}
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    multiple
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return WeekOffDays.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                    </div>

                    <div style={{ padding: "5px 0" }}></div>


                    <div style={{ display:"flex", justifyContent:"center"}}>
                      <Button variant="contained">
                        Submit
                      </Button>
                    </div>
                  </Box>
                </Drawer>
              </>
            ))}
          </div>
        </div>

        <div style={{ width: "100%", display: "flex", borderRadius: "10px" }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <input
                          type="checkbox"
                          className="input"
                          name="selectall"
                          id="selectall"
                          onChange={(e) =>
                            e.target.checked
                              ? setSelectedEmployees(
                                  team_details &&
                                    team_details.map(
                                      (data: any, index: number) => {
                                        if (index < 100) {
                                          return data?.employee_id;
                                        }
                                      }
                                    )
                                )
                              : setSelectedEmployees([])
                          }
                        />
                      </div>
                      
                      <input
                       type="text"
                       style={{ width:"80%", padding:"5px", borderRadius:"10px", backgroundColor:"white"}}
                       placeholder="Search by Id or name"
                       onChange={(e) => {
                       clearTimeout(timer.current);
                       timer.current = setTimeout(() => {
                        getMyTeamDetails(`search=${e.target.value}`);
                       }, 1000);
                    }}
                  />
                    
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">{dates[0]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[1]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[2]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[3]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[4]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[5]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[6]}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {team_details.map((empDetail: any, index: number) => (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            name="selectall"
                            id="selectall"
                            checked={selectedEmployees.find(
                              (qa: any) => qa === empDetail.employee_id
                            )}
                            onChange={(e) =>
                              checkSelectedEmployees(e, empDetail.employee_id)
                            }
                          />
                        </div>
                        <div>
                          <Avatar
                            // key={data.id}
                            // alt="User Avatar"
                            // src={
                            //   data.profile_picture
                            //     ? data.profile_picture
                            //     : "/static/images/avatar/1.jpg"
                            // }
                            style={{
                              backgroundColor: "rgb(14, 63, 108)",
                              cursor: "pointer",
                              border: "1px solid black",
                              height: "50px",
                              width: "50px",
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <p>
                            <b style={{ color: "blue" }}>Employee Name:</b>
                            <strong>{empDetail.fullname}</strong>
                            <br />
                            <Chip
                              label={empDetail.employee_id}
                              color="primary"
                              variant="outlined"
                            />
                            <Chip
                              label={empDetail.designation}
                              color="success"
                              variant="outlined"
                              style={{ marginLeft: "5px" }}
                            />
                          </p>
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift === "10"
                        ? `${empDetail.shift} hours`
                        : empDetail.weekly_off_days.map((item: any) => (
                            <Chip
                              label={item}
                              color="success"
                              variant="outlined"
                            />
                          ))}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift2}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift3}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift4}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift5}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift6}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift7}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  team_details: state.loginReducer.team_details,
  shiftList: state.loginReducer.shiftList,

});

export default connect(mapStateToProps, {
  getMyTeamDetails,
  getShiftList,
})(WeeklyRoster);
