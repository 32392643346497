const isSelected = (field) => {
  if (
    field === "all" ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

const isSelectedNum = (field) => {
  if (
    field === 0 ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};


export const generateLeaveFilter = (body) => {
  const { employee_id, date } = body;
  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const date_filter = isSelected(date) ? `&leave_date=${date}` : "";
  const url = `?${name_filter}${date_filter}`;
  return url;
};
export const generateEmployeeFilter = (body) => {
  const { employee_id, department, manager,employee_status ,start_date,end_date,employee,active_employee,location} = body;
  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const name_employee_status = isSelected(employee_status)
    ? `&employee_status=${employee_status}`
    : "";
  const name_Is_active = isSelected(active_employee)
    ? `&active_employee=${active_employee}`
    : "";
  const employee_department = isSelected(department) ? `&department=${department}` : "";
  const employee_manager = isSelected(manager) ? `&manager=${manager}` : "";
  const employee_start_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
  const employee_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const employee_Data = isSelected(employee) ? `&employee=${employee}` : "";
  const employee_location = isSelected(location) ? `&location=${location}` : "";
  const url = `?${name_filter}${employee_department}${employee_manager}${name_employee_status}${employee_start_date}${employee_end_date}${employee_Data}${name_Is_active}${employee_location}`;
  return url;
};

export const generateUrlRegularze = (body) => {
  const {
    employeeUUid,
    reportingManager,
    employeeDept,
    fromDate,
    toDate,
  } = body;

  const employee = isSelected(employeeUUid)
    ? `&employee=${employeeUUid}`
    : "";
  const ReportingManager = isSelected(reportingManager)
    ? `&manager=${reportingManager}`
    : "";
  const EmployeeDepartment = isSelected(employeeDept)
    ? `&department=${employeeDept}`
    : "";
  const FromDate = isSelected(fromDate)
    ? `&from_date=${fromDate}`
    : "";
  const ToDate = isSelected(toDate)
    ? `&to_date=${toDate}`
    : "";

  const url = `${employee}${ReportingManager}${EmployeeDepartment}${FromDate}${ToDate}`
  return url;
}
export const generateAttendanceFilter = (body) => {
  const { employee_id, start_date, end_date, department,employee_type } = body;

  const employee_name = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const employee_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const employee_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";

  const employee_department = isSelected(department)
    ? `&department=${department}`
    : "";

  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}&start_date=${start_date}&end_date=${end_date}`
    : "";
  const name_employee_type = isSelected(employee_type)
    ? `&employee_type=${employee_type}`
    : "";
  const url = `?${employee_name}${employee_start_date}${employee_end_date}${employee_department}${name_employee_type}`;
  return url;
};

export const generateLmsFilter = (body) => {
  const { name} = body;
  const nameFilter = isSelected(name) ? `&name=${name}` : "";
  const url = `?${nameFilter}`;
  return url;
}

export const generateDesignationFilter = (body) => {
  const { name} = body;
  const nameFilter = isSelected(name) ? `&name=${name}` : "";
  const url = `?${nameFilter}`;
  return url;
}
