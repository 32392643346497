import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  TableContainer,
  withStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { AppBar, Tabs, Tab } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { ACTIONS } from "../../../interfaces/actionTypes/loginTypes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import "./index.sass";
import {
  getEmployeeList,
  getEmployeeDepartment,
  getEmployeeALlData,
  getUsersReport,
  getUsersReportExport,
  getUsersRegularizationReport,
  getUsersRegularizationReportExport,
  getEmployeeLeaveCount,
  getEmployeeLeaveCountExport,
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import { generateEmployeeFilter } from "../../../helper/generate";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      position: "absolute",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      position: "absolute",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getEmployeeList: any;
  employeesList: any;
  editEmployeeById: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  userReportloading: any;
  getEmployeeALlData: any;
  employee_all_data: any;
  getUsersReport: any;
  userReport: any;
  getUsersReportExport: any;
  userReportExport: any;
  userReportloadingExport: any;
  getUsersRegularizationReport: any;
  userRegularizationReport: any;
  userRegularizationReportloading: any;
  getUsersRegularizationReportExport: any;
  userRegularizationReportExport: any;
  userRegularizationReportExportLoading: any;
  getEmployeeLeaveCount: any;
  empLeaveCount: any;
  empLeaveCountLoader: any;
  getEmployeeLeaveCountExport: any;
  empLeaveCountExport: any;
  empLeaveExportCountLoader: any;
}
const CreateEmployeetTablePage: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  getEmployeeDepartment,
  departmentNames,
  userReportloading,
  getEmployeeALlData,
  employee_all_data,
  getUsersReport,
  userReport,
  getUsersReportExport,
  userReportExport,
  userReportloadingExport,
  getUsersRegularizationReport,
  userRegularizationReport,
  userRegularizationReportloading,
  getUsersRegularizationReportExport,
  userRegularizationReportExport,
  userRegularizationReportExportLoading,
  getEmployeeLeaveCount,
  empLeaveCount,
  empLeaveCountLoader,
  getEmployeeLeaveCountExport,
  empLeaveCountExport,
  empLeaveExportCountLoader,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const [employeeId, setEmployeeId] = useState<any>("");
  const [FilterBtn, setFilterBtn] = useState<any>(false);
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [end_date, setEnd_datee] = useState<any>("");
  const [start_date, setStart_date] = useState<any>("");
  const [report_type, setReport_type] = React.useState<any>("");
  const MyCurrenttoday = moment().format("YYYY-MM-DD");

  useEffect(() => {
    getEmployeeList("get_all=true");
    getEmployeeDepartment("");
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = userReport.links && userReport.links.next.split("?")[1];

      getUsersReport(`${url}`);
    } else if (newPage < page) {
      let url = userReport.links && userReport.links.previous.split("?")[1];

      getUsersReport(`${url}`);
    }
    setPage(newPage as number);
  };
  const handleChangeRegularizationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        userRegularizationReport.links &&
        userRegularizationReport.links.next.split("?")[1];

      getUsersRegularizationReport(`${url}`);
    } else if (newPage < page) {
      let url =
        userRegularizationReport.links &&
        userRegularizationReport.links.previous.split("?")[1];

      getUsersRegularizationReport(`${url}`);
    }
    setPage(newPage as number);
  };

  const handleResetFunction = () => {
    history.push("/dashboard/payrollManager/employee_report");
  };

  const handlFilterEmployee = () => {
    setFilterBtn(true);
    const body: any = {
      start_date: start_date,
      end_date: end_date,
      employee: employeeId,
      department: employeeDept != 64 ? employeeDept : "",
    };
    const url = generateEmployeeFilter(body).substring(2);
    if (report_type.includes("leave")) {
      getUsersReport(`${url}`);
      getUsersReportExport(`export=true&${url}`);
    } else if (report_type.includes("regularization")) {
      getUsersRegularizationReport(`${url}`);
      getUsersRegularizationReportExport(`export=true&${url}`);
    } else if (report_type.includes("leaveCount")) {
      getEmployeeLeaveCount(`${url}`);
      getEmployeeLeaveCountExport(`export=true${url}`);
    }

    setPage(0);
  };

  const LeaveexportData = () => {
    var rows = [];

    //iterate through rows of table
    let row = userReportExport;
    rows.push([
      "Employee Name	",
      "Employee ID",
      "Business Unit",
      "Department",
      "Designation	",
      "From Date Time",
      "To Date Time",
      "Leave Name",
      "Total Days/Hours",
      "Working Day Count",
      "Non Working Day Count",
      "Leave Start Type Name",
      "Leave End Type Name",
      "Applied On",
      "Request Status",
      "Manager Name",
      "Action Taken By",
      "Action Taken Date",
      "Reason",
    ]);

    for (var i = 0; i < row.length; i++) {
      rows.push([
        row[i]?.fullname,
        row[i]?.employee_id,
        row[i]?.organisation_name,
        row[i]?.department,
        row[i]?.designation,
        row[i]?.from_date,
        row[i]?.to_date,
        row[i]?.leave_name,
        row[i]?.total_days,
        row[i]?.working_day,
        row[i]?.non_working_day,
        row[i]?.leave_from_session,
        row[i]?.leave_to_session,
        row[i]?.created_at?.substring(0, 10),
        row[i]?.status,
        row[i]?.manager_name,
        row[i]?.approved_by,
        row[i]?.updated_at?.substring(0, 10),
        row[i]?.reason_for_leave,
      ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
      row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "EmployeeLeaveReportList.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({ type: ACTIONS.GET_USER_REPORT_EXPORT, payload: [] });
  };

  const RegularizationexportData = () => {
    var rows = [];

    //iterate through rows of table
    let row = userRegularizationReportExport;
    rows.push([
      "Employee Code",
      "Employee Name",
      "Domain",
      "Department",
      "Designation	",
      "Shift Name",
      "Device Policy Name",
      "Weekoff Policy Name",
      "From Date Time",
      "To Date Time",
      "Request Type Name",
      "Work Request Type Name",
      "Comment",
      "Applied On",
      "Request Status",
      "Manager Name",
      "Action Taken By",
      "Action Taken Date",
    ]);
    const getDateSubstring = (dateString: any) => {
      return dateString ? dateString.substring(0, 10) : "";
    };

    for (var i = 0; i < row.length; i++) {
      rows.push([
        row[i]?.employee_id,
        row[i]?.fullname,
        row[i]?.domain,
        row[i]?.department,
        row[i]?.designation,
        row[i]?.shift,
        row[i]?.attendance_mode,
        (row[i]?.weekly_off && Array.isArray(row[i]?.weekly_off)
          ? row[i]?.weekly_off.join(", ")
          : "") || "",
        getDateSubstring(row[i]?.check_in),
        getDateSubstring(row[i]?.check_out),
        "Regularize Attendance Request",
        row[i]?.regularization_category,
        row[i]?.comments,
        getDateSubstring(row[i]?.created_at),
        row[i]?.status,
        row[i]?.manager_name,
        row[i]?.approved_by,
        getDateSubstring(row[i]?.approved_date),
      ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
      row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "EmployeeRegularizationReportList.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: ACTIONS.GET_USER_REGULARIZATION_REPORT_EXPORT,
      payload: [],
    });
  };

  const DownloadEmployeeLeaveCount = () => {
    let row = empLeaveCountExport;
    let csvContent = [
      [
        "Employee Id",
        "Employee Name",
        "Earn Leave",
        "Casual Leave",
        "Sick Leave",
        "Intern Leave",
        "Consultancy Leave",
        "Compoff Leave",
        "Maternity Leave",
        "Paternity Leave",
        "Birthday Leave",
      ],
    ];
  
    for (let i = 0; i < row.length; i++) {
      csvContent.push([
        row[i]?.employee_id ?? 0,
        row[i]?.fullname ?? "N/A",
        row[i]?.earn_leave ?? 0,
        row[i]?.casual_leave ?? 0,
        row[i]?.sick_leave ?? 0,
        row[i]?.intern_leave ?? 0,
        row[i]?.consultancy_leave ?? 0,
        row[i]?.compoff_leave ?? 0,
        row[i]?.maternity_leave ?? 0,
        row[i]?.paternity_leave ?? 0,
        row[i]?.birthday_leave ?? 0,
      ]);
    }
    let csvString = csvContent.map(rowArray => rowArray.join(",")).join("\r\n");
    let blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    let link = document.createElement("a");
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "EmployeeLeaveCount.csv");
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
    dispatch({
      type: ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT,
      payload: [],
    });
  };
  

  useEffect(() => {
    if (report_type.includes("regularization")) {
      dispatch({ type: ACTIONS.GET_USER_REGULARIZATION_REPORT, payload: [] });
      dispatch({
        type: ACTIONS.GET_USER_REGULARIZATION_REPORT_EXPORT,
        payload: [],
      });
    } else if (report_type.includes("leave")) {
      dispatch({ type: ACTIONS.GET_USER_REPORT, payload: [] });
      dispatch({ type: ACTIONS.GET_USER_REPORT_EXPORT, payload: [] });
    } else if (report_type.includes("leaveCount")) {
      dispatch({ type: ACTIONS.EMPLOYEE_LEAVE_COUNT, payload: [] });
      dispatch({
        type: ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT,
        payload: [],
      });
    }
  }, [report_type]);
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            backgroundColor: "rgb(28, 78, 128)",
            color: "#fff",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "10px",
            borderRadius: "15px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          Employee Report
        </h4>

        <Grid
          container
          spacing={3}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={3} md={2}>
            <Autocomplete
              id="report_type"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = [obj.value];
                }
                setReport_type(sourceIds);
              }}
              options={[
                {
                  text: "Leave",
                  value: "leave",
                },
                {
                  text: "Regularization",
                  value: "regularization",
                },
                {
                  text: "Leave Count",
                  value: "leaveCount",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return report_type.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Report Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          {!report_type.includes("leaveCount") && (
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                className="input"
                name="start_date"
                id="noTyping"
                type="date"
                label="Start Date"
                value={start_date}
                variant="outlined"
                inputProps={{
                  max: moment().format("DD-MM-YYYY"),
                  min: moment().format("DD-MM-YYYY"),
                }}
                onChange={(e) =>
                  String(e.target.value).length <= 10
                    ? setStart_date(e.target.value as string)
                    : ""
                }
                style={{ width: "100%" }}
              />
            </Grid>
          )}
          {!report_type.includes("leaveCount") && (
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                className="input"
                name="end_date"
                id="noTyping"
                type="date"
                label="End Date"
                inputProps={{
                  max: MyCurrenttoday,
                }}
                value={end_date}
                variant="outlined"
                onChange={(e) =>
                  String(e.target.value).length <= 10
                    ? setEnd_datee(e.target.value as string)
                    : ""
                }
                style={{ width: "100%" }}
              />
            </Grid>
          )}
          {!report_type.includes("leaveCount") && (
            <Grid item xs={12} sm={3} md={3}>
              <Autocomplete
                onChange={(event, obj) => {
                  if (obj) {
                    setEmployeeId(obj.uuid);
                  } else {
                    setEmployeeId("");
                  }
                }}
                id="checkboxes-tags-demo"
                options={employee_all_data?.results || []}
                getOptionLabel={(option: any) => {
                  return option?.employee_id + " " + option.name;
                }}
                renderOption={(option: any) => {
                  return (
                    <p>
                      <b style={{ color: "blue" }}>Employee Name:</b>
                      <strong>
                        {option?.name.toString()}
                        <br />
                      </strong>
                      <b style={{ color: "red" }}>Employee Id:</b>
                      {option?.employee_id?.toUpperCase()}
                    </p>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    newInputValue &&
                      getEmployeeALlData(`employee_id=${newInputValue}`);
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Employee ID Or Name"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {!report_type.includes("leaveCount") && (
            <Grid item xs={12} sm={3} md={2}>
              <Autocomplete
                onChange={(event, obj) => {
                  if (obj) {
                    setEmployeeDept(obj.id);
                  } else {
                    setEmployeeDept("");
                  }
                }}
                id="department-name"
                options={departmentNames || []}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    newInputValue &&
                      getEmployeeDepartment(`name=${newInputValue}`);
                  }, 1000);
                }}
                getOptionLabel={(option: any) => {
                  return option.name;
                }}
                renderOption={(option: any) => {
                  return option?.name;
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Department Name"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{
                backgroundColor: !report_type.includes("leaveCount")
                  ? start_date !== "" && end_date !== ""
                    ? "#1976d2"
                    : "gray"
                  : "#1976d2",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              startIcon={<SearchIcon />}
              onClick={handlFilterEmployee}
              disabled={
                !report_type.includes("leaveCount")
                  ? start_date === "" && end_date === ""
                  : false
              }
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = !report_type.includes(
                  "leaveCount"
                )
                  ? start_date !== "" && end_date !== ""
                    ? "#1565c0"
                    : "gray"
                  : "#1565c0";
                e.currentTarget.style.boxShadow = !report_type.includes(
                  "leaveCount"
                )
                  ? start_date !== "" && end_date !== ""
                    ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                    : "none"
                  : "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = !report_type.includes(
                  "leaveCount"
                )
                  ? start_date !== "" && end_date !== ""
                    ? "#1976d2"
                    : "gray"
                  : "#1976d2";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<RotateLeftIcon />}
              onClick={() => handleResetFunction()}
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Reset
            </Button>
          </Grid>
          {report_type.includes("leave") && (
            <>
              <Grid item xs={12} sm={4} md={4}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor:
                      !FilterBtn || userReportloadingExport
                        ? "gray"
                        : "#009688",
                    color: "#ffffff",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    if (!FilterBtn || userReportloadingExport) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#00796b";
                    e.currentTarget.style.boxShadow =
                      "0px 2px 4px rgba(0, 0, 0, 0.1)";
                  }}
                  onMouseOut={(e) => {
                    if (!FilterBtn || userReportloadingExport) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#009688";
                    e.currentTarget.style.boxShadow = "none";
                  }}
                  disabled={!FilterBtn || userReportloadingExport}
                  startIcon={
                    userReportloadingExport ? null : <ArrowCircleDownIcon />
                  }
                  onClick={LeaveexportData}
                >
                  {userReportloadingExport ? (
                    <span>
                      Preparing data... <br />
                      <small>Please wait, we are preparing data for you.</small>
                    </span>
                  ) : (
                    "Download Employee Leave Report"
                  )}
                </LoadingButton>
              </Grid>
            </>
          )}
          {report_type.includes("regularization") && (
            <>
              <Grid item xs={12} sm={4} md={4}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor:
                      !FilterBtn || userRegularizationReportExportLoading
                        ? "gray"
                        : "#009688",
                    color: "#ffffff",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    if (!FilterBtn || userRegularizationReportExportLoading) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#00796b";
                    e.currentTarget.style.boxShadow =
                      "0px 2px 4px rgba(0, 0, 0, 0.1)";
                  }}
                  onMouseOut={(e) => {
                    if (!FilterBtn || userRegularizationReportExportLoading) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#009688";
                    e.currentTarget.style.boxShadow = "none";
                  }}
                  disabled={!FilterBtn || userRegularizationReportExportLoading}
                  startIcon={
                    userRegularizationReportExportLoading ? null : (
                      <ArrowCircleDownIcon />
                    )
                  }
                  onClick={RegularizationexportData}
                >
                  {userRegularizationReportExportLoading ? (
                    <span>
                      Preparing data... <br />
                      <small>Please wait, we are preparing data for you.</small>
                    </span>
                  ) : (
                    "Download Employee Regularization Report"
                  )}
                </LoadingButton>
              </Grid>
            </>
          )}
          {report_type.includes("leaveCount") && (
            <>
              <Grid item xs={12} sm={4} md={4}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor:
                      !FilterBtn || empLeaveExportCountLoader
                        ? "gray"
                        : "#009688",
                    color: "#ffffff",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    if (!FilterBtn || empLeaveExportCountLoader) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#00796b";
                    e.currentTarget.style.boxShadow =
                      "0px 2px 4px rgba(0, 0, 0, 0.1)";
                  }}
                  onMouseOut={(e) => {
                    if (!FilterBtn || empLeaveExportCountLoader) {
                      return;
                    }
                    e.currentTarget.style.backgroundColor = "#009688";
                    e.currentTarget.style.boxShadow = "none";
                  }}
                  disabled={!FilterBtn || empLeaveExportCountLoader}
                  startIcon={
                    empLeaveExportCountLoader ? null : <ArrowCircleDownIcon />
                  }
                  onClick={DownloadEmployeeLeaveCount}
                >
                  {empLeaveExportCountLoader ? (
                    <span>
                      Preparing data... <br />
                      <small>Please wait, we are preparing data for you.</small>
                    </span>
                  ) : (
                    "Download Employee Leave Count"
                  )}
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
        <div
          style={{
            padding: "12px",
            marginTop: "12px",
            border: "1px solid #ccc",
            borderRadius: "6px",
            backgroundColor: "#e3f2fd",
            display: "inline-block",
            fontSize: "15px",
            color: "#333",
          }}
        >
          <span style={{ color: "#004d40" }}>
            📄 Please select the <strong>Report Type</strong> before viewing the
            report.
          </span>{" "}
          <br />
          <span style={{ color: "#1976d2" }}>
            📅 Please select both the <strong>Start Date</strong> and{" "}
            <strong>End Date</strong> to generate the Employee Report.
          </span>
          <br />
          <span style={{ color: "#c62828" }}>
            📋 Click the <strong>filter button</strong> to enable the{" "}
            <strong>Download Employee Report </strong> option.
          </span>
        </div>
        {report_type.includes("leave") && (
          <>
            <h4
              style={{
                marginTop: "10px",
                fontSize: "1.5rem",
                fontWeight: 600,
                color: "#0d47a1",
                margin: "0",
                padding: "8px 0",
                borderBottom: "2px solid #0d47a1",
                textTransform: "capitalize",
                letterSpacing: "0.5px",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              Leave Report
            </h4>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Employee Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Employee ID
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Business Unit
                    </StyledTableCell>
                    <StyledTableCell align="center">Department</StyledTableCell>
                    <StyledTableCell align="center">
                      Designation
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      From Date Time
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      To Date Time
                    </StyledTableCell>
                    <StyledTableCell align="center">Leave Name</StyledTableCell>
                    <StyledTableCell align="center">
                      Total Days/Hours
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Working Day Count
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Non Working Day Count
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Leave Start Type Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Leave End Type Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Applied On</StyledTableCell>
                    <StyledTableCell align="center">
                      Request Status
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Manager Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Action Taken By
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Action Taken Date
                    </StyledTableCell>
                    <StyledTableCell align="center">Reason</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {userReportloading ? (
                  <Loader />
                ) : (
                  <>
                    <TableBody>
                      {userReport &&
                        userReport?.results?.length > 0 &&
                        userReport?.results?.map((data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {data.fullname}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.employee_id}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.organisation_name &&
                                data?.organisation_name !== null
                                  ? data?.organisation_name
                                  : "NA"}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.department}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.designation && data?.designation !== null
                                  ? data?.designation
                                  : "NA"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.from_date}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.to_date}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.leave_name}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.total_days}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.working_day}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.non_working_day}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.leave_from_session}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.leave_to_session}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <span>
                                  Date: {data?.created_at.substring(0, 10)}
                                </span>
                                <br />
                                <span>
                                  Time: {data?.created_at?.substring(11, 19)}
                                </span>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.status}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.manager_name &&
                                data?.manager_name !== null
                                  ? data?.manager_name
                                  : "NA"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.approved_by !== null
                                  ? typeof data?.approved_by === "object"
                                    ? data.approved_by.name
                                    : data.approved_by
                                  : "NA"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.updated_at !== null && (
                                  <>
                                    <span>
                                      Date: {data?.updated_at.substring(0, 10)}
                                    </span>
                                    <br />
                                    <span>
                                      Time:{" "}
                                      {data?.updated_at?.substring(11, 19)}
                                    </span>
                                  </>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.reason_for_leave &&
                                data?.reason_for_leave !== null
                                  ? data?.reason_for_leave
                                  : "NA"}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </>
                )}

                <TableFooter>
                  <StyledTableRow style={{ textAlign: "center" }}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={6}
                      count={userReport?.count || 0}
                      rowsPerPage={userReport?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
        {report_type.includes("regularization") && (
          <>
            <h4
              style={{
                marginTop: "10px",
                fontSize: "1.5rem",
                fontWeight: 600,
                color: "#0d47a1",
                margin: "0",
                padding: "8px 0",
                borderBottom: "2px solid #0d47a1",
                textTransform: "capitalize",
                letterSpacing: "0.5px",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              Regularization Report
            </h4>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "560px" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Employee Code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Employee Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Domain </StyledTableCell>
                    <StyledTableCell align="center">Department</StyledTableCell>
                    <StyledTableCell align="center">
                      Designation
                    </StyledTableCell>
                    <StyledTableCell align="center">Shift Name</StyledTableCell>
                    <StyledTableCell align="center">
                      Device Policy Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Weekoff Policy Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      From Date Time
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      To Date Time
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Request Type Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Work Request Type Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Comment</StyledTableCell>
                    <StyledTableCell align="center">Applied On</StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      Request Status{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Manager Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Action Taken By
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Action Taken Date
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {userRegularizationReportloading ? (
                  <Loader />
                ) : (
                  <>
                    <TableBody>
                      {userRegularizationReport &&
                        userRegularizationReport?.results?.length > 0 &&
                        userRegularizationReport?.results?.map(
                          (data: any, index: any) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {data.employee_id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.fullname}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {data?.doman && data?.doman !== null
                                    ? data?.doman
                                    : "NA"}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {data?.department}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.designation &&
                                  data?.designation !== null
                                    ? data?.designation
                                    : "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.shift}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {data?.attendance_mode}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.weekly_off !== null
                                    ? data?.weekly_off.join(", ")
                                    : "NA"}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <span>
                                    Date: {data?.check_in.substring(0, 10)}
                                  </span>
                                  <br />
                                  <span>
                                    Time: {data?.check_in?.substring(11, 19)}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <span>
                                    Date: {data?.check_out.substring(0, 10)}
                                  </span>
                                  <br />
                                  <span>
                                    Time: {data?.check_out?.substring(11, 19)}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Regularize Attendance Request
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.regularization_category}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.comments}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <span>
                                    Date: {data?.created_at.substring(0, 10)}
                                  </span>
                                  <br />
                                  <span>
                                    Time: {data?.created_at?.substring(11, 19)}
                                  </span>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {data?.status}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.manager_name &&
                                  data?.manager_name !== null
                                    ? data?.manager_name
                                    : "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.approved_by !== null
                                    ? typeof data?.approved_by === "object"
                                      ? data.approved_by.name
                                      : data.approved_by
                                    : "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.approved_date !== null &&
                                  data?.approved_date !== undefined ? (
                                    <>
                                      <span>
                                        Date:{" "}
                                        {data?.approved_date?.substring(0, 10)}
                                      </span>
                                      <br />
                                      <span>
                                        Time:{" "}
                                        {data?.approved_date?.substring(11, 19)}
                                      </span>
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </>
                )}

                <TableFooter>
                  <StyledTableRow style={{ textAlign: "center" }}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={6}
                      count={userRegularizationReport?.count || 0}
                      rowsPerPage={userRegularizationReport?.page_size}
                      page={page}
                      onPageChange={handleChangeRegularizationPage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
        {report_type.includes("leaveCount") && (
          <>
            <h4
              style={{
                marginTop: "10px",
                fontSize: "1.5rem",
                fontWeight: 600,
                color: "#0d47a1",
                margin: "0",
                padding: "8px 0",
                borderBottom: "2px solid #0d47a1",
                textTransform: "capitalize",
                letterSpacing: "0.5px",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              Employee Leave Count
            </h4>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "560px" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Employee Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Employee ID
                    </StyledTableCell>
                    <StyledTableCell align="center">Earn Leave</StyledTableCell>
                    <StyledTableCell align="center">
                      Casual Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">Sick Leave</StyledTableCell>
                    <StyledTableCell align="center">Earn Leave</StyledTableCell>
                    <StyledTableCell align="center">
                      Intern Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Consultancy Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Compoff Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Maternity Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Paternity Leave
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Leave Start Type Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Birthday Leave
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {empLeaveCountLoader ? (
                  <Loader />
                ) : (
                  <>
                    <TableBody>
                      {empLeaveCount &&
                        empLeaveCount?.results &&
                        empLeaveCount?.results?.length > 0 &&
                        empLeaveCount?.results?.map((data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {data.fullname}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.employee_id}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data.earn_leave}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.casual_leave}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.sick_leave}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.intern_leave}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.consultancy_leave}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.compoff_leave}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {data?.maternity_leave}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.paternity_leave}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data?.birthday_leave}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </>
                )}

                {/* <TableFooter>
                  <StyledTableRow style={{ textAlign: "center" }}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={6}
                      count={userReport?.count || 0}
                      rowsPerPage={userReport?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter> */}
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  userReport: state.loginReducer.userReport,
  userReportloading: state.loginReducer.userReportloading,
  departmentNames: state.loginReducer.departmentNames,
  employee_all_data: state.loginReducer.employee_all_data,
  userReportExport: state.loginReducer.userReportExport,
  userReportloadingExport: state.loginReducer.userReportloadingExport,
  userRegularizationReport: state.loginReducer.userRegularizationReport,
  userRegularizationReportloading:
    state.loginReducer.userRegularizationReportloading,
  userRegularizationReportExport:
    state.loginReducer.userRegularizationReportExport,
  userRegularizationReportExportLoading:
    state.loginReducer.userRegularizationReportExportLoading,
  empLeaveCount: state.loginReducer.empLeaveCount,
  empLeaveCountLoader: state.loginReducer.empLeaveCountLoader,
  empLeaveCountExport: state.loginReducer.empLeaveCountExport,
  empLeaveExportCountLoader: state.loginReducer.empLeaveExportCountLoader,
});
export default connect(mapStateToProps, {
  getEmployeeList,
  getEmployeeDepartment,
  getEmployeeALlData,
  getUsersReport,
  getUsersReportExport,
  getUsersRegularizationReport,
  getUsersRegularizationReportExport,
  getEmployeeLeaveCount,
  getEmployeeLeaveCountExport,
})(CreateEmployeetTablePage);
