import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Button } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import PPTModal from "./PPTModule/pptModule";
import { getCreateModule } from "../../../actions/loginActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#1b526f",
    border: "none",
    borderRadius: "10px",
    width: isMobile ? "200px" : "265px",
    margin: "10px 10px",
    padding: "5px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },

  startButton: {
    borderRadius: "20px",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
    background: "linear-gradient(45deg, #404fff 30%, #07ff88 90%);",
  },

  scrollContainer: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "6px",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#fff",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  getCreateModule: any;
  createModuleData: any;
};

const LearningModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  getCreateModule,
  createModuleData,
}) => {
  const classes = useStyles();

  const [openPPT, setOpenPPT] = useState(false);
  const [myModule, setMyModule] = useState<any>();

  const handleClose = () => {
    localStorage.setItem("tokeninlocal", "delete");
    setOpen(false);
  };

  useEffect(() => {
    getCreateModule();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.scrollContainer} style={{ display: "flex" }}>
        {createModuleData &&
            createModuleData?.results?.length > 0 &&
            createModuleData?.results?.filter((data:any)=> data?.status === 'active' && data?.score_by_user === "Fail").map((row: any) => (
              <div className={classes.paper} key={row.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    margin: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap:"5px",
                      color: "white",
                      minWidth:"265px",
                      width: "auto",
                      height: "80px",
                    
                    }}
                  >
                    <span style={{ fontSize: "1rem", textAlign: "center" }}>
                      {row.name}
                    </span>
                    <span>
                      ({row.completion_hours * 60 + row.completion_minutes}{" "}
                      mins)
                    </span>
                    <Button
                      variant="contained"
                      className={classes.startButton}
                      onClick={() => {
                        setOpenPPT(true);
                        setMyModule(row);
                      }}
                    >
                      Start Module
                    </Button>
                  </div>
                 
                </div>
              </div>
            ))}
        </div>
      </Dialog>

      {openPPT && (
        <PPTModal open={openPPT} setOpen={setOpenPPT} myModule={myModule} />
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
  getCreateModule,
})(LearningModal);
