import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import {
    Paper,
    TextField,
    Grid,
    Card,
    IconButton,
    CircularProgress,
    Switch,
} from "@material-ui/core";
import {
    getManageAttendance,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeDepartment,
    getManageAttendanceWithOutPagination,
    CreateLockAction,
    getUsersLockAction,
    UpdateUsersLockAction,
    getUpdateBiometric
} from "../../../actions/loginActions";
import LoadingButton from "@mui/lab/LoadingButton";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Loader from "../Loader2";
import AttractionsIcon from "@mui/icons-material/Attractions";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateAttendanceFilter } from "../../../helper/generate";
import { useHistory } from "react-router";
import CustomDrawer from "../../../common/CustomDrawer";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ACTIONS } from "../../../interfaces/actionTypes/loginTypes";
import moment from "moment";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableFooter,
    TablePagination,
    Select,
    MenuItem,
} from "@material-ui/core";
import UpdateAttendence from "../../../Admin-Panel/components/Attendance/UpdateAttendence/UpdateAttendence";
import AXIOS from "../../../config/Axios";
import { customSnackbar } from "../../../actions/customSnackBar";
import Securestorage from "../../../config/SecureStorage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid green",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
            position: "absolute",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const DefaultSwitch: any = withStyles({
    switchBase: {
        color: "red",
        "&$checked": {
            color: "green",
        },
        "&$checked + $track": {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red",
    },
})(Switch);

interface Props {
    loading: boolean;
    getManageAttendance: any;
    manageAttendance: any;
    employeesList: any;
    getEmployeeList: any;
    postRegularizationRequest: Function;
    getEmployeeDepartment: Function;
    departmentNames: any;
    getManageAttendanceWithOutPagination: any;
    attendanceDataWithourPageloading: any;
    attendanceDataWithourPage: any;
    manageAttendanceLoading: any;
    CreateLockAction: any;
    getUsersLockAction: any;
    lockDetails: any;
    UpdateUsersLockAction: any;
    getUpdateBiometric: any;
    updateBiometric: any;
}
const Forms: React.FC<Props> = ({
    loading,
    getManageAttendance,
    manageAttendance,
    employeesList,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeDepartment,
    departmentNames,
    getManageAttendanceWithOutPagination,
    attendanceDataWithourPageloading,
    attendanceDataWithourPage,
    manageAttendanceLoading,
    CreateLockAction,
    getUsersLockAction,
    lockDetails,
    UpdateUsersLockAction,
    getUpdateBiometric,
    updateBiometric
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [name, setName] = useState<String>("");
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [employeeUUid, setEmployeeUuid] = useState("");
    const [regularizationCategory, setRegularizationCategory] = useState("work_from_home");
    const [durationtype, setDurationType] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [overlap, setOverlap] = useState("");
    const [reason, setReason] = useState("");
    const [attendance_date, setAttendance_date] = useState("");
    const [openManageAttendanceModel, setOpenManageAttendanceModel] = useState<boolean>(false);
    const [openAttendence, setOpenAttendence] = useState<boolean>(false);
    const [attendance_start_date, setAttendance_start_date] = useState<any>("");
    const [attendance_to_date, setAttendance_to_date] = useState("");
    const [employeeDept, setEmployeeDept] = useState<any>("");
    const [FilterBtn, setFilterBtn] = useState<any>(false);
    const [EmployeeUuidForAttendance, setEmployeeUuidForAttendance] = useState<any>("");
    const [modelLockDate, setModelLockDate] = useState<any>(false);
    const [lockAttendaceEndDate, setLockAttendaceEndDate] = useState<any>("");
    const [lock_Attendace_Start_date, setLockAttendaceStartDate] = useState<any>("");
    const [isActive, setIsActive] = useState(false);
    const [editPackage, setEditPackage] = useState<any>({});
    const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    useEffect(() => {
        getManageAttendance("?get_all=true");
        getEmployeeList("get_all=true");
        getEmployeeDepartment();
        getUsersLockAction("");
    }, []);

    const handleFilter = () => {
        setFilterBtn(true);
        const body: any = {
            employee_id: employeeUUid,
            start_date: attendance_start_date,
            end_date: attendance_to_date,
            department: employeeDept !== 64 ? employeeDept : "",
        };
        const url = generateAttendanceFilter(body).substring(2);
        getManageAttendance(`?get_all=true&${url}`);
        { employeeDept !== 64 && getManageAttendanceWithOutPagination(`?get_all=true&export=true&${url}`); }
        setPage(0);
    };
    const handleSendEmail = () => {
        setDisableFilterBtn(true)
        setFilterBtn(true);
        const body: any = {
            employee_id: employeeUUid,
            start_date: attendance_start_date,
            end_date: attendance_to_date,
            department: employeeDept !== 64 ? employeeDept : "",
        };
        const url = generateAttendanceFilter(body).substring(2);
        getManageAttendanceWithOutPagination(`?get_all=true&export=true&${url}`);
        setPage(0);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                manageAttendance.links && manageAttendance.links.next.split("?")[1];

            getManageAttendance(`?${url}`);
        } else if (newPage < page) {
            let url =
                manageAttendance.links && manageAttendance.links.previous.split("?")[1];

            getManageAttendance(`?${url}`);
        }
        setPage(newPage as number);
    };
    useEffect(() => {
        if (disableFilterBtn) {
            setTimeout(() => {
                setDisableFilterBtn(false);
            }, 100000)
        }
    }, [disableFilterBtn])

    const handleManageAttendance = (data: any) => {
        setOpenManageAttendanceModel(true);
        setFromDate(
            data?.date
                ? data?.date
                : ""
        );
        setFromTime(updatedTime(data?.check_in));
        setToTime(updatedTime(data?.check_out));
        setEmployeeUuidForAttendance(data.employee);
    };
    const handleSubmit = async (e: any) => {
        const timeFormat = "HH:mm";
        const checkInTime = moment(fromTime, timeFormat);
        const checkOutTime = moment(toTime, timeFormat);
        const duration = moment.duration(checkOutTime.diff(checkInTime));
        const hoursDifference = duration.asHours();

        e.preventDefault();
        const data = {
            regularization_category: regularizationCategory,
            from_date: fromDate + " 00:00:00",
            comments: reason,
            check_in: fromDate + "T" + fromTime,
            check_out: fromDate + "T" + toTime,
            employee: EmployeeUuidForAttendance,
            date: fromDate,
        };
        if (hoursDifference < 2) {
            alert(
                "The difference between Check-in and Check-out must be at least 2 hours."
            );
        } else {
            await postRegularizationRequest(data);
            setRegularizationCategory("");
            setDurationType("");
            setFromDate("");
            setToDate("");
            setFromTime("");
            setToTime("");
            setReason("");
            setOpenManageAttendanceModel(false);
        }
    };

    type FormValues = {
        id: string;
        from_date: string;
        time: string;
        selectInDate: string;
        check_in: string;
        selectOutDate: string;
        check_out: string;
        regularization_category: string;
        comments: string;
    };
    const dispatch = useDispatch();
    const [attendenceData, setAttendenceData] = useState<FormValues[]>([]);
    const [addAttendence, setAddAttendence] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);

    const MyCurrenttoday = moment().format("YYYY-MM-DD");

    const handleSendRequest = async () => {
        setRequestLoader(true);

        try {
            const response = await AXIOS.post(
                "api/regularization/bulk/",
                attendenceData,
                {
                    headers: {
                        Authorization: `Token ${Securestorage.getItem("token")}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                setOpenAttendence(false);
                dispatch(customSnackbar(true, "Upload Success", "success"));
            } else {
                console.error(response.data, "responseData Error");
                dispatch(customSnackbar(true, "Unexpected Error", "error"));
            }

            setRequestLoader(false);
        } catch (error: any) {
            console.error("Error:", error.response.data.error);
            dispatch(
                customSnackbar(
                    true,
                    error.response.data.error || "Some Thing Went Wrong",
                    "error"
                )
            );
            setRequestLoader(false);
        }
    };

    const handleRemoveAttendence = (data: any) => {
        const filterAttendenceData = attendenceData?.filter((v) => v?.id !== data);
        setAttendenceData(filterAttendenceData);
    };

    const updatedTime = (timeString: any) => {
        const dateTimeObj = new Date(timeString);
        const extraTime = 5.5 * 60 * 60 * 1000;
        let newDateTimeObj = new Date(dateTimeObj.getTime() + extraTime);
        let newTime = newDateTimeObj.toISOString().slice(11, 19);

        return newTime;
    };
    const formatTime = (timeString: any) => {
        if (!timeString) return "NA";

        const dateTimeObj = new Date(timeString);
        const extraTime = 5.5 * 60 * 60 * 1000;
        const newDateTimeObj = new Date(dateTimeObj.getTime() + extraTime);
        const hours = newDateTimeObj.getUTCHours().toString().padStart(2, "0");
        const minutes = newDateTimeObj.getUTCMinutes().toString().padStart(2, "0");
        const seconds = newDateTimeObj.getUTCSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };

    const exportAttendanceReportData = () => {
        var rows = [];

        let row = attendanceDataWithourPage;
        rows.push([
            "Employee Name",
            "Employee ID",
            "Date",
            "Clock-In",
            "Clock-Out	",
            "Is Regularize",
            "Total Working Hours",
            "Punch In Location",
            "Punch Out Location",
            "Status",
        ]);
        for (var i = 0; i < row.length; i++) {
            rows.push([
                row[i]?.emploayee_code,
                row[i]?.emploayee_name,
                row[i]?.date,
                formatTime(row[i]?.check_in),
                formatTime(row[i]?.check_out),
                row[i]?.is_regularize,
                row[i]?.total_hour,
                row[i]?.punch_in_location,
                row[i]?.punch_out_location,
                row[i]?.status,
            ]);
        }
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
            row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "AttendanceReport.csv");
        document.body.appendChild(link);
        link.click();
        dispatch({ type: ACTIONS.GET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION, payload: [] });
    };
    const getJune21st = () => {
        const today = new Date();
        const year = today.getFullYear();
        return new Date(year, 5, 21).toISOString().split("T")[0];
    };

    const june21st = getJune21st();

    const handleCreateLockDate = async () => {
        const body = {
            start_date: lock_Attendace_Start_date,
            end_date: lockAttendaceEndDate,
        };
        await CreateLockAction(body);
        getUsersLockAction();
    };

    const handleUser = async (data: any) => {
        const body: any = {
            is_active: isActive,
        };
        await UpdateUsersLockAction(body, data.id);
        getUsersLockAction();
        setEditPackage({});
    };

    const handleEditPackage = (pack: any) => {
        setIsActive(pack?.is_active);
        setEditPackage(pack);
    };
    const handleReason = (e: any) => {
        const value = e.target.value;

        // Check if the first character is a space or period
        if (value.length === 0 || !/^[. ]/.test(value[0])) {
            setReason(value);
        }
    };

    const handlRefreshAttendance = async () => {
        getUpdateBiometric(`?employee_id=${employeeUUid}`)
        await alert("Your Attendance is Updated Successfully")
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h4
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(28, 78, 128)",
                    color: "#fff",
                    textAlign: "center",
                    padding: "10px",
                    borderRadius: "15px",
                }}
            >
                Manage Attendance
            </h4>

            <div>
                <Grid container spacing={3} style={{ marginTop: "15px" }}>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeDept(obj.id);
                                } else {
                                    setEmployeeDept("");
                                }
                            }}
                            id="department-name"
                            options={departmentNames || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeeDepartment(`name=${newInputValue}`);
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option.name;
                            }}
                            renderOption={(option: any) => {
                                return option?.name;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Department Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeUuid(obj.employee_id);
                                } else {
                                    setEmployeeUuid("");
                                }
                            }}
                            id="checkboxes-tags-demo"
                            options={employeesList?.results || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeeList(
                                            `get_all=true&employee_id=${newInputValue}`
                                        );
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option?.employee_id + " " + option.name;
                            }}
                            renderOption={(option: any) => {
                                return (
                                    <p>
                                        <b style={{ color: "blue" }}>Employee Name:</b>
                                        <strong>
                                            {option?.name.toString()}
                                            <br />
                                        </strong>
                                        <b style={{ color: "red" }}>Employee Id:</b>
                                        {option?.employee_id?.toUpperCase()}
                                    </p>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Search By Employee Code Or Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            className="input"
                            name="start_date"
                            id="noTyping"
                            type="date"
                            label="Start Date"
                            value={attendance_start_date}
                            variant="outlined"
                            inputProps={{
                                max: moment().format("DD-MM-YYYY"),
                                min: moment().format("DD-MM-YYYY"),
                            }}
                            onChange={(e) =>
                                String(e.target.value).length <= 10
                                    ? setAttendance_start_date(e.target.value as string)
                                    : ""
                            }
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            className="input"
                            name="end_date"
                            id="noTyping"
                            type="date"
                            label="End Date"
                            inputProps={{
                                max: MyCurrenttoday,
                            }}
                            value={attendance_to_date}
                            variant="outlined"
                            onChange={(e) =>
                                String(e.target.value).length <= 10
                                    ? setAttendance_to_date(e.target.value as string)
                                    : ""
                            }
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleFilter}
                            startIcon={<SearchIcon />}
                            disabled={manageAttendanceLoading}
                            style={{
                                backgroundColor: "#1976d2",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#1565c0";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#1976d2";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            startIcon={<RotateLeftIcon />}
                            style={{
                                backgroundColor: "#9c27b0",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#7b1fa2";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#9c27b0";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                            onClick={() =>
                                history.push("/dashboard/payrollManager/manage-attendance")
                            }
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <LoadingButton
                            variant="contained"
                            style={{
                                backgroundColor:
                                    !FilterBtn || attendanceDataWithourPageloading || disableFilterBtn
                                        ? "gray"
                                        : "#009688",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "12px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                if (!attendanceDataWithourPageloading) {
                                    e.currentTarget.style.backgroundColor = "#7b1fa2";
                                    e.currentTarget.style.boxShadow =
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }
                            }}
                            onMouseOut={(e) => {
                                if (!attendanceDataWithourPageloading) {
                                    e.currentTarget.style.backgroundColor =
                                        !FilterBtn || attendanceDataWithourPageloading
                                            ? "gray"
                                            : "#009688";
                                    e.currentTarget.style.boxShadow = "none";
                                }
                            }}
                            startIcon={
                                attendanceDataWithourPageloading ? null : employeeDept ===
                                    64 ? (
                                    <MarkEmailReadIcon />
                                ) : (
                                    <ArrowCircleDownIcon />
                                )
                            }
                            fullWidth
                            onClick={employeeDept !== 64 ? exportAttendanceReportData : handleSendEmail}
                            disabled={!FilterBtn || attendanceDataWithourPageloading || disableFilterBtn}
                            loading={attendanceDataWithourPageloading}
                            loadingPosition="start"
                        >
                            {attendanceDataWithourPageloading ? (
                                <span>
                                    Preparing data... <br />
                                    <small>Please wait, we are preparing data for you.</small>
                                </span>
                            ) : employeeDept === 64 ? (
                                "Send Email"
                            ) : (
                                "Download Attendance"
                            )}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            startIcon={<AttractionsIcon />}
                            style={{
                                backgroundColor: "#006064",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#795548";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#006064";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                            onClick={() => setOpenAttendence(true)}
                        >
                            ATTENDENCE UPDATE
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            startIcon={<EventBusyIcon />}
                            style={{
                                backgroundColor: "#795548",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#00bcd4";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#795548";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                            onClick={() => setModelLockDate(true)}
                        >
                            LOCK ATTENDANCE DATE
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>

                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "rgb(72 50 136)",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                fontFamily: "Arial, sans-serif",
                                fontWeight: "bold",
                                boxShadow: "0 4px 8px rgba(0, 128, 128, 0.2)",
                                transition: "background-color 0.3s ease, transform 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                                const target = e.target as HTMLElement;
                                target.style.backgroundColor = "#bcaaa4";
                                target.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                                const target = e.target as HTMLElement;
                                target.style.backgroundColor = "#795548";
                                target.style.transform = "scale(1)";
                            }}
                            onClick={() => handlRefreshAttendance()}
                        >
                            Refresh Attendance
                        </Button>
                    </Grid>
                </Grid>

                <div
                    style={{
                        padding: "12px",
                        marginTop: "12px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                        backgroundColor: "#e3f2fd",
                        display: "inline-block",
                        fontSize: "15px",
                        color: "#333",
                    }}
                >
                    <span style={{ color: "#c62828" }}>
                        📅 First of all click on <strong>Filter button</strong> to enable
                        the <strong>Download Attendance </strong> button.
                    </span>
                    <br />
                </div>

                {/* {openForm && (
          <div> */}
                <TableContainer
                    className={classes.tableContainer}
                >
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Employee Name</StyledTableCell>
                                <StyledTableCell align="center">Employee ID</StyledTableCell>
                                <StyledTableCell align="center">Date</StyledTableCell>
                                <StyledTableCell align="center">Clock-In</StyledTableCell>
                                <StyledTableCell align="center">Clock-Out</StyledTableCell>
                                <StyledTableCell align="center">
                                    Is Regularize
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Total Working Hours
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Punch In Location
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Punch Out Location
                                </StyledTableCell>
                                <StyledTableCell align="center">Work Location</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {manageAttendanceLoading ? (
                            <Loader />
                        ) : (
                            <TableBody>
                                {manageAttendance &&
                                    manageAttendance.results &&
                                    manageAttendance.count > 0 &&
                                    manageAttendance.results.map((data: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    {data?.emploayee_code || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.emploayee_name || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.date
                                                        ? moment(data?.date).format("DD-MM-YYYY")
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.check_in && data?.check_in
                                                        ? updatedTime(data?.check_in)
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.check_out && data?.check_out
                                                        ? updatedTime(data?.check_out)
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.is_regularize ? data?.is_regularize : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.total_hour !== null ? data.total_hour : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.punch_in_location !== null
                                                        ? data.punch_in_location
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.punch_out_location !== null
                                                        ? data.punch_out_location
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.work_location !== null
                                                        ? data.work_location
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.status || "NA"}
                                                    {data.status !== "P" && (
                                                        <AddCircleIcon
                                                            onClick={() => handleManageAttendance(data)}
                                                            style={{ marginLeft: "8px", color: "#007BFF" }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        )}
                        <TableFooter>
                            <StyledTableRow style={{ textAlign: "center" }}>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={manageAttendance?.count || 0}
                                    rowsPerPage={manageAttendance?.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <CustomDrawer
                    drawerTitle={"REGULARIZATION REQUEST"}
                    open={openManageAttendanceModel}
                    toggleDrawer={() => setOpenManageAttendanceModel(false)}
                >
                    <Box>
                        <Card className="p-3 m-0">
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                                justify="space-between"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Location</p>
                                    <Select
                                        className="input"
                                        name="action"
                                        variant="outlined"
                                        value={regularizationCategory}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                            setRegularizationCategory(e.target.value as string)
                                        }
                                    >
                                        <MenuItem value={"work_from_home"}>Work From Home</MenuItem>
                                        <MenuItem value={"work_from_office"}>
                                            Work From Office
                                        </MenuItem>
                                        <MenuItem value={"on_duty"}>On Duty</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}> Date</p>
                                    <input
                                        className="input"
                                        name="fromDate"
                                        type="date"
                                        value={fromDate || ""}
                                        min={june21st}
                                        max={formattedYesterday}
                                        onChange={(e) => setFromDate(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Punch-In Time </p>

                                    <input
                                        className="input"
                                        name="fromTime"
                                        type="time"
                                        value={fromTime ? fromTime : ""}
                                        onChange={(e) => setFromTime(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Punch-Out Time </p>
                                    <input
                                        className="input"
                                        name="toTime"
                                        type="time"
                                        value={toTime ? toTime : ""}
                                        onChange={(e) => setToTime(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <label
                                        style={{
                                            display: "block",
                                            fontWeight: "bold",
                                            marginBottom: "8px",
                                            fontSize: "16px",
                                            color: "#333",
                                        }}
                                    >
                                        Add Reason
                                        <textarea
                                            className="tex"
                                            value={reason}
                                            onChange={handleReason}
                                            style={{
                                                width: "100%",
                                                height: "100px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "vertical",
                                                outline: "none",
                                                transition: "border-color 0.3s",
                                            }}
                                            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
                                            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                                        />
                                    </label>
                                </Grid>
                                <button
                                    style={{
                                        backgroundColor:
                                            fromDate === "" ||
                                                fromTime === "" ||
                                                toTime === "" ||
                                                reason === ""
                                                ? "gray"
                                                : "rgb(14, 63, 108)",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "4px",
                                        padding: "12px 24px",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s, transform 0.3s",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        textTransform: "uppercase",
                                        letterSpacing: "1px",
                                    }}
                                    className="but"
                                    onClick={handleSubmit}
                                    disabled={
                                        fromDate === "" ||
                                        fromTime === "" ||
                                        toTime === "" ||
                                        reason === ""
                                    }
                                    onMouseEnter={(e) => {
                                        const target = e.target as HTMLButtonElement;
                                        target.style.backgroundColor = "rgb(19, 90, 150)";
                                        target.style.transform = "scale(1.05)";
                                    }}
                                    onMouseLeave={(e) => {
                                        const target = e.target as HTMLButtonElement;
                                        target.style.backgroundColor = "rgb(14, 63, 108)";
                                        target.style.transform = "scale(1)";
                                    }}
                                >
                                    Apply
                                </button>
                            </Grid>
                        </Card>
                    </Box>
                </CustomDrawer>
            </div>
            <CustomDrawer
                open={openAttendence}
                toggleDrawer={() => setOpenAttendence(false)}
                drawerTitle={"Bulk Regularize"}
            >
                {/* Conditionally render the existing attendance data */}

                {attendenceData?.length > 0 && (
                    <div className="px-3">
                        {attendenceData.map((data, index) => (
                            <Card
                                key={index}
                                className="d-flex p-2 my-2 shadow border border-1 border-success align-items-center justify-content-between"
                            >
                                <div className="d-flex align-items-start  justify-content-center">
                                    <span className="p-0 m-0 text-secondary">
                                        <AccountBalanceWalletOutlinedIcon />
                                    </span>
                                    <div className="mx-4 p-0">
                                        <p className="p-0 m-0 ">
                                            <span
                                                className="text-dark  fs-2"
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {data.from_date} -
                                            </span>

                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {"5:00 AM"}
                                            </span>
                                        </p>
                                        <p className="p-0 m-0 ">
                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {data.check_in} -
                                            </span>
                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {" " + data.check_out}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <IconButton onClick={() => handleRemoveAttendence(data?.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Card>
                        ))}
                    </div>
                )}

                {/* Conditionally render the form to add new attendance */}
                {(addAttendence || attendenceData?.length === 0) && (
                    <UpdateAttendence
                        formData={(data: any) =>
                            setAttendenceData((prev) => [...prev, data])
                        }
                        handletoggle={() => setOpenAttendence(false)}
                        handleAddAttendence={() => setAddAttendence(false)}
                    />
                )}

                {/* Render additional UI elements if there is existing attendance data */}
                {attendenceData?.length > 0 && (
                    <>
                        {!addAttendence && (
                            <Box
                                onClick={() => setAddAttendence(true)}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "2px dotted #007bff",
                                    borderRadius: "8px",
                                    width: "90%",
                                    marginX: "auto",
                                    height: "100px",
                                    cursor: "pointer",
                                    position: "relative",
                                    marginTop: "1rem",
                                }}
                            >
                                <IconButton onClick={() => setAddAttendence(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        )}

                        <div className="col-5 my-4">
                            <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                type="submit"
                                disabled={addAttendence}
                                endIcon={requestLoader && <CircularProgress size="small" />}
                                onClick={() => handleSendRequest()}

                            // Assuming onSubmit function is handled elsewhere
                            >
                                Send Request
                            </Button>
                        </div>
                    </>
                )}
            </CustomDrawer>
            {modelLockDate && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={modelLockDate}
                    onClose={() => setModelLockDate(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modelLockDate}>
                        <div className={classes.paper1}>
                            <h3
                                style={{
                                    color: "#0e3f6c",
                                    fontSize: "24px",
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                    letterSpacing: "0.5px",
                                    marginBottom: "20px",
                                    textAlign: "center",
                                    borderBottom: "2px solid #0e3f6c",
                                    paddingBottom: "10px",
                                    width: "fit-content",
                                    margin: "0 auto",
                                }}
                            >
                                Configure Attendance Date Lock
                            </h3>
                            <h6
                                style={{
                                    fontWeight: "bold",
                                    color: "#333",
                                    padding: "10px",
                                    margin: "0 0 1rem 0",
                                }}
                            >
                                Create a Date to Lock the Attendance
                            </h6>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                alignItems="flex-start"
                                style={{ alignItems: "left", marginTop: "1.5rem" }}
                            >
                                <Grid item xs={12} sm={3} md={3}>
                                    <TextField
                                        className="input"
                                        name="start_date"
                                        id="noTyping"
                                        type="date"
                                        label="Start Date"
                                        value={lock_Attendace_Start_date}
                                        variant="outlined"
                                        inputProps={{
                                            max: moment().format("DD-MM-YYYY"),
                                            min: moment().format("DD-MM-YYYY"),
                                        }}
                                        onChange={(e) =>
                                            String(e.target.value).length <= 10
                                                ? setLockAttendaceStartDate(e.target.value as string)
                                                : ""
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <TextField
                                        className="input"
                                        name="end_date"
                                        id="noTyping"
                                        type="date"
                                        label="End Date"
                                        value={lockAttendaceEndDate}
                                        variant="outlined"
                                        onChange={(e) =>
                                            String(e.target.value).length <= 10
                                                ? setLockAttendaceEndDate(e.target.value as string)
                                                : ""
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button
                                        variant="contained"
                                        startIcon={<SyncAltIcon />}
                                        fullWidth
                                        color="secondary"
                                        style={{
                                            borderRadius: "8px",
                                            padding: "8px 16px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            textTransform: "none",
                                            transition: "background-color 0.3s, box-shadow 0.3s",
                                            boxShadow: "none",
                                        }}
                                        onClick={handleCreateLockDate}
                                        disabled={
                                            lock_Attendace_Start_date === "" ||
                                            lockAttendaceEndDate === "" ||
                                            (lockDetails?.results?.length > 0 &&
                                                lock_Attendace_Start_date !== "" &&
                                                lockAttendaceEndDate !== "")
                                        }
                                    >
                                        Lock Date
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sm={6} md={2}>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#0e3f6c" }}
                                        fullWidth
                                        onClick={() => {
                                            setLockAttendaceEndDate("");
                                            setLockAttendaceStartDate("");
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                            <h6
                                style={{
                                    fontWeight: "bold",
                                    color: "#333",
                                    padding: "10px",
                                    margin: "0 0 1rem 0",
                                    borderBottom: "1px solid #333",
                                }}
                            >
                                {" "}
                                List Of Lock Date
                            </h6>
                            <TableContainer
                                className={classes.tableContainer}
                                style={{ maxHeight: "560px" }}
                            >
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                Created At
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                Start Date
                                            </StyledTableCell>
                                            <StyledTableCell align="center">End Date</StyledTableCell>
                                            <StyledTableCell align="center">Status</StyledTableCell>
                                            <StyledTableCell align="center">Edit</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>

                                    <TableBody>
                                        {lockDetails?.results &&
                                            lockDetails.results?.map((data: any, index: any) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="center">
                                                            {data?.created_at
                                                                ? moment(data?.created_at).format("DD-MM-YYYY")
                                                                : "NA"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {moment(data?.start_date).format("DD-MM-YYYY") ||
                                                                "NA"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {moment(data?.end_date).format("DD-MM-YYYY") ||
                                                                "NA"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {editPackage?.id === data?.id ? (
                                                                <Switch
                                                                    defaultChecked={data?.is_active}
                                                                    checked={isActive}
                                                                    onChange={(e) =>
                                                                        setIsActive(e.target.checked)
                                                                    }
                                                                    name="checkedA"
                                                                    color="primary"
                                                                    inputProps={{
                                                                        "aria-label": "secondary checkbox",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <DefaultSwitch
                                                                    checked={data?.is_active}
                                                                    name="checkedA"
                                                                    inputProps={{
                                                                        "aria-label": "secondary checkbox",
                                                                    }}
                                                                    disableRipple
                                                                />
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {editPackage?.id === data?.id ? (
                                                                <Button
                                                                    color="primary"
                                                                    variant="text"
                                                                    onClick={() => handleUser(data)}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    color="primary"
                                                                    variant="text"
                                                                    onClick={() => handleEditPackage(data)}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>

                                    <TableFooter>
                                        <StyledTableRow style={{ textAlign: "center" }}>
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                colSpan={5}
                                                count={lockDetails?.count || 0}
                                                rowsPerPage={lockDetails?.page_size}
                                                page={page}
                                                onPageChange={handleChangePage}
                                            />
                                        </StyledTableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </Fade>
                </Modal>
            )}
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    manageAttendance: state.loginReducer.manageAttendance,
    manageAttendanceLoading: state.loginReducer.manageAttendanceLoading,
    attendanceDataWithourPage: state.loginReducer.attendanceDataWithourPage,
    attendanceDataWithourPageloading:
        state.loginReducer.attendanceDataWithourPageloading,
    employeesList: state.loginReducer.employeesList,
    departmentNames: state.loginReducer.departmentNames,
    lockDetails: state.loginReducer.lockDetails,
});
export default connect(mapStateToProps, {
    getManageAttendance,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeDepartment,
    getManageAttendanceWithOutPagination,
    CreateLockAction,
    getUsersLockAction,
    UpdateUsersLockAction,
    getUpdateBiometric

})(Forms);
