// import { birthdayReminder } from "../../actions/PayrollActions";

export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_LOADING: "hrms/set_loading",
  DISPLAY_HOLIDAYS: "national-holidays-list/",
  LEAVE_APPLICATION: "hrms/LEAVE_APPLICATION",
  ATTENDANCE_MASTER: "hrms/attendance-master",
  REGULARIZATION: "hrms/REGULARIZATION",
  BIRTHDAY_REMINDER: "hrms/BIRTHDAY_REMINDER",
  EMPLOYEE_SALARY: "hrms/employeeSalary",
  GET_EMPLOYEE_SALARY: "hrms/getEmployeeSalry",
  GET_LEAVE_APPLICATION: 'hrms/leaveApplication',
  GET_PENDING_REGULARIZATION: 'hrms/pendingRegularization',
  LEAVE_APPLICATION_REVIEW: 'hrms/LeaveReview',
  GET_EMPLOYEE_DATA: "hrms/employye_data",
  CREATE_LEAVE_REQUESTS: "hrms/create_leave_requests",
  GET_LEAVE_REQUESTS: "hrms/get_leave_requests",
  UPDATE_REGULARIZE: "hrms/upadte_regularize",
  POST_UPDATE_IMAGE: "post-upload-image",
  GET_PROFILE_IMAGE: "get-profile-image",
  GET_LOADING_REGULARIZATION:"loader-pending-regularization",
  ADD_EMPLOYMENT_DETAILS:"hrms/add-employment-details",
  EDIT_EMPLOYMENT_DETAILS:"hrms/edit-employment-details",
  GET_EMPLOYMENT_DETAILS:"hrms/get-employment-details",
  DELETE_EMPLOYMENT_DETAIL:"hrms/delete-employment-detail",
  ADD_CERTIFICATE:"hrms/add-certificate",
  EDIT_CERTIFICATE:"hrms/edit-certificate",
  GET_CERTIFICATE:"hrms/get-certificate",
  DELETE_CERTIFICATE:"hrms/delete-certificate",
  GET_MEDICAL_INFO:"hrms/medical-details",
  ADD_MEDICAL_INFO:"hrms/add-medical-info",
  EDIT_MEDICAL_INFO:"hrms/edit-medical-info",
  ADD_EDUCATION:"hrms/add-education",
  EDIT_EDUCATION:"hrms/edit-education",
  GET_EDUCATION:"hrms/get-education",
  DELETE_EDUCATION:"hrms/delete-education",
  GET_EMPLOYEE_FILTER_LIST:"hrms/get-employee-filter-list",
  UPDATE_REGULARIZATION_COUNT:"hrms/update-regularization-count",
};
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: Array<string | number>
}

interface DisplayHolidays {
  type: typeof ACTIONS.DISPLAY_HOLIDAYS;
  payload: Array<any>

}

interface LeaveApplication {
  type: typeof ACTIONS.LEAVE_APPLICATION
  payload: Array<any>;
}
interface Regularization {
  type: typeof ACTIONS.REGULARIZATION
  payload: Array<any>;
}

interface BirthdayReminder {
  type: typeof ACTIONS.BIRTHDAY_REMINDER
  payload: Array<any>;
}
interface AttendanceMaster {
  type: typeof ACTIONS.ATTENDANCE_MASTER
  payload: Array<any>;
}


interface EmployeeSalary {
  type: typeof ACTIONS.EMPLOYEE_SALARY
  payload: Array<any>;
}


interface GetEmployeeSalary {
  type: typeof ACTIONS.GET_EMPLOYEE_SALARY
  payload: Array<any>;
}
interface GetLeaveApplication {
  type: typeof ACTIONS.GET_LEAVE_APPLICATION
  payload: Array<any>;
}
interface GetPendingRegularization {
  type: typeof ACTIONS.GET_PENDING_REGULARIZATION
  payload: Array<any>;
}

interface GetLoadingRegularization {
  type: typeof ACTIONS.GET_LOADING_REGULARIZATION
  payload: boolean;
}




interface leaveApplicationReview {
  type: typeof ACTIONS.LEAVE_APPLICATION_REVIEW
  payload: Array<any>;
}

interface GetEmployeeData {
  type: typeof ACTIONS.GET_EMPLOYEE_DATA
  payload: Array<any>;
}

interface CreateLeaveRequests {
  type: typeof ACTIONS.CREATE_LEAVE_REQUESTS
  payload: Array<any>;
}

interface GetLeaveRequests {
  type: typeof ACTIONS.GET_LEAVE_REQUESTS
  payload: Array<any>;
}
interface updateReg {
  type: typeof ACTIONS.UPDATE_REGULARIZE
  payload: Array<any>;
}
interface PostUpdateImage {
  type: typeof ACTIONS.POST_UPDATE_IMAGE
  payload: Array<any>;
}

interface GetProfileImage {
  type: typeof ACTIONS.GET_PROFILE_IMAGE
  payload: Array<any>;
}
interface addEmploymentDetails {
  type: typeof ACTIONS.ADD_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface editEmploymentDetails {
  type: typeof ACTIONS.EDIT_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface getEmploymentDetails {
  type: typeof ACTIONS.GET_EMPLOYMENT_DETAILS;
  payload: Array<any>;
}
interface deleteEmploymentDetail {
  type: typeof ACTIONS.DELETE_EMPLOYMENT_DETAIL;
  payload: boolean;
}

interface addCertificate {
  type: typeof ACTIONS.ADD_CERTIFICATE;
  payload: Object;
}
interface editCertificate {
  type: typeof ACTIONS.EDIT_CERTIFICATE;
  payload: Object;
}
interface getCertificate {
  type: typeof ACTIONS.GET_CERTIFICATE;
  payload: Array<any>;
}
interface deleteCertificate {
  type: typeof ACTIONS.DELETE_CERTIFICATE;
  payload: boolean;
}
interface GetMedicalData {
  type: typeof ACTIONS.GET_MEDICAL_INFO;
  payload: Array<any>;
}
interface AddMedicalInfo {
  type: typeof ACTIONS.ADD_MEDICAL_INFO;
  payload: object;
}
interface EditMedicalInfo {
  type: typeof ACTIONS.EDIT_MEDICAL_INFO;
  payload: object;
}

interface AddEducation {
  type: typeof ACTIONS.ADD_EDUCATION;
  payload: Object;
}
interface EditEducation {
  type: typeof ACTIONS.EDIT_EDUCATION;
  payload: Object;
}
interface getEducationDetails {
  type: typeof ACTIONS.GET_EDUCATION;
  payload: Array<any>;
}
interface deleteEducation {
  type: typeof ACTIONS.DELETE_EDUCATION;
  payload: boolean;
}
interface getemployeFilterList {
  type: typeof ACTIONS.GET_EMPLOYEE_FILTER_LIST;
  payload: Array<any>;
}
interface updateRegularizationCount {
  type: typeof ACTIONS.UPDATE_REGULARIZATION_COUNT;
  payload: Object;
}
export type HRMSTypes =
  | ChangePassword
  | ChangePassword
  | SetLoadng
  | DisplayHolidays
  | LeaveApplication
  | Regularization
  | AttendanceMaster
  | BirthdayReminder
  | EmployeeSalary
  | GetEmployeeSalary
  | GetLeaveApplication
  | GetPendingRegularization
  | leaveApplicationReview
  | GetEmployeeData
  | CreateLeaveRequests
  | GetLeaveRequests
  | updateReg
  | PostUpdateImage
  | GetProfileImage
  | GetLoadingRegularization
  | addEmploymentDetails
  | editEmploymentDetails
  | getEmploymentDetails
  | deleteEmploymentDetail
  | addCertificate
  | getCertificate
  | editCertificate
  | deleteCertificate
  | GetMedicalData
  | AddMedicalInfo
  | EditMedicalInfo
  | AddEducation
  | getEducationDetails
  | EditEducation
  | deleteEducation
  | getemployeFilterList
  | updateRegularizationCount;
