import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { getMyTeamDetails } from "../../../actions/loginActions";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "190%",
      marginTop: "50px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      padding: "10px 20px",
      gap: "10px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },

    tableContainer: {
      position: "sticky",
      padding: "20px",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
      border: "1px solid #fff", // Add this line
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
      border: "1px solid #fff", // Add this line
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      border: "1px solid #fff", 
    },
  })
)(TableRow);

interface Props {
  getMyTeamDetails: any;
  team_details: any;

}

const WeeklyRoster: React.FC<Props> = ({ getMyTeamDetails, team_details}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [dates, setDates] = useState<any>([]);
 

  const getNext7Days = (selectedDate: Date) => {
    const Dates = [];
    const startDate = new Date(selectedDate);

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);
      Dates.push(
        nextDate.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
      );
    }
    return Dates;
  };



  const handleChange = (event: any) => {
    const selectedDate = event.target.value;
    const selectedDates = getNext7Days(selectedDate);
    setDates(selectedDates);
  };

  useEffect(() => {
    getMyTeamDetails();
  }, []);

  return (
    <>
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <div className="data-table">
            <h4
              style={{
                color: "rgb(14, 63, 108)",
                padding: "10px 20px",
              }}
            >
              Weekly Roster
            </h4>
          </div>

          <div>
            <input
              type="date"
              id="start"
              name="Date"
              onChange={handleChange}
              style={{
                padding: "5px 20px",
                border: "1px solid grey",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>

        <div style={{ width: "100%", display: "flex", borderRadius: "10px" }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                     
                      
                      <input
                       type="text"
                       style={{ width:"70%", padding:"5px", borderRadius:"10px", backgroundColor:"white"}}
                       placeholder="Search by Id or name"
                       onChange={(e) => {
                       clearTimeout(timer.current);
                       timer.current = setTimeout(() => {
                        getMyTeamDetails(`search=${e.target.value}`);
                       }, 1000);
                    }}
                  />
                    
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">{dates[0]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[1]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[2]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[3]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[4]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[5]}</StyledTableCell>
                  <StyledTableCell align="center">{dates[6]}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {team_details.map((empDetail: any, index: number) => (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <div>
                          <Avatar
                            // key={data.id}
                            // alt="User Avatar"
                            // src={
                            //   data.profile_picture
                            //     ? data.profile_picture
                            //     : "/static/images/avatar/1.jpg"
                            // }
                            style={{
                              backgroundColor: "rgb(14, 63, 108)",
                              cursor: "pointer",
                              border: "1px solid black",
                              height: "50px",
                              width: "50px",
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <p>
                            <b style={{ color: "blue" }}>Employee Name:</b>
                            <strong>{empDetail.fullname}</strong>
                            <br />
                            <Chip
                              label={empDetail.employee_id}
                              color="primary"
                              variant="outlined"
                            />
                            <Chip
                              label={empDetail.designation}
                              color="success"
                              variant="outlined"
                              style={{ marginLeft: "5px" }}
                            />
                          </p>
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                        <div style={{ display:"flex", flexDirection:"column", gap:"2px"}}>
                        {empDetail.shift
                        ? `${empDetail.shift} hours`
                        : empDetail.weekly_off_days.map((item: any) => (
                          
                             <Chip
                              label={item}
                              color="success"
                              variant="outlined"
                            />
                          

                          ))}
                        </div>
                      
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift2}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift3}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift4}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift5}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8",  }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift6}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift7}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  team_details: state.loginReducer.team_details,
});

export default connect(mapStateToProps, {
  getMyTeamDetails,
})(WeeklyRoster);
