import Auth from "../services/Auth";
import SecureStorage from "../config/SecureStorage";
import snackBarUpdate from "../actions/snackBarActions";
import { ACTIONS } from "../interfaces/actionTypes/loginTypes";
import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix";

export const login = (body: object) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data, status } = await Auth.login({ user: body });
    let authResponse: any = [];
    if (status === 200 || status === 201) {
      authResponse = {
        data,
        status,
      };
      const user = data.user.username;
      const passwordStatus = data.user.password_change;
      const token = data.user.token;
      SecureStorage.setItem("token", token);
      localStorage.setItem("global__token", token);
      localStorage.setItem("tokeninlocal", token);
      localStorage.setItem("password_change_status", data.user.password_change);
      SecureStorage.setItem("username", user);
      // dispatch({type: ACTIONS.GET_USER, payload: passwordStatus })
      dispatch({ type: ACTIONS.SET_USER, payload: user });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    }
    return authResponse;
  } catch (err: any) {
    let title = "";
    if (err.response.data.user.error) {
      title = String(err.response.data.user.error);
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const resetPasswordReset = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.RESET_PASSWORD, payload: false });
  dispatch({ type: ACTIONS.SEND_OTP, payload: false });
};

export const resetPassword = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/auth/password_reset/confirm/`,
      body
    );
    dispatch({ type: ACTIONS.RESET_PASSWORD, payload: data });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Invalid OTP!";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const panelUserLogout = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/api/auth/users/users/logout/`,
      {},
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.PANELUSER_LOGOUT, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const sendOtp = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/auth/password_reset/`,
      body
    );
    dispatch({ type: ACTIONS.SEND_OTP, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: false });
    throw err;
  }
};

export const logout = () => ({ type: ACTIONS.LOGOUT });

export const checkUser = () => async (dispatch: Function) => {
  try {
    const data = await Auth.checkLogin();
    let checkUserLoginResponse;

    if (Object.keys(data.user).length > 0) {
      checkUserLoginResponse = data;

      dispatch({ type: ACTIONS.SET_USER, payload: data });
    }
    return checkUserLoginResponse;
  } catch (error) {
    return error;
  }
};

export const setProfile = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await Auth.setProfile();
    dispatch({ type: ACTIONS.SET_PROFILE, payload: data });
  } catch (error) {}
};
export const getAttendancesList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendances/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ATTENDANCE_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const createAttendances = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/attendances/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_ATTENDANCE, payload: data });
    snackBarUpdate({
      payload: {
        message: "Attendances Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAttendanceById = (id: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/attendances/${id ? `${id}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ATTENDANCE_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const editAttendanceById =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/attendances/uuid/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.EDIT_ATTENDANCE_BY_ID,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Attendances Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const deleteAttendanceById =
  (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/attendances/uuid/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
          data: body,
        }
      );
      snackBarUpdate({
        payload: {
          message: "Module deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.DELETE_EMPLOYEE_ATTENDANCE_BY_ID,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const createEmployee = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/employees/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_EMPLOYEE, payload: data });
    snackBarUpdate({
      payload: {
        message: "Employee Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data) {
      title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getEmployeeList = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/employees/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_EMPLOYEE_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const editEmployeeById =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/employees/${id}/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.EDIT_EMPLOYEE_BY_ID,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employee Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const deleteEmployeeById = (body: any) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.delete(
      `${Prefix.api}/employees/uuid/`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        data: body,
      }
    );
    snackBarUpdate({
      payload: {
        message: "Module deleted successfully!",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.DELETE_EMPLOYEE_ACCOUNT_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title,
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getOrganizationsList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/organizations/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ORGANIZATION_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const createOrganizations =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/organizations/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.CREATE_ORGANIZATIONS, payload: data });
      snackBarUpdate({
        payload: {
          message: "organizations Created Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createPayrolls = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/payrolls/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_PAYROLLS, payload: data });
    snackBarUpdate({
      payload: {
        message: "Payrolls Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPayrollsList = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payrolls/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PAYROLLS_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getPayrollById = (id: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payrolls/uuid/${id ? `${id}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PAYROLL_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const editPayrollById =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/payrolls/uuid/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.EDIT_PAYROLL_BY_ID,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Payrolls Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const deletePayrollById = (body: any) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.delete(
      `${Prefix.api}/payrolls/uuid/`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        data: body,
      }
    );
    snackBarUpdate({
      payload: {
        message: "Module deleted successfully!",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.DELETE_PAYROLL_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title,
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const createEmployeeLeaveLedgers =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/employee-leave-ledgers/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_EMPLOYEE_LEAVE_LEADGERS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Employee Leave Ledgers Created Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getEmployeeLeaveLedgersList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employee-leave-ledgers/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_LIST,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getEmployeeLeaveLedgersById =
  (id: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employee-leave-ledgers/${id ? `${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_BY_ID,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const editEmployeeLeaveLedgersById =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/employee-leave-ledgers/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.EDIT_EMPLOYEE_LEAVE_LEADGERS_BY_ID,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employee Leave Ledgers Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const deleteEmployeeLeaveLedgersById =
  (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/employee-leave-ledgers/id/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
          data: body,
        }
      );
      snackBarUpdate({
        payload: {
          message: "Module deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.DELETE_EMPLOYEE_LEAVE_LEADGERS_BY_ID,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const createEmployeeLeaveMasters =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/employee-leave-masters/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_EMPLOYEE_LEAVE_MASTER, payload: data });
      snackBarUpdate({
        payload: {
          message: "Employee Leave Masters Created Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getEmployeeLeaveMastersList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employee-leave-masters/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getEmployeeLeaveMastersById =
  (id: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}//employee-leave-masters/${id ? `${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_BY_ID,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const editEmployeeLeaveMastersById =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}//employee-leave-masters/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.EDIT_EMPLOYEE_LEAVE_MASTER_BY_ID,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employee Leave Masters Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const deleteEmployeeLeaveMastersById =
  (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}//employee-leave-masters/id/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
          data: body,
        }
      );
      snackBarUpdate({
        payload: {
          message: "Module deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.DELETE_EMPLOYEE_LEAVE_MASTER_BY_ID,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getComponentsList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/components/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CONPONENTS_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getEmployeeComponentsList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employee-components/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_CONPONENTS_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const UpdateEmployeeComponents =
  (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/employee-components-update/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_EMPLOYEE_COMPONENTS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employee Component Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const editKraDetails = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`/employee-form/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.EDIT_KRA_DETAILS, payload: data });
    snackBarUpdate({
      payload: {
        message: "KRA details filled successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Something went wrong";
    } else if (err?.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const createLeaveApplication =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/leave_application`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_LEAVE_APPLOCATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Leave Created Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getEmployeeDepartment =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users/department/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_DEPARTMENT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const PostEmployeeSalary = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/employee-components-update/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.POST_EMPLOYEE_SALARY, payload: data });
    snackBarUpdate({
      payload: {
        message: "Salary Displayed Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data) {
      title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getMyTeamDetails = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/users/my-team/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_MY_TEAM_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const postDepartmentBulkUploader =
  (formData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/bulk_upload_department/csv/`,
        formData,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: ACTIONS.POST_DEPARTMENT_BULK_UPLOAD, payload: data });
      snackBarUpdate({
        payload: {
          message: "FILE UPLOADED SUCCESSFULLY !",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const postBulkUploaderEmployee =
  (formData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/bulk_upload_employee/csv/`,
        formData,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: ACTIONS.POST_EMPLOYEE_BULK_UPLOAD, payload: data });
      snackBarUpdate({
        payload: {
          message: "FILE UPLOADED SUCCESSFULLY !",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const postBulkUploaderPayslip =
  (formData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/bulk_upload_payslip/csv/`,
        formData,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: ACTIONS.POST_PAYSHLIP_BULK_UPLOAD, payload: data });
      snackBarUpdate({
        payload: {
          message: "FILE UPLOADED SUCCESSFULLY !",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAttendanceMaster =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendance-list/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ATTENDANCE_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.ATTENDANCE_MASTER, payload: err });
      throw err;
    }
  };

export const getBirthdayReminder = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/users/birthday-reminder/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.BIRTHDAY_REMINDER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.BIRTHDAY_REMINDER, payload: err });
    throw err;
  }
};

export const getDisplayHolidaysList = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/national-holidays-list/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: err });
    throw err;
  }
};
export const getLeaveComponents =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/leave-components/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAVE_COMPONENTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const postRegularizationRequest =
  (payload: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/regularization/`,
        payload,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_REGULARIZATION_REQUEST, payload: data });
      snackBarUpdate({
        payload: {
          message: "Attendace Regularize Successfull! ",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.errors) {
        title = JSON.stringify(err?.errors).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPayrollAggregate =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/payroll/aggregate/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PAYROLL_AGGREGATE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getPayrollData = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payrolls/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PAYROLL_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const leaveApplication = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/leave_application`, body);
    dispatch({ type: ACTIONS.LEAVE_APPLICATION, payload: data });
    snackBarUpdate({
      payload: {
        message: "Application sumbit  Successfully.",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Something went wrong";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const PostEmployeeRating = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/employee-form/rating/`,
      body
    );
    dispatch({ type: ACTIONS.EMPLOYEE_RATING, payload: data });
    snackBarUpdate({
      payload: {
        message: "Rating sumbit  Successfully.",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Something went wrong";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getEmployeeForm = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/employee-form/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_EMPLOYEE_FORM, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getEmployeeSalary = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/employee-components/?`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_EMPLOYEE_SALARY, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_EMPLOYEE_SALARY, payload: err });
    throw err;
  }
};

export const UpdateEmployeeSalary =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/employee-components-update/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_EMPLOYEE_SALARY,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Salary Updated!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const getEmployeeSubDept =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users/sub-department/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_SUB_DEPARTMENT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const sendOtp1 = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/users/send_otp/`, body);
    dispatch({ type: ACTIONS.SEND_OTP_NUMBER, payload: null });
    snackBarUpdate({
      payload: {
        message: "OTP has been sent to your mobile number",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const resetPassword1 = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/users/resetpass/`, body);
    dispatch({ type: ACTIONS.RESET_PASSWORD_NEW, payload: data });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Invalid OTP!";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getEmployeeStateName =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/states/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_STATE_NAME, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getShiftList = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/users/shift-list/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SHIFT_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getDesignationtList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/designation/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_DESIGNATION_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const getLocationList = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/location/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_LOCATION_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getattendancerecord =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendance-record/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ATTENDANCE_RECORD, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const getAttendanceMasterPro =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ATTENDANCE_MASTER_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendance-record/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ATTENDANCE_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ATTENDANCE_MASTER_LOADING, payload: false });
      throw err;
    }
  };

export const getManageAttendance = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_MANAGE_ATTENDANCE_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/attendance-list/${url ? url : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_MANAGE_ATTENDANCE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else {
      title = "Something went wrong!";
    }
    if (
      err?.response?.data?.detail ===
      "No user matching this token was found."
    ) {
      dispatch(panelUserLogout());
      dispatch(logout());
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_MANAGE_ATTENDANCE_LOADING, payload: false });
    throw err;
  }
};
export const PostAssignManage = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/assign-manager/`, body);
    dispatch({ type: ACTIONS.POST_ASSIGN_MANAGE, payload: data });
    snackBarUpdate({
      payload: {
        message: "Manage Assign Successfully.",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Something went wrong";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const PostPunchInOut = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/punch/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });

    dispatch({ type: ACTIONS.POST_PUNCH_IN_OUT, payload: data });
    snackBarUpdate({
      payload: {
        message: "Punch In successfully.",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.message) {
      title = JSON.stringify(err.response.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getEmployeeById = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/employees/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_EMPLOYEE_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const getEmployeeALlData =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employees/?get_all=true&${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_ALL_DATA, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const getLeaveLedger = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/employee-leave-ledgers/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_LEAVE_LEDGER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getEmployeeMode = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/users/mode/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_EMPLOYEE_MODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: err });
    throw err;
  }
};
export const updateEmployeeUploader =
  (formData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/bulk_update_employee/csv/`,
        formData,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_EMPLOYEE_BULK_UPLOAD, payload: data });
      snackBarUpdate({
        payload: {
          message: "FILE UPLOADED SUCCESSFULLY !",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getReportingManager =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employees/?${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_REPORTING_MANAGER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const getNotification =
  (body: any, id: number) => async (dispatch: Function) => {
    try {
      let putData: any;
      if (id) {
        putData = await AXIOS.put(
          `${Prefix.api}/employee-logs-list/${id}/`,
          body,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      } else {
        putData = await AXIOS.get(`${Prefix.api}/employee-logs-list/`, {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });

        const { data } = putData;
        dispatch({ type: ACTIONS.GET_NOTIFICATION, payload: data });
      }
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const bulkLeaveUploads =
  (formData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/bulk_upload_employee_leave/csv/`,
        formData,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: ACTIONS.BULK_LEAVE_UPLOADS, payload: data });
      snackBarUpdate({
        payload: {
          message: "LEAVE UPLOADED SUCCESSFULLY !",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getExportEmployeeList =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_EXPORT_EMPLOYEE_LIST_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employees/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EXPORT_EMPLOYEE_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_EXPORT_EMPLOYEE_LIST_LOADING, payload: false });
      throw err;
    }
  };

export const updateImage = (formData: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.put(
      `${Prefix.api}/users/profile-picture-add/${
        formData.id ? formData.id : ""
      }/`,
      formData.data,
      {
        headers: {
          Authorization: `Token ${SecureStorage.getItem("token")}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: ACTIONS.POST_UPDATE_IMAGE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err?.response?.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.message) {
      title = JSON.stringify(err?.response?.data?.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else if (err?.response?.data?.error) {
      title = JSON.stringify(err?.response?.data?.error).replace(
        /"|{|}|[|]/g,
        ""
      );
    }
    else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getUserProfileImage =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users/profile-picture-add/${url ? `${url}/` : ""}`,
        {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")} `,
          },
        }
      );
      dispatch({ type: ACTIONS.GET_PROFILE_IMAGE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
export const PostBulkRegularization =
  (body: any) => async (dispatch: Function) => {
    try {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      const { data } = await AXIOS.post(
        `${Prefix.api}/regularization/bulk/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_BULK_REGULARIZATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Attendace Regularize Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getManageAttendanceWithOutPagination = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendance-list/${url ? url : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION_LOADING, payload: false });
      throw err;
    }
  };
  export const UpdateEmployeeWorkLocation =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/update/employee-work-location/${id}/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.UPDATE_EMPLOYEE_WORK_LOCATION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Location Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const getGeoFenceCordinateList = (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/geo-fence-cordinate/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_GEO_FENCE_CORDINATE_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.message) {
        title = JSON.stringify(err.response.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const CreateGeoFenceCordinate = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/geo-fence-cordinate/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
  
      dispatch({ type: ACTIONS.CREATE_GEO_FENCE_CORINATE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Geo Fence Created successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.message) {
        title = JSON.stringify(err.response.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getUsersReport = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_USER_REPORT_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users-report/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_USER_REPORT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_USER_REPORT_LOADING, payload: false });
      throw err;
    }
  };

  
  export const getMedicalInfo =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/medical-details/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_MEDICAL_INFO, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const CreateLockAction = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/lock-action/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
  
      dispatch({ type: ACTIONS.CREATE_LOCK_ACTION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Given Date is Lock successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.message) {
        title = JSON.stringify(err.response.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getUsersLockAction = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lock-action/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_USER_LOCK_ACTION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const UpdateUsersLockAction =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/lock-action/${id}/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.UPDATE_USERS_LOCK_ACTION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Date Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const getCreateModule = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
   try {
     const { data } = await AXIOS.get(
       `${Prefix.api}/assignment-module-master/`,
       { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
     );
 
     dispatch({ type: ACTIONS.CREATE_MODULE, payload: data });
   } catch (err: any) {
     let title = "";
     if (err?.response?.data?.detail) {
       title = JSON.stringify(err.response.data?.detail).replace(
         /"|{|}|[|]/g,
         ""
       );
     } else if (err?.response?.data?.errors) {
       title = JSON.stringify(err.response.data?.errors).replace(
         /"|{|}|[|]/g,
         ""
       );
     } else if (err?.response?.data?.msg) {
       title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
     } else {
       title = "Something went wrong!";
     }
     snackBarUpdate({
       payload: {
         message: title,
         status: true,
         type: "error",
       },
     })(dispatch);
     throw err;
   }
 };

 export const getUsersReportExport = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_USER_REPORT_LOADING_EXPORT, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users-report/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_USER_REPORT_EXPORT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_USER_REPORT_LOADING_EXPORT, payload: false });
      throw err;
    }
  };
  export const getUsersRegularizationReport = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/regularization-users-report/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_USER_REGULARIZATION_REPORT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING, payload: false });
      throw err;
    }
  };
  export const getUsersRegularizationReportExport = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING_EXPORT, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/regularization-users-report/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_USER_REGULARIZATION_REPORT_EXPORT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING_EXPORT, payload: false });
      throw err;
    }
  };
  export const getEmployeeLeaveCount = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.EMPLOYEE_LEAVE_COUNT_LOADING, payload: "pending" });
   try {
     const { data } = await AXIOS.get(
       `${Prefix.api}/employee-leave-count/`,
       { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
     );
 
     dispatch({ type: ACTIONS.EMPLOYEE_LEAVE_COUNT, payload: data });
   } catch (err: any) {
     let title = "";
     if (err?.response?.data?.detail) {
       title = JSON.stringify(err.response.data?.detail).replace(
         /"|{|}|[|]/g,
         ""
       );
     } else if (err?.response?.data?.errors) {
       title = JSON.stringify(err.response.data?.errors).replace(
         /"|{|}|[|]/g,
         ""
       );
     } else if (err?.response?.data?.msg) {
       title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
     } else {
       title = "Something went wrong!";
     }
     snackBarUpdate({
       payload: {
         message: title,
         status: true,
         type: "error",
       },
     })(dispatch);
     throw err;
   }
 };

 export const getEmployeeLeaveCountExport = (url:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT_LOADING, payload: "pending" });
 try {
   const { data } = await AXIOS.get(
     `${Prefix.api}/employee-leave-count/?${url ? url : ""}`,
     { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
   );

   dispatch({ type: ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT, payload: data });
 } catch (err: any) {
   let title = "";
   if (err?.response?.data?.detail) {
     title = JSON.stringify(err.response.data?.detail).replace(
       /"|{|}|[|]/g,
       ""
     );
   } else if (err?.response?.data?.errors) {
     title = JSON.stringify(err.response.data?.errors).replace(
       /"|{|}|[|]/g,
       ""
     );
   } else if (err?.response?.data?.msg) {
     title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
   } else {
     title = "Something went wrong!";
   }
   snackBarUpdate({
     payload: {
       message: title,
       status: true,
       type: "error",
     },
   })(dispatch);
   throw err;
 }
};
export const getEmployeeMyTeamList =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employees/?my_team=true&${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_MY_TEAM_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      if (
        err?.response?.data?.detail ===
        "No user matching this token was found."
      ) {
        dispatch(panelUserLogout());
        dispatch(logout());
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getPerformanceTablenew = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/module-performance-report/${url ? url : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.PERFORMANCE_TABLE_NEW, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  
  export const postAssignmentAnswerMaster =
  (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/assignment-panel-user-answer-master/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

export const putAssignmentAnswerMaster =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/panel/assignment-panel-user-answer-master/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const postModulePerformanceData = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/module-performance-report/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_MODULE_PERFORMANCE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const resetPasswordByPayroll =
  (payload: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/users/reset-password/`,
        payload,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.RESET_PASSWORD_BY_PAYROLL, payload: data });
      snackBarUpdate({
        payload: {
          message: "Password Reset successfully! ",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.errors) {
        title = JSON.stringify(err?.errors).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const updateDesignation =
  ( formData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/users/users/designation/${id ? `${id}` : ""}`,
        formData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_DESIGNAION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const updateGeoLocation =
  (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/geofence-cordinates/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_GEO_LOCATION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Location Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const getUpdateBiometric = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/update-biometric/${url ? url : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_UPDATE_BIOMETRIC, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };