import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWindowSize } from "../useWindowSize";
import {  postAssignmentAnswerMaster,
  putAssignmentAnswerMaster,
  postModulePerformanceData } from "../../../../actions/loginActions";
import { getEmployeeData } from "../../../actions/EmployeeActions";
import { ACTIONS } from "../../../../interfaces/actionTypes/loginTypes";
import target from "./target.png"
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "1200px",
    overflowY: "auto",
  },
  option: {
    display: "block",
    border: "1px solid #616A94",
    borderRadius: "15px",
    padding: "15px 30px",
    color: "black",
    backgroundColor: "white",
    transition: "0.3s",
    fontSize: "1em",
    fontFamily: "serif",
    outline: "none",
    userSelect: "none",
    marginTop: "1em",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#5a6b90",
    },
  },
}));

const PPTModal = ({
  open,
  setOpen,
  myModule,
  postAssignmentAnswerMaster,
  assignmentAnswerMasterPostData,
   putAssignmentAnswerMaster,
   postModulePerformanceData,
   getEmployeeData,
   employeeData,
}) => {
  const classes = useStyles();
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [questionAttempted, setQuestionAttempted] = useState([]);
  const [windowWidth, windowHeight] = useWindowSize();
  const [youTubeId, setYouTubeId] = useState("");
  const [count, setCount] = useState(0);
  const playerRef = useRef(null);
  let dispatch = useDispatch();

  useEffect(()=>{
     getEmployeeData()
  },[])

  console.log(employeeData,"employeeData")

  useEffect(() => {
    if (questionAttempted?.length === currentQuestionIndex + 1 && myModule?.assignment_question_module?.[currentQuestionIndex]?.attempted !== null) {
      setQuestionAttempted([...questionAttempted, myModule?.assignment_question_module?.[currentQuestionIndex]?.attempted])
    } else if (assignmentAnswerMasterPostData?.length !== 0) {
      setQuestionAttempted([...questionAttempted, assignmentAnswerMasterPostData?.id])
      dispatch({ type: ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER, payload: [] });
    }
  }, [currentQuestionIndex, assignmentAnswerMasterPostData])

  const handleOptionClick = (selectedOption) => {
    let arrayForAnswer = [];
    let scoreValue = 0;
    arrayForAnswer.push(selectedOption.id);

    if (selectedOption?.assignment_user % 2 === 1) {
      scoreValue = 1;
      setTotalScore((prevCount) => prevCount +1)
    }

    const data = {
      answer: arrayForAnswer,
      question: selectedOption.question,
      score: scoreValue * ( 100 / myModule?.assignment_question_module?.length),
      user: employeeData && employeeData?.results[0]?.uuid,
    };

    if (questionAttempted?.length >= currentQuestionIndex + 1) {
      putAssignmentAnswerMaster(data, questionAttempted[currentQuestionIndex]);
      if(currentQuestionIndex < myModule.assignment_question_module.length - 1){
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }else{
        setShowScore(true)
      }
    } else {
      postAssignmentAnswerMaster(data);
      if(currentQuestionIndex < myModule.assignment_question_module.length - 1){
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }else{
        setShowScore(true)
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setQuizModalOpen(false);
    setCurrentQuestionIndex(0);
    setTotalScore(0);
    setShowScore(false);
  };

  const extractYouTubeID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)?([^"&?\/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  
  useEffect(() => {
    const videoUrl = myModule && myModule?.video_url;;
    const videoId = extractYouTubeID(videoUrl);
    setYouTubeId(videoId);
  }, [myModule]);

  
  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      setIsButtonEnabled(true);
    }
  };
  
  const initializePlayer = () => {
    if (!playerRef.current) {
      playerRef.current = new window.YT.Player("youtube-player", {
        height: windowWidth > 450 ? "490" : "390",
        width: "640",
        videoId: youTubeId,
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    }
  };
  
  const cleanupPlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
      playerRef.current = null;
    };
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    script.onload = () => {
      if (youTubeId) {
        window.onYouTubeIframeAPIReady = initializePlayer;
      }
    };
    document.body.appendChild(script);
  
   return () => {
      cleanupPlayer();
      document.body.removeChild(script);
    };
  }, [youTubeId, open]);

  useEffect(() => {
    if (open && youTubeId && window.YT && window.YT.Player) {
      initializePlayer()
    }
  
    return () => {
      cleanupPlayer();
    };
  }, [open, youTubeId]);

  useEffect(()=>{
    if(showScore){
      const data = {
        "module": myModule && myModule?.id,
        "watched_video": true,
        "final_score": totalScore * ( 100 / myModule?.assignment_question_module?.length).toFixed(2),
        "asignment_status": totalScore * ( 100 / myModule?.assignment_question_module?.length) >= myModule?.minimum_score ? "pass" : "fail",
        "user": employeeData && employeeData?.results[0]?.uuid,
      }

      postModulePerformanceData(data);
    }
  },[showScore])
  

  return (
    <div id="pdfShow">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <Fade in={open}>
          {!quizModalOpen ? (
            <div
              className={`${classes.paper} ${"scrollDesign"}`}
              style={{ height: windowWidth > 450 ? "90vh" : "70vh" }}
            >
              <div
                id="youtube-player"
                style={{
                  width: "100%",
                  background: "rgba(0,0,0,0.2)",
                  border: "1px solid grey",
                  borderRadius: "10px",
                }}
              ></div>
              {isButtonEnabled===false?
              (<div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "red",
                  marginTop: "5px",
                }}
              >
                Please watch the video first to complete the training program
              </div>)
              :
              (<div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "red",
                  marginTop: "5px",
                }}
              >
                You have successfully watched the video,please go for the assessment
              </div>)
              }

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    backgroundColor: isButtonEnabled ? "#c70e0ef7" : "#D3D3D3",
                    color: isButtonEnabled ? "white" : "black",
                    borderRadius: "8px",
                    width: "300px",
                    height: "40px",
                    margin: "25px 15px 0 0px",
                    border: "1px solid black",
                  }}
                  size="small"
                  disabled={!isButtonEnabled}
                  onClick={() => {
                    setQuizModalOpen(true);
                    if (playerRef.current) {
                      playerRef.current.destroy();
                      playerRef.current = null;
                    }
                  }}
                >
                  <p style={{ margin: "0", fontSize: "15px" }}>TAKE THE QUIZ</p>
                </Button>
              </div>
            </div>
          ) : (
            <div
              className={classes.paper}
              style={{
                backgroundColor: "#101427",
                height: windowWidth > 450 ? "90vh" : "80vh",
              }}
            >
              {showScore ? (
                <div
                  style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10%",
                  }}
                >
                  <h2 style={{ color:"white"}}>Thank you for completing the quiz!</h2>
                  <img src={target} alt="" width={150} height={150} />
                  <p>Passing Score: {myModule.minimum_score} %</p>
                  <p style={{ fontSize: "20px" }}>
                    Your score is {totalScore * ( 100 / myModule?.assignment_question_module?.length).toFixed(2)}% out of 100%
                  </p>
                  { (totalScore * ( 100 / myModule?.assignment_question_module?.length)) >= myModule?.minimum_score ? (
                     <div>
                     <p>
                      Congratulations! you have successfully passed the Assessment.
                     </p>
                
                   </div>
                  ) : (
                     <div>
                     {
                      count === 0 ? 
                      <div>
                        <p>
                      OOps, you didn't pass this time. please try again !!
                      </p>
                    <Button
                      variant="contained"
                      startIcon={<RefreshIcon />}
                      onClick={() => {
                        setShowScore(false);
                        setQuizModalOpen(true);
                        setCurrentQuestionIndex(0);
                        setTotalScore(0)
                        setCount(1);
                      }}
                    >
                      Try again
                    </Button></div> : <div>
                      <p>Thanks for the assessment,our team will contact you shortly for the training in brief</p>
                    </div>
                     }
                    
          
                   </div>
                  )}
                </div>
              ) : (
                <>
                  <div
                    id="header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 0",
                      borderBottom: "2px solid white",
                      color: "white",
                    }}
                  >
                    {currentQuestionIndex === 0 && (
                      <div style={{ color: "white" }}>
                        <Button
                          variant="contained"
                          startIcon={<ArrowBackIcon />}
                          size="small"
                          onClick={() => {
                            setQuizModalOpen(false);
                            setTimeout(initializePlayer, 100);
                          }}
                        >
                          Watch again
                        </Button>
                      </div>
                    )}
                    <div>{myModule.name}</div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {currentQuestionIndex + 1} of {" "}
                        {myModule.assignment_question_module.length}
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "10%" }}>
                    <div
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "30px",
                        fontFamily: "serif",
                      }}
                    >
                      {
                        myModule.assignment_question_module[
                          currentQuestionIndex
                        ].name
                      }
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {myModule.assignment_question_module[
                        currentQuestionIndex
                      ].assignment_answer_question?.map((option, index) => (
                        <div
                          key={index}
                          className={classes.option}
                          style={{ width: windowWidth > 450 ? "40%" : "85%" }}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assignmentAnswerMasterPostData: state.loginReducer.assignmentAnswerMasterPostData,
  employeeData: state.EmployeeReducer.employeeData,
});

export default connect(mapStateToProps, {
  postAssignmentAnswerMaster,
  putAssignmentAnswerMaster,
  postModulePerformanceData,
  getEmployeeData,
})(PPTModal);