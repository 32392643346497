import {
  ACTIONS,
  HRMSTypes,
} from "../interfaces/actionTypes/Payroll";

type InitialState = {
  cities: Array<any>;
  holidayList: any;
  leave_application: any[];
  attendanceData: any;
  birthday_reminder: any[];
  employee_salary: any[];
  requestCenterLeave: any[];
  pendingreularization: any[];
  pendingRegularizationLoader:boolean;
  leavereivew: any[];
  employeeData: any[];
  leaveRequests: any[];
  loading: boolean;
  postProfileImage: any[],
  getProfileImage: any[],
  performanceTableData: Array<any>,
  employmentDetailsList:any[];
  certificateList:any[];
  medicalData:Array<any>;
  educationList:any[];
  employeesFilterList: Array<any>;
};

const initialState: InitialState = {
  cities: [],
  holidayList: null,
  leave_application: [],
  attendanceData: [],
  birthday_reminder: [],
  employee_salary: [],
  requestCenterLeave: [],
  pendingreularization: [],
  pendingRegularizationLoader:false,
  leavereivew: [],
  employeeData: [],
  leaveRequests: [],
  loading: false,
  postProfileImage: [],
  getProfileImage: [],
  performanceTableData:[],
  employmentDetailsList:[],
  certificateList:[],
  medicalData:[],
  educationList:[],
  employeesFilterList:[],
};


const PayrollReducer = (
  state = initialState,
  action: HRMSTypes
) => {
  switch (action.type) {
    case ACTIONS.DISPLAY_HOLIDAYS:
      return {
        ...state,
        holidayList: action.payload
      }

    case ACTIONS.LEAVE_APPLICATION:
      return {
        ...state,
        leave_application: action.payload
      }
    case ACTIONS.REGULARIZATION:
      return {
        ...state,
        regularization: action.payload
      }
    case ACTIONS.ATTENDANCE_MASTER:
      return {
        ...state,
        attendanceData: action.payload
      }
    case ACTIONS.REGULARIZATION:
      return {
        ...state,
        regularization: action.payload
      }

    case ACTIONS.BIRTHDAY_REMINDER:
      return {
        ...state,
        birthday_reminder: action.payload
      }

    case ACTIONS.GET_LEAVE_APPLICATION:
      return {
        ...state,
        requestCenterLeave: action.payload
      }

    case ACTIONS.GET_LOADING_REGULARIZATION:
        return {
          ...state,
          pendingRegularizationLoader: action.payload,
        }

    case ACTIONS.GET_PENDING_REGULARIZATION:
      return {
        ...state,
        pendingreularization: action.payload,
        pendingRegularizationLoader: false
      }

    case ACTIONS.EMPLOYEE_SALARY:
      return {
        ...state,
        employee_salary: action.payload
      }
    case ACTIONS.LEAVE_APPLICATION_REVIEW:
      return {
        ...state,
        leavereivew: action.payload
      }


    case ACTIONS.LEAVE_APPLICATION_REVIEW:
      return {
        ...state,
        leavereivew: action.payload
      }
    case ACTIONS.GET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload
      }
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ACTIONS.GET_LEAVE_REQUESTS:
      return {
        ...state,
        leaveRequests: action.payload,
        loading: false,
      }
    case ACTIONS.GET_PROFILE_IMAGE:
      return {
        ...state,
        getProfileImage: action.payload,
        loading: false,
      }
    case ACTIONS.POST_UPDATE_IMAGE:
      return {
        ...state,
        postProfileImage: action.payload,
        loading: false,
      }
    case ACTIONS.GET_EMPLOYMENT_DETAILS:
      return{
        ...state,
        employmentDetailsList:action.payload,
        loading:false,
      }
    case ACTIONS.GET_CERTIFICATE:
      return{
        ...state,
        certificateList:action.payload,
        loading:false,
      }
    case ACTIONS.GET_MEDICAL_INFO:
      return{
        ...state,
        medicalData:action.payload,
        loading:false,
      };
    case ACTIONS.GET_EDUCATION:
      return{
        ...state,
        educationList:action.payload,
        loading:false,
      }
    case ACTIONS.GET_EMPLOYEE_FILTER_LIST:
      return {
        ...state,
        employeesFilterList: action.payload,
      };
    default:
      return state;
  }
};

export default PayrollReducer;
