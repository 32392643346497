import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  IconButton,
  TextField,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { postCreateModule, updateModule } from "../../actions/EmployeeActions";
import { getCreateModule } from "../../../actions/loginActions";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    height: "90vh",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

const Input = styled("input")({
  display: "none",
});

type ModalProps = {
  open: boolean;
  setOpen: Function;
  postCreateModule: any;
  getCreateModule: any;
  createModuleData: any;
  modulePercentage: any;
  editButton: any;
  moduleNames: any;
  moduleTimeMinutess: any;
  moduleTimeHourss: any;
  inputListOfQuestionss: any;
  videoUrl: any;
  updateModule: any;
  moduleIds: any;
  moduleQuestion: any;
};

const LearningModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  postCreateModule,
  getCreateModule,
  createModuleData,
  modulePercentage,
  editButton,
  moduleNames,
  moduleTimeMinutess,
  moduleTimeHourss,
  videoUrl,
  updateModule,
  moduleIds,
  moduleQuestion,
}) => {
  const classes = useStyles();
  const [disposition, setDisposition] = useState<string>("none");
  const [moduleName, setModuleName] = useState<String>("");
  const [videoLink, setVideoLink] = useState<any>("");
  const [moduleCategory, setModuleCategory] = useState<String>("employee");
  const [modulePassPercentage, setModulePassPercentage] = useState<any>(0);
  const [moduleTimeMinutes, setModuleTimeMinutes] = useState<any>(0);
  const [moduleTimeHours, setModuleTimeHours] = useState<any>(0);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [allOptionsOkay, setAllOptionsOkay] = useState<any>([false]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>("");
  const [inputListOfQuestions, setInputListOfQuestions] = useState<any>([
    {
      questionName: "",
      questionActive: true,
      option0: "",
      option0Check: false,
      option1: "",
      option1Check: false,
      option2: "",
      option2Check: false,
      option3: "",
      option3Check: false,
    },
  ]);
  console.log(inputListOfQuestions, "inputListOfQuestions");
  const handleClose = () => {
    setOpen(false)
    setDisposition("");
  };

  useEffect(() => {
    if (editButton) {
      setModulePassPercentage(modulePercentage);
      setModuleName(moduleNames);
      setModuleTimeMinutes(moduleTimeMinutess);
      setModuleTimeHours(moduleTimeHourss);
      setVideoLink(videoUrl);
    }
  }, [open]);

  useEffect(() => {
    if (disableSave) {
      setTimeout(() => {
        setDisableSave(false);
      }, 5000);
    }
  }, [disableSave]);

  useEffect(() => {
    getCreateModule();
  }, []);

  const handleAddClick = (index: any) => {
    setInputListOfQuestions([
      ...inputListOfQuestions,
      {
        questionName: "",
        questionActive: true,
        option0: "",
        option0Check: false,
        option1: "",
        option1Check: false,
        option2: "",
        option2Check: false,
        option3: "",
        option3Check: false,
      },
    ]);
  };

  const handleRemoveClick = (index: any) => {
    if (inputListOfQuestions?.length !== 1) {
      const list = [...inputListOfQuestions];
      list.splice(index, 1);
      setInputListOfQuestions(list);
    }
  };

  const [checkedState, setCheckedState] = useState(new Array(4).fill(false));

  useEffect(() => {
    if (disableButton) {
      setTimeout(() => {
        setDisableButton(false);
      }, 3000);
    }
  }, [disableButton]);

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...inputListOfQuestions];

    if (
      name === "option0Check" ||
      name === "option1Check" ||
      name === "option2Check" ||
      name === "option3Check" ||
      name === "questionActive"
    ) {
      list[index][name] = e.target.checked;
    } else {
      list[index][name] = value;
    }

    setInputListOfQuestions(list);
  };

  const checkSaveModule = (e: any) => {
    let check = 1;
    inputListOfQuestions.map(async (item: any, index: any) => {
      if (
        item.option0Check === false &&
        item.option1Check === false &&
        item.option2Check === false &&
        item.option3Check === false
      ) {
        check = 0;
        alert("Please select atleast one answer in each question.");
      }
    });
    if (check === 1) {
      saveModule(e);
    }
  };

  const saveModule = async (e: any) => {
    e.preventDefault();
    setDisableButton(true);

    const newArray = inputListOfQuestions.map((item: any) => {
      return {
        name: item.questionName,
        // "status": item.questionActive === true ? "active" : "in_active",
        status: item.questionActive ? "active" : "in_active",

        assignment_answer_question: [
          {
            name: item.option0,
            is_correct:
              item.option0Check === "false" || item.option0Check === false
                ? false
                : true,
          },
          {
            name: item.option1,
            is_correct:
              item.option1Check === "false" || item.option1Check === false
                ? false
                : true,
          },
          {
            name: item.option2,
            is_correct:
              item.option2Check === "false" || item.option2Check === false
                ? false
                : true,
          },
          {
            name: item.option3,
            is_correct:
              item.option3Check === "false" || item.option3Check === false
                ? false
                : true,
          },
        ],
      };
    });

    const data = {
      name: moduleName,
      category: moduleCategory,
      minimum_score: modulePassPercentage,
      completion_minutes: moduleTimeMinutes,
      completion_hours: moduleTimeHours,
      video_url: videoLink,
      assignment_question_module: newArray,
      indexing:
        createModuleData?.count === 0
          ? 1
          : createModuleData?.results[createModuleData?.count - 1]?.id + 1,
    };

    if (editButton) {
      await updateModule(moduleIds, data);
    } else {
      await postCreateModule(data);
    }

    getCreateModule();
    setModuleName("");
    setModulePassPercentage(0);
    setModuleTimeMinutes(0);
    setModuleTimeHours(0);
    setVideoLink("");
    setInputListOfQuestions([
      {
        questionName: "",
        questionActive: true,
        option0: "",
        option0Check: false,
        option1: "",
        option1Check: false,
        option2: "",
        option2Check: false,
        option3: "",
        option3Check: false,
      },
    ]);
    setOpen(false);
  };

  useEffect(() => {
    if (editButton) {
      let list: any[] = [];
      moduleQuestion?.length > 0 &&
        moduleQuestion?.reverse()?.map((data: any, index: any) => {
          list[index] = {
            questionName: data?.name,
            questionActive: data?.status === "active" ? true : false,
            option0: data?.assignment_answer_question[0]?.name,
            option0Check:
              data?.assignment_answer_question[0]?.assignment_user % 2 === 1
                ? true
                : false,
            option1: data?.assignment_answer_question[1]?.name,
            option1Check:
              data?.assignment_answer_question[1]?.assignment_user % 2 === 1
                ? true
                : false,
            option2: data?.assignment_answer_question[2]?.name,
            option2Check:
              data?.assignment_answer_question[2]?.assignment_user % 2 === 1
                ? true
                : false,
            option3: data?.assignment_answer_question[3]?.name,
            option3Check:
              data?.assignment_answer_question[3]?.assignment_user % 2 === 1
                ? true
                : false,
          };
        });
      setInputListOfQuestions(list);
    }
  }, [moduleQuestion]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <div
                className="body"
                style={{ overflow: "scroll", height: "75vh" }}
              >
                <div style={{ marginLeft: "40px", paddingTop: "20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <h2 style={{ fontSize: "16px", padding: "25px 0 0 15px" }}>
                      Upload Module
                    </h2>
                    <HighlightOffIcon
                      onClick={() => {
                        setOpen(false);
                      }}
                      style={{
                        position: "relative",
                        left: "85%",
                        height: "40px",
                        width: "30px",
                      }}
                    />
                  </div>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 1 : Name your Module{" "}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <TextField
                    name="module_name"
                    type="text"
                    placeholder="Type here.."
                    value={moduleName}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "30%",
                      margin: "0",
                      backgroundColor: "white",
                    }}
                    onChange={(e) => setModuleName(e.target.value as String)}
                    required
                  />
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>

                <div style={{ marginLeft: "40px", paddingTop: "20px" }}>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 2 : Select min time for completion{" "}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <TextField
                    name="module_hours"
                    type="number"
                    placeholder="Hours"
                    value={moduleTimeHours}
                    onWheel={(e: any) => e.target.blur()}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Hours"
                    style={{
                      width: "12%",
                      margin: "0",
                      backgroundColor: "white",
                    }}
                    onChange={(e) => {
                      let hoursValue = parseInt(e.target.value);
                      if (hoursValue > 2) hoursValue = 2;
                      if (hoursValue < 0) hoursValue = 0;
                      e.target.value = hoursValue.toString();
                      setModuleTimeHours(e.target.value);
                    }}
                  />

                  <TextField
                    name="module_minutes"
                    type="number"
                    placeholder="Minutes"
                    value={moduleTimeMinutes}
                    onWheel={(e: any) => e.target.blur()}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Minutes"
                    style={{
                      width: "12%",
                      marginLeft: "32px",
                      backgroundColor: "white",
                    }}
                    onChange={(e) => {
                      let minutesValue = parseInt(e.target.value);
                      if (minutesValue > 59) minutesValue = 59;
                      if (minutesValue < 0) minutesValue = 0;
                      e.target.value = minutesValue.toString();
                      setModuleTimeMinutes(e.target.value);
                    }}
                    required
                  />
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>

                <div style={{ marginLeft: "40px", paddingTop: "20px" }}>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 3 : Pass percentage{" "}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <TextField
                    name="module_pass_percentage"
                    type="number"
                    placeholder="Type here.."
                    value={modulePassPercentage}
                    onWheel={(e: any) => e.target.blur()}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "30%",
                      margin: "0",
                      backgroundColor: "white",
                    }}
                    onChange={(e) => {
                      let percentageValue = parseInt(e.target.value);
                      if (percentageValue > 100) percentageValue = 100;
                      if (percentageValue < 0) percentageValue = 0;
                      e.target.value = percentageValue.toString();
                      setModulePassPercentage(e.target.value);
                    }}
                    required
                  />
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>

                <div style={{ marginLeft: "40px", paddingTop: "20px" }}>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 4 : Select Category{" "}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    className="input"
                    name="module_category"
                    variant="outlined"
                    value={moduleCategory}
                    style={{ width: "252px", margin: "0" }}
                    onChange={(e) =>
                      setModuleCategory(e.target.value as string)
                    }
                    required
                  >
                    <MenuItem value="employee">employee</MenuItem>
                  </Select>
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>

                <div style={{ marginLeft: "40px", paddingTop: "20px", display:"flex", flexDirection:"column" }}>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 5 :Content Type <span style={{ color: "red" }}>*</span>
                  </p>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                        onChange={(e) =>
                         setContentType(e.target.value as string)
                        }
                    >
                      <FormControlLabel
                        value="video"
                        control={<Radio />}
                        label="Video"
                      />
                      <FormControlLabel
                        value="pdf"
                        control={<Radio />}
                        label="Pdf"
                      />
                    </RadioGroup>
                  </FormControl>
                  {
                    contentType === "video" &&  <TextField
                    name="module_name"
                    type="text"
                    placeholder="Paste youtube video Link here.."
                    value={videoLink}
                    className="input"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: "58%", margin: "0", backgroundColor: "white" }}
                    onChange={(e) => setVideoLink(e.target.value as String)}
                    required
                />
                  }

                {
                    contentType === "pdf" &&  
                    <input 
                    name="pdf"
                    type="file"
                    disabled
                    accept=".pdf"
                    />

                  }
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>

                <div style={{ marginLeft: "40px", paddingTop: "20px" }}>
                  <p style={{ fontSize: "14px", marginTop: "26px" }}>
                    STEP 6 : Add Q&As
                  </p>

                  {inputListOfQuestions.map((x: any, index: number) => {
                    return (
                      <>
                        <div style={{ marginRight: "40px" }}>
                          <p style={{ fontSize: "14px", marginTop: "26px" }}>
                            Question {index + 1}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              name="questionName"
                              type="text"
                              placeholder="Type here.."
                              value={x.questionName}
                              className="input"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{
                                width: "60%",
                                margin: "0",
                                backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange(e, index)}
                              required
                            />

                            <div>
                              <input
                                type="checkbox"
                                id={`custom-checkbox-${4}`}
                                name={"questionActive"}
                                value={"questionActive"}
                                checked={x.questionActive}
                                // checked={x.questionActive === "active"}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                              <span style={{ paddingLeft: "20px" }}>
                                Active / Inactive
                              </span>
                            </div>
                          </div>
                          <p style={{ fontSize: "14px", marginTop: "26px" }}>
                            Options
                          </p>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                name="option0"
                                type="text"
                                placeholder="Type here.."
                                value={x.option0}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{
                                  width: "60%",
                                  marginBottom: "10px",
                                  backgroundColor: "white",
                                }}
                                onChange={(e) => handleInputChange(e, index)}
                                required
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${0}`}
                                  name={"option0Check"}
                                  value={"option0Check"}
                                  checked={x.option0Check}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span style={{ paddingLeft: "20px" }}>
                                  Right Answer
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                name="option1"
                                type="text"
                                placeholder="Type here.."
                                value={x.option1}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{
                                  width: "60%",
                                  marginBottom: "10px",
                                  backgroundColor: "white",
                                }}
                                onChange={(e) => handleInputChange(e, index)}
                                required
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${1}`}
                                  name={"option1Check"}
                                  value={"option1Check"}
                                  checked={x.option1Check}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span style={{ paddingLeft: "20px" }}>
                                  Right Answer
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                name="option2"
                                type="text"
                                placeholder="Type here.."
                                value={x.option2}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{
                                  width: "60%",
                                  marginBottom: "10px",
                                  backgroundColor: "white",
                                }}
                                onChange={(e) => handleInputChange(e, index)}
                                required
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${2}`}
                                  name={"option2Check"}
                                  value={"option2Check"}
                                  checked={x.option2Check}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span style={{ paddingLeft: "20px" }}>
                                  Right Answer
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                name="option3"
                                type="text"
                                placeholder="Type here.."
                                value={x.option3}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{
                                  width: "60%",
                                  marginBottom: "10px",
                                  backgroundColor: "white",
                                }}
                                onChange={(e) => handleInputChange(e, index)}
                                required
                              />
                              <div>
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${3}`}
                                  name={"option3Check"}
                                  value={"option3Check"}
                                  checked={x.option3Check}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span style={{ paddingLeft: "20px" }}>
                                  Right Answer
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ paddingRight: "14px", fontSize: "14px" }}
                          >
                            <ControlPointIcon
                              onClick={() => {
                                setAllOptionsOkay([...allOptionsOkay, false]);
                                handleAddClick(index);
                              }}
                            />{" "}
                            Add Question
                          </div>
                          <div
                            style={{ paddingRight: "14px", fontSize: "14px" }}
                          >
                            <DeleteRoundedIcon
                              onClick={() => {
                                setAllOptionsOkay(
                                  (previousArr: string | any[]) =>
                                    previousArr.slice(0, -1)
                                );
                                handleRemoveClick(index);
                              }}
                            />{" "}
                            Delete Question
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <hr style={{ margin: "40px 0 0px 0" }}></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "35px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor:
                        moduleName === "" ||
                        (moduleTimeMinutes === 0 && moduleTimeHours === 0) ||
                        modulePassPercentage === "" ||
                        videoLink === "" ||
                        moduleCategory === "default"
                          ? "#beb8b89e"
                          : "#c70e0ef7",
                      color:
                        moduleName === "" ||
                        (moduleTimeMinutes === 0 && moduleTimeHours === 0) ||
                        modulePassPercentage === "" ||
                        videoLink === "" ||
                        moduleCategory === "default"
                          ? "black"
                          : "white",
                      borderRadius: "8px",
                      width: "220px",
                      height: "40px",
                      margin: "25px 15px 0 0px",
                      border:
                        moduleName === "" ||
                        (moduleTimeMinutes === 0 && moduleTimeHours === 0) ||
                        modulePassPercentage === "" ||
                        videoLink === "" ||
                        moduleCategory === "default"
                          ? ""
                          : "1px solid",
                      textTransform: "none",
                    }}
                    disabled={
                      moduleName === "" ||
                      (moduleTimeMinutes === 0 && moduleTimeHours === 0) ||
                      modulePassPercentage === "" ||
                      videoLink === "" ||
                      moduleCategory === "default" ||
                      disableButton
                    }
                    onClick={(e: any) => {
                      checkSaveModule(e);
                    }}
                    size="small"
                  >
                    <p style={{ margin: "0", fontSize: "15px" }}>
                      {editButton ? "Update Module" : "Create Module"}
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
  postCreateModule,
  getCreateModule,
  updateModule,
})(LearningModal);
