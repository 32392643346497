import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Loader from '../Loader2';
import Modal from '@mui/material/Modal';
import Fade from '@material-ui/core/Fade';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import './index.sass';
import { Box, Button } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
          },
          conlorChange: {
            background: "red",
          },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        table: {
            maxWidth: "100%",
          },
          tableHead: {
            background: "rgb(146, 74, 145)!important",
          },
          tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
          },
          tableContainer: {
            position: "sticky",
            top: "200px",
            overflow:"scroll",
          },
    })
);



const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);


interface Props {

    loading: Boolean;

}

const HolidayTable: React.FC<Props> = ({

    loading,
}) => {
    const classes = useStyles()

    const PolicyData = [
        { date: 'Pending', name: 'Leave Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Cancel', name: 'AML Policy_Reviewed', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Open', name: 'Attendance Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Complated', name: 'Increment Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Open', name: 'Govt Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Cancel', name: 'FNF Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Pending', name: 'Fastival Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
        { date: 'Cancel', name: 'Hr Policy', url: 'https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf' },
    ];

    const [openPdf, setOpenPdf] = useState<boolean>(false)
    const [openPdfTable, setOpenPdfTable] = useState<boolean>(false)
    const [fileLink, setFileLink] = useState<any>("")
    const [docs, setDocs] = useState<any>([])

    const handlePdfModelClose = () => {
        setOpenPdf(false)
    }


    const handleViewPdf = () => {
        setOpenPdf(true)
    }

    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h4>Policy center</h4>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "740px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Policy Title Holiday’s Name</StyledTableCell>
                                <StyledTableCell align="center">Acknowledgement Staus</StyledTableCell>
                                <StyledTableCell align="center">View</StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        {PolicyData.map((policy) => (
                            <TableRow key={policy.date}>
                                <TableCell>{policy.name}</TableCell>
                                <TableCell>{policy.date}</TableCell>
                                <TableCell><Button
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        marginLeft: "10px"
                                    }}
                                    variant="contained"
                                    style={{ backgroundColor: "rgb(102 76 118)" }}
                                    onClick={() => {
                                        setFileLink(policy.url);
                                        // setDocs([{ uri: `${data?.file}` }]);
                                        handleViewPdf();
                                    }}
                                >

                                    View
                                </Button></TableCell>

                            </TableRow>
                        ))}
                        <TableFooter>
                            <StyledTableRow>

                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>

            {openPdf === true &&
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openPdf}
                    onClose={handlePdfModelClose}
                    closeAfterTransition

                >
                    <Fade in={openPdf}>
                        {
                            (fileLink?.includes(".jpg") || fileLink?.includes(".jpeg") || fileLink?.includes(".png")) ?
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img
                                        src={`${fileLink}`}
                                        style={{ width: "95vh", height: "80%" }}
                                        title="IMAGE Document"
                                    />
                                </div>
                                : (
                                    (fileLink?.includes(".doc") || fileLink?.includes(".docx")) ?
                                        <div id="mera-doc-viewer">
                                            <DocViewer
                                                style={{ height: "-webkit-fill-available" }}
                                                pluginRenderers={DocViewerRenderers}
                                                documents={[{ uri: fileLink, fileType: "doc" }]}
                                            />
                                        </div>
                                        :
                                        <iframe
                                            src={`${fileLink}`}
                                            style={{ width: "80%", height: "750px" }}
                                            title="PDF Document"
                                        ></iframe>
                                )
                        }
                    </Fade>
                </Modal>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.PayrollReducer.loading,
})

export default connect(mapStateToProps, {


})(HolidayTable);

