import { ACTIONS } from "../interfaces/actionTypes/Payroll";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const changePassword = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/auth/password_reset/reset_password/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getDisplayHolidaysList = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/national-holidays-list/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: err });
    throw err;
  }
};

export const getEmployeeData = (url: string) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/employees/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_EMPLOYEE_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const leaveApplication =
  (payload: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/leave_application`,
        payload,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.LEAVE_APPLICATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Leave apply successfully! ",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.errors) {
        title = JSON.stringify(err?.errors).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

// export const leaveApplication = (body: any) => async (dispatch: Function) => {
//   dispatch({ type: ACTIONS.SET_LOADING, payload: true });
//   try {
//     const { data } = await AXIOS.post(`${Prefix.api}/leave_application`,
//       body,
//     );
//     dispatch({ type: ACTIONS.LEAVE_APPLICATION, payload: data });
//     snackBarUpdate({
//       payload: {
//         message: "Application sumbit  Successfully.",
//         status: true,
//         type: "success",
//       },
//     })(dispatch);
//     dispatch({ type: ACTIONS.SET_LOADING, payload: false });
//   } catch (err: any) {
//     let title = "";
//     if (err?.response?.status === 404) {
//       title = "Something went wrong"
//     } else if (err?.response) {
//       title = JSON.stringify(err.response.data?.errors).replace(
//         /"|{|}|[|]/g,
//         ""
//       );
//     } else {
//       title = "Something went wrong!";
//     }
//     snackBarUpdate({
//       payload: {
//         message: title || "Something went wrong!",
//         status: true,
//         type: "error",
//       },
//     })(dispatch);
//     dispatch({ type: ACTIONS.SET_LOADING, payload: false });
//     throw err;
//   }
// }

export const getAttendanceMaster =
  (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/attendance-list/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ATTENDANCE_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.ATTENDANCE_MASTER, payload: err });
      throw err;
    }
  };
export const getLeaveApplication = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/leave_application`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAVE_APPLICATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_LEAVE_APPLICATION, payload: err });
    throw err;
  }
};

export const getpendingregularization = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.GET_LOADING_REGULARIZATION, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/pending/regularization/${url ? url : ''}`, {

      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_PENDING_REGULARIZATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_PENDING_REGULARIZATION, payload: err });
    throw err;
  } finally {
    // Ensure loader is always set to false after the request
    dispatch({ type: ACTIONS.GET_LOADING_REGULARIZATION, payload: false });
  }
};

export const PostEmployeeSalary = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/employee-components-update/`,

      body
    );
    dispatch({ type: ACTIONS.EMPLOYEE_SALARY, payload: data });
    snackBarUpdate({
      payload: {
        message: "Application sumbit  Successfully.",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Something went wrong";
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const leaveApplicationReview =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/leave_application/review/ `,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.LEAVE_APPLICATION_REVIEW, payload: data });
      snackBarUpdate({
        payload: {
          message: "Application sumbit  Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.LEAVE_APPLICATION_REVIEW, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.status === 404) {
        title = "Something went wrong";
      } else if (err?.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getBirthdayReminder = () => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/users/birthday-reminder/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.BIRTHDAY_REMINDER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
    } else {
      title = "Can't Fetch Data";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.BIRTHDAY_REMINDER, payload: err });
    throw err;
  }
};

export const createLeaveRequests =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/leave_application/review/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_LEAVE_REQUESTS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Request Successful",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };


export const getLeaveRequests =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/leave_application${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAVE_REQUESTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateRegularization =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/regularization/action/`,
        url,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_REGULARIZE, payload: data });
      snackBarUpdate({
        payload: {
          message: `Regularized Successfully`,
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors);
      } else {
        title = "Only manager can approve the request.";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);

      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };


export const updateImage = (formData: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.put(`${Prefix.api}/users/profile-picture-add/${formData.id ? formData.id : ""}/`,
      formData.data,
      {
        headers: {
          Authorization: `Token ${SecureStorage.getItem("token")}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: ACTIONS.POST_UPDATE_IMAGE, payload: data });
    snackBarUpdate({
      payload: {
        message: "Profile Updated!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};



export const getUserProfileImage = (url: string, body: any) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/users/profile-picture-add/${url ? `${url}/` : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")} ` }
      }
    );
    dispatch({ type: ACTIONS.GET_PROFILE_IMAGE, payload: data });

  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};
export const AddEmploymentDetails =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/employment-details/`,
        body,
        {
          headers: { "Content-Type": 'multipart/form-data', Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ADD_EMPLOYMENT_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Employment details added Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const EditEmploymentDetails =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/employment-details/${id}/`, body, {
        headers: {"Content-Type": 'multipart/form-data', Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.EDIT_EMPLOYMENT_DETAILS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employment Details Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getEmploymentDetails =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employment-details/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYMENT_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const deleteEmploymentDetail = (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/employment-details/${id}/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "Employment detail deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.DELETE_EMPLOYMENT_DETAIL, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const AddCertificate =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/certificate-details/`,
        body,
        {
          headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ADD_CERTIFICATE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Employment details added Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const EditCertificate =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/certificate-details/${id}/`, body, {
        headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.EDIT_CERTIFICATE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employment Details Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getCertificates =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/certificate-details/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CERTIFICATE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const deleteCertificate = (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/certificate-details/${id}/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "Employment detail deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.DELETE_CERTIFICATE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  
  export const AddMedicalInfo =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/medical-details/`,
        body,
        {
          headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ADD_MEDICAL_INFO, payload: data });
      snackBarUpdate({
        payload: {
          message: "Medical details added Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const EditMedicalInfo =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/medical-details/${id}/`, body, {
        headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.EDIT_CERTIFICATE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Medical Details Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getMedicalInfo =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/medical-details/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_MEDICAL_INFO, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const AddEducation =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/education-details/`,
        body,
        {
          headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.ADD_EDUCATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Employment details added Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data) {
        title = JSON.stringify(err?.response?.data).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const EditEducation =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(`${Prefix.api}/education-details/${id}/`, body, {
        headers: { "Content-Type": 'multipart/form-data' , Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.EDIT_EDUCATION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Employment Details Updated Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data) {
        title = JSON.stringify(err.response.data).replace(/"|{|}|[|]/g, "");
      } else if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getEducationDetails =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/education-details/${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_EDUCATION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const deleteEducation = (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/education-details/${id}/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "Employment detail deleted successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.DELETE_EDUCATION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(/"|{|}|[|]/g, "");
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getEmployeFilterList = (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/employees/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_EMPLOYEE_FILTER_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err?.response?.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.message) {
        title = JSON.stringify(err?.response?.data?.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else if (err?.response?.data?.error) {
        title = JSON.stringify(err?.response?.data?.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      }
      else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const updateRegularizationCount =
  (url: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/regularization-count/update/`,
        url,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_REGULARIZATION_COUNT, payload: data });
      snackBarUpdate({
        payload: {
          message: `Regularization count updated Successfully`,
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors);
      } else {
        title = "Only manager can update the count.";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);

      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
