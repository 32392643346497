import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  TableContainer,
  withStyles,
  TableFooter,
  TablePagination,
  Switch,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TableRow from "@material-ui/core/TableRow";
import { Slider } from "@mui/material";
import { ACTIONS } from "../../../interfaces/actionTypes/loginTypes";
import "./index.sass";
import {
  getEmployeeList,
  createEmployee,
  editEmployeeById,
  getEmployeeDepartment,
  getOrganizationsList,
  PostAssignManage,
  getEmployeeALlData,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
  getLocationList,
  UpdateEmployeeWorkLocation,
  getGeoFenceCordinateList,
  CreateGeoFenceCordinate,
  updateGeoLocation,
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import { generateEmployeeFilter } from "../../../helper/generate";
import Backdrop from "@mui/material/Backdrop";
import { getUserProfileImage, updateImage } from "../../actions/PayrollActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      position: "absolute",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      position: "absolute",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const CustomChip = styled(Chip)(({ theme: any }) => ({
  margin: "4px",
  backgroundColor: "#e0e0e0",
  color: "#424242",
  fontWeight: "500",
  fontSize: "14px",
  borderRadius: "16px",
  height: "32px",
  display: "inline-flex",
  alignItems: "center",
  padding: "0 12px",
  boxShadow: "none",
  "& .MuiChip-label": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getEmployeeList: any;
  employeesList: any;
  createEmployee: any;
  editEmployeeById: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  empObj: any;
  getOrganizationsList: any;
  organizationsList: any;
  PostAssignManage: any;
  getEmployeeALlData: any;
  employee_all_data: any;
  getEmployeeMode: any;
  get_Employee_Mode: any;
  getReportingManager: any;
  reporting_manager: any;
  loading: any;
  getShiftList: any;
  shiftList: any;
  getEmployeeSubDept: any;
  subDeptName: any;
  getDesignationtList: any;
  designationList: any;
  getExportEmployeeList: any;
  exportList: any;
  exportListLoading: any;
  getUserProfileImage: any;
  getProfileImage: any;
  updateImage: any;
  postProfileImage: any;
  locationList: any;
  getLocationList: any;
  UpdateEmployeeWorkLocation: any;
  getGeoFenceCordinateList: any;
  geoFenceCordinate: any;
  CreateGeoFenceCordinate: any;
  updateGeoLocation: any;
}
const CreateEmployeetTablePage: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  createEmployee,
  editEmployeeById,
  getEmployeeDepartment,
  departmentNames,
  getOrganizationsList,
  organizationsList,
  PostAssignManage,
  empObj,
  employee_all_data,
  getEmployeeALlData,
  getEmployeeMode,
  get_Employee_Mode,
  getReportingManager,
  reporting_manager,
  loading,
  getShiftList,
  shiftList,
  getEmployeeSubDept,
  subDeptName,
  getDesignationtList,
  designationList,
  getExportEmployeeList,
  exportList,
  exportListLoading,
  getUserProfileImage,
  getProfileImage,
  updateImage,
  postProfileImage,
  locationList,
  getLocationList,
  UpdateEmployeeWorkLocation,
  getGeoFenceCordinateList,
  geoFenceCordinate,
  CreateGeoFenceCordinate,
  updateGeoLocation,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [employeeEditopen, setEmployeeEditopen] = React.useState(false);
  const history = useHistory();
  ///Employee Create Page
  const [employeeId, setEmployeeId] = useState<any>("");
  const [employeeName, setEmployeeName] = useState<any>("");
  const [employeeDesignationId, setEmployeeDesignationId] = useState<any>("");
  const [employeeDesignation, setEmployeeDesignation] = useState<any>("");
  const [employeeStatus, setEmployeeStatus] = useState<any>("");
  const [employeeLocation, setEmployeeLocation] = useState<any>("");
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [employeeManagerId, setEmployeeManagerId] = useState<any>("");
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState<any>("");
  const [FilterBtn, setFilterBtn] = useState<any>(false);
  const [EmployeePunchInPuchOutLocation, setEmployeePunchInPuchOutLocation] =
    useState<any>([]);
  const [createGeoFanceModel, setCreateGeoFanceModel] = useState<any>(false);
  const [Location, setLocation] = useState<any>("");
  const [latitude, setLatitude] = useState<any>("");
  const [longitude, setLongitude] = useState<any>("");
  const [employeeWorkLocation, setEmployeeWorkLocation] = useState<any>([]);
  const [EmployeeWorkLocationId, setEmployeeWorkLocationId] = useState<any>([]);
  const [GeoFanceModelList, setGeoFanceModelList] = useState<any>(false);
  const [locationFilter, setLocationFilter] = useState<any>("");
  const [active_employee, setActive_employee] = useState<any>("");

  useEffect(() => {
    getEmployeeList("get_all=true");
    getEmployeeALlData("");
    getEmployeeDepartment("");
    getOrganizationsList("");
    getEmployeeMode();
    getReportingManager(`is_manager=true`);
    getShiftList();
    getEmployeeSubDept("");
    getDesignationtList();
    getGeoFenceCordinateList("");
  }, []);

  const handleCreateEmployee = async () => {
    const body = {
      work_location: EmployeeWorkLocationId,
    };
    await UpdateEmployeeWorkLocation(body, employeeUUID);
    setEmployeeEditopen(false);
    handleResetFunction();
  };
  const handleCreateGeoFenceCordinate = async () => {
    const body = {
      location: Location,
      latitude: latitude,
      longitude: longitude,
    };
    await CreateGeoFenceCordinate(body);
    handleResetFunction();
    setCreateGeoFanceModel(false);
  };
  const handleResetEmployeeDeviceId = async (data: any) => {
    const body = {
      device_id: "none",
    };
    await UpdateEmployeeWorkLocation(body, data.uuid);
    handleResetFunction();
    setEmployeeEditopen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = employeesList.links && employeesList.links.next.split("?")[1];

      getEmployeeList(`${url}`);
    } else if (newPage < page) {
      let url =
        employeesList.links && employeesList.links.previous.split("?")[1];

      getEmployeeList(`${url}`);
    }
    setPage(newPage as number);
  };
  const handleChangePageForLocation = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        geoFenceCordinate.links && geoFenceCordinate.links.next.split("?")[1];

      getGeoFenceCordinateList(`${url}`);
    } else if (newPage < page) {
      let url =
        geoFenceCordinate.links &&
        geoFenceCordinate.links.previous.split("?")[1];

      getGeoFenceCordinateList(`${url}`);
    }
    setPage(newPage as number);
  };

  const handleEditFunction = (data: any) => {
    setEmployeeName(data?.fullname);
    setEmployeeUUID(data.uuid);
    setEmployeeId(data?.employee_id);
    setEmployeeDesignation(data?.employee_designation);
    setEmployeeLocation(data?.location);
    setEmployeePhoneNumber(data?.contact_number);
    if (data.employee_work_location) {
      setEmployeeWorkLocation(data.employee_work_location);
      setEmployeeWorkLocationId(
        data.employee_work_location.map((item: any) => item.id)
      );
    }
  };
  const handleResetFunction = () => {
    history.push("/dashboard/payrollManager/employee_location");
  };

  const handlFilterEmployee = () => {
    setFilterBtn(true);
    const body: any = {
      employee_id: employeeId,
      department: employeeDept != 64 ? employeeDept : "",
      manager: employeeManagerId,
      employee_status: employeeStatus,
      active_employee: active_employee,
    };
    const url = generateEmployeeFilter(body).substring(2);
    getEmployeeList(`get_all=true&${url}`);
    getExportEmployeeList(`get_all=true&export=true&${url}`);
    setPage(0);
  };

  const exportData = () => {
    var rows = [];

    //iterate through rows of table
    let row = exportList;
    rows.push([
      "Employee Name	",
      "Employee ID",
      "Employee Designation",
      "Phone Number",
      "Employee Location	",
      "Punch In Out Location",
    ]);

    for (var i = 0; i < row.length; i++) {
      rows.push([
        row[i]?.name,
        row[i]?.employee_id,
        row[i]?.employee_designation,
        row[i]?.phonenumber,
        row[i]?.location,
        row[i]?.employee_work_location_name,
      ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
      row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "EmployeeList.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({ type: ACTIONS.GET_EXPORT_EMPLOYEE_LIST, payload: [] });
  };
  const handleCreateGeoFance = () => {
    setCreateGeoFanceModel(true);
  };
  const handleGeoFanceList = () => {
    setGeoFanceModelList(true);
  };
  const handleGeoListFilter = () => {
    getGeoFenceCordinateList(`name=${locationFilter}`);
  };

  const [editLocationData, setEditLocationData] = useState<any>({});
  const [locationStatus, setLocationStatus] = useState<boolean>(false);
  const [geolongitude, setGeoLongitude] = useState<any>("");
  const [geolatitude, setGeolatitude] = useState<any>("");

  const handleEditLocation = (data: any) => {
    setEditLocationData(data);
    setLocationStatus(data.is_active);
    setGeoLongitude(data.longitude);
    setGeolatitude(data.latitude);
  };

  const handleUpdatePackage = async (data: any) => {
    const body: any = {
      is_active: locationStatus,
      latitude: geolongitude,
      longitude: geolatitude,
    };
    await updateGeoLocation(body, data.id);
    getGeoFenceCordinateList("");
    setEditLocationData({});
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            backgroundColor: "rgb(28, 78, 128)",
            color: "#fff",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "10px",
            borderRadius: "15px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          Manage Employee Location
        </h4>

        <Grid
          container
          spacing={3}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeId(obj.employee_id);
                } else {
                  setEmployeeId("");
                }
              }}
              id="checkboxes-tags-demo"
              options={employee_all_data?.results || []}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeALlData(`employee_id=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Employee ID Or Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeDept(obj.id);
                } else {
                  setEmployeeDept("");
                }
              }}
              id="department-name"
              options={departmentNames || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeDepartment(`name=${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              renderOption={(option: any) => {
                return option?.name;
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Department Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeManagerId(obj?.employee_id);
                } else {
                  setEmployeeManagerId("");
                }
              }}
              id="checkboxes-tags-demo"
              options={employee_all_data?.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeALlData(`employee_id=${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Manager Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ticket_status"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setEmployeeStatus(obj?.value);
                } else {
                  setEmployeeStatus("");
                }
              }}
              options={[
                {
                  text: "In Active",
                  value: "inactive",
                },
              ]}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return employeeStatus.includes(option.value);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="In Active Employee"
                  variant="outlined"
                  value={employeeStatus}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ticket_status"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setActive_employee(obj?.value);
                } else {
                  setActive_employee("");
                }
              }}
              options={[
                {
                  text: "Active",
                  value: "true",
                },
              ]}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return active_employee.includes(option.value);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Active Employee  "
                  variant="outlined"
                  value={active_employee}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{
                backgroundColor: "#1976d2",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              startIcon={<SearchIcon />}
              onClick={handlFilterEmployee}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#1565c0";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#1976d2";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<RotateLeftIcon />}
              onClick={() => handleResetFunction()}
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <LoadingButton
              variant="contained"
              fullWidth
              style={{
                backgroundColor:
                  !FilterBtn || exportListLoading ? "gray" : "#009688",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                if (!exportListLoading) {
                  e.currentTarget.style.backgroundColor = "#7b1fa2";
                  e.currentTarget.style.boxShadow =
                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                }
              }}
              onMouseOut={(e) => {
                if (!exportListLoading) {
                  e.currentTarget.style.backgroundColor =
                    !FilterBtn || exportListLoading ? "gray" : "#9c27b0";
                  e.currentTarget.style.boxShadow = "none";
                }
              }}
              loading={exportListLoading}
              loadingPosition="start"
              disabled={!FilterBtn || exportListLoading}
              startIcon={exportListLoading ? null : <ArrowCircleDownIcon />}
              onClick={exportData}
            >
              Download Employee Geofence Data
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#607d8b",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              startIcon={<MyLocationIcon />}
              onClick={handleCreateGeoFance}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#fbc02d";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#607d8b";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Create Geofence cordinate
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#0097a7",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              startIcon={<AddLocationIcon />}
              onClick={handleGeoFanceList}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#40c4ff";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#0097a7";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Geofence cordinate List
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            padding: "10px",
            marginTop: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            backgroundColor: "#e3f2fd",
            display: "inline-block",
            fontSize: "16px",
            color: "#c62828",
          }}
        >
          <span>
            👉 Please click on the filter button to enable the Download Employee
            Location List button.
          </span>
        </div>

        <TableContainer
          className={classes.tableContainer}
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Edit Location</StyledTableCell>
                <StyledTableCell align="center">
                  Reset Device ID
                </StyledTableCell>
                <StyledTableCell align="center">Device ID</StyledTableCell>
                <StyledTableCell align="center">Employee Name</StyledTableCell>
                <StyledTableCell align="center">Employee ID</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Designation
                </StyledTableCell>

                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Location
                </StyledTableCell>
                <StyledTableCell align="center">
                  Punch In Out Location
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody>
                  {employeesList &&
                    employeesList?.results?.length > 0 &&
                    employeesList?.results?.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            <div>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleEditFunction(data);
                                  setEmployeeEditopen(true);
                                }}
                                startIcon={<EditLocationAltIcon />}
                                style={{
                                  borderRadius: "8px",
                                  padding: "8px 16px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "none",
                                  backgroundColor: "#155a8a", // Blue color
                                  color: "#ffffff",
                                  transition:
                                    "background-color 0.3s, box-shadow 0.3s",
                                  boxShadow: "none",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#1976d2"; // Darker blue on hover
                                  e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#155a8a";
                                  e.currentTarget.style.boxShadow = "none";
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() =>
                                  handleResetEmployeeDeviceId(data)
                                }
                                startIcon={<RestartAltIcon />}
                                style={{
                                  borderRadius: "8px",
                                  padding: "8px 16px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "none",
                                  backgroundColor: "#f48fb1", // Soft pink color
                                  color: "#ffffff",
                                  transition:
                                    "background-color 0.3s, box-shadow 0.3s",
                                  boxShadow: "none",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#f06292"; // Slightly darker pink on hover
                                  e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#f48fb1";
                                  e.currentTarget.style.boxShadow = "none";
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data.device_id !== null ? data.device_id : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_id}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.employee_designation
                              ? data?.employee_designation
                              : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.phonenumber ? data?.phonenumber : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.location ? data?.location : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_work_location
                              ? data.employee_work_location.map(
                                  (location: any, index: any) => (
                                    <CustomChip
                                      key={index}
                                      label={location.location.trim()}
                                    />
                                  )
                                )
                              : "NA"}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </>
            )}

            <TableFooter>
              <StyledTableRow style={{ textAlign: "center" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={6}
                  count={employeesList?.count || 0}
                  rowsPerPage={employeesList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={employeeEditopen}
          onClose={() => setEmployeeEditopen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={employeeEditopen}>
            <div className={classes.paper1}>
              <h3
                style={{
                  color: "#0e3f6c",
                }}
              >
                Update Employee Location
              </h3>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="flex-start"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_id"
                    label="Employee Id"
                    value={employeeId}
                    onChange={(e) => {
                      setEmployeeId(e.target.value as String);
                    }}
                    disabled
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_name"
                    type="text"
                    label="Employee Name "
                    value={employeeName}
                    disabled
                    variant="outlined"
                    onChange={(e) => setEmployeeName(e.target.value as string)}
                    inputProps={{ maxLength: 100 }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEmployeeDesignationId(obj.id);
                        setEmployeeDesignation(obj.name);
                      }
                    }}
                    id="department-name"
                    options={designationList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getDesignationtList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.designation;
                    }}
                    renderOption={(option: any) => {
                      return option?.designation;
                    }}
                    disabled
                    defaultValue={{ designation: employeeDesignation }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Designation "
                        variant="outlined"
                        disabled
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    disabled
                    className="input"
                    name="employee_phone_number"
                    type="text"
                    label="Contact Number"
                    value={employeePhoneNumber}
                    onChange={(e) => {
                      setEmployeePhoneNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    id="Employee_Punch_In_Puch_Out_Location"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let panelUserArr: any = [];
                        let panelUserId: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          panelUserArr.push(obj);
                          panelUserId.push(obj.id);
                        });
                        setEmployeeWorkLocation(panelUserArr);
                        setEmployeeWorkLocationId(panelUserId);
                      }
                    }}
                    value={employeeWorkLocation}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getGeoFenceCordinateList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    multiple
                    disableClearable
                    disableCloseOnSelect
                    limitTags={1}
                    options={geoFenceCordinate?.results || []}
                    freeSolo
                    blurOnSelect
                    getOptionDisabled={(option: any) => {
                      return EmployeeWorkLocationId.includes(option.id);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.location;
                    }}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          key={index}
                          label={option?.location}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Punch In Puch Out Location"
                        required
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    startIcon={<SyncAltIcon />}
                    fullWidth
                    style={{
                      backgroundColor:
                        employeeWorkLocation === "" ? "#004d40" : "gray",
                      color: "#ffffff",
                      borderRadius: "8px",
                      padding: "8px 16px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "none",
                    }}
                    onClick={handleCreateEmployee}
                    disabled={employeeWorkLocation === ""}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#00332b";
                      e.currentTarget.style.boxShadow =
                        "0px 2px 4px rgba(0, 0, 0, 0.1)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#004d40";
                      e.currentTarget.style.boxShadow = "none";
                    }}
                  >
                    Update location
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0e3f6c" }}
                    fullWidth
                    onClick={() => setEmployeeEditopen(false)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={createGeoFanceModel}
          onClose={() => setCreateGeoFanceModel(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={createGeoFanceModel}>
            <div className={classes.paper1}>
              <h3
                style={{
                  color: "#0e3f6c",
                }}
              >
                Create Geofence Location
              </h3>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="flex-start"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="Location"
                    label="Location "
                    value={Location}
                    onChange={(e) => {
                      setLocation(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="Location"
                    type="text"
                    label="latitude "
                    value={latitude}
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        setLatitude(value);
                      }
                    }}
                    style={{ margin: "0", width: "100%" }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_location"
                    type="text"
                    label="longitude"
                    value={longitude}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        setLongitude(value);
                      }
                    }}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    startIcon={<SyncAltIcon />}
                    fullWidth
                    style={{
                      backgroundColor:
                        longitude !== "" && latitude !== "" && Location !== ""
                          ? "#004d40"
                          : "gray",
                      color: "#ffffff",
                      borderRadius: "8px",
                      padding: "8px 16px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      boxShadow: "none",
                    }}
                    onClick={handleCreateGeoFenceCordinate}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#00332b"; // Darker green on hover
                      e.currentTarget.style.boxShadow =
                        "0px 2px 4px rgba(0, 0, 0, 0.1)";
                    }}
                    disabled={
                      longitude === "" && latitude === "" && Location === ""
                    }
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#004d40";
                      e.currentTarget.style.boxShadow = "none";
                    }}
                  >
                    Create Geo location
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0e3f6c" }}
                    fullWidth
                    onClick={() => {
                      setCreateGeoFanceModel(false);
                      setLongitude("");
                      setLatitude("");
                      setLocation("");
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={GeoFanceModelList}
          onClose={() => setGeoFanceModelList(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={GeoFanceModelList}>
            <div className={classes.paper1}>
              <h3
                style={{
                  color: "#0e3f6c",
                }}
              >
                Geofence Location List
              </h3>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="flex-start"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="Location"
                    label="Location "
                    value={locationFilter}
                    onChange={(e) => {
                      setLocationFilter(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#9c27b0" }}
                    fullWidth
                    onClick={handleGeoListFilter}
                  >
                    Filter
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0e3f6c" }}
                    fullWidth
                    onClick={() => {
                      setGeoFanceModelList(false);
                      setLocationFilter("");
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>

              <TableContainer
                className={classes.tableContainer}
                style={{ maxHeight: "560px" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">Location</StyledTableCell>
                      <StyledTableCell align="center">
                        Longitude
                      </StyledTableCell>
                      <StyledTableCell align="center">Latitude</StyledTableCell>
                      <StyledTableCell align="center">
                        Is Active
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Created_at
                      </StyledTableCell>
                      <StyledTableCell align="center">Edit</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <TableBody>
                        {geoFenceCordinate.results &&
                          geoFenceCordinate?.results?.length > 0 &&
                          geoFenceCordinate?.results?.map(
                            (data: any, index: any) => {
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="center">
                                    {data?.location}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {/* {data?.longitude ? data?.longitude : "NA"} */}
                                    {editLocationData?.id === data?.id ? (
                                      <TextField
                                        name="longitude"
                                        type="text"
                                        value={geolongitude}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Geo longitude"
                                        onChange={(e) =>
                                          setGeoLongitude(
                                            e.target.value as string
                                          )
                                        }
                                        style={{ width: "100%" }}
                                        required
                                      />
                                    ) : (
                                      data?.longitude
                                    )}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {editLocationData?.id === data?.id ? (
                                      <TextField
                                        name="latitude"
                                        type="text"
                                        value={geolatitude}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Geo Latitude"
                                        onChange={(e) =>
                                          setGeolatitude(
                                            e.target.value as string
                                          )
                                        }
                                        style={{ width: "100%" }}
                                        required
                                      />
                                    ) : (
                                      data?.latitude
                                    )}
                                    {/* {data?.latitude ? data?.latitude : "NA"} */}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {editLocationData?.id === data?.id ? (
                                      <Switch
                                        defaultChecked={data?.is_active}
                                        checked={locationStatus}
                                        onChange={(e) =>
                                          setLocationStatus(e.target.checked)
                                        }
                                        name="checkedA"
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                    ) : (
                                      <DefaultSwitch
                                        checked={data?.is_active}
                                        name="checkedA"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                        disableRipple
                                      />
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {data?.created_at.substring(0, 10)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {editLocationData?.id === data?.id ? (
                                      <Button
                                        color="primary"
                                        variant="text"
                                        onClick={() =>
                                          handleUpdatePackage(data)
                                        }
                                      >
                                        Submit
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        variant="text"
                                        onClick={() => handleEditLocation(data)}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </>
                  )}

                  <TableFooter>
                    <StyledTableRow style={{ textAlign: "center" }}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={6}
                        count={geoFenceCordinate?.count || 0}
                        rowsPerPage={geoFenceCordinate?.page_size}
                        page={page}
                        onPageChange={handleChangePageForLocation}
                      />
                    </StyledTableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </Fade>
        </Modal>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  loading: state.loginReducer.loading,
  organizationsList: state.loginReducer.organizationsList,
  departmentNames: state.loginReducer.departmentNames,
  employee_all_data: state.loginReducer.employee_all_data,
  get_Employee_Mode: state.loginReducer.get_Employee_Mode,
  reporting_manager: state.loginReducer.reporting_manager,
  shiftList: state.loginReducer.shiftList,
  subDeptName: state.loginReducer.subDeptName,
  designationList: state.loginReducer.designationList,
  exportList: state.loginReducer.exportList,
  exportListLoading: state.loginReducer.exportListLoading,
  getProfileImage: state.PayrollReducer.getProfileImage,
  postProfileImage: state.PayrollReducer.postProfileImage,
  locationList: state.loginReducer.locationList,
  geoFenceCordinate: state.loginReducer.geoFenceCordinate,
});
export default connect(mapStateToProps, {
  getEmployeeList,
  getOrganizationsList,
  getEmployeeDepartment,
  editEmployeeById,
  PostAssignManage,
  getEmployeeALlData,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
  getUserProfileImage,
  updateImage,
  getLocationList,
  UpdateEmployeeWorkLocation,
  getGeoFenceCordinateList,
  CreateGeoFenceCordinate,
  updateGeoLocation,
})(CreateEmployeetTablePage);
