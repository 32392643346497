import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  TableContainer,
  withStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TableRow from "@material-ui/core/TableRow";
import { Slider } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import "./index.sass";
import {
  getEmployeeList,
  createEmployee,
  editEmployeeById,
  getEmployeeDepartment,
  getOrganizationsList,
  PostAssignManage,
  getEmployeeALlData,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
  getLocationList,
  getGeoFenceCordinateList,
  getEmployeeStateName
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import { generateEmployeeFilter } from "../../../helper/generate";
import Backdrop from "@mui/material/Backdrop";
import { ACTIONS } from "../../../interfaces/actionTypes/loginTypes";
import { getUserProfileImage, updateImage } from "../../actions/PayrollActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      overflow:"hidden",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      position: "absolute",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      position: "absolute",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getEmployeeList: any;
  employeesList: any;
  createEmployee: any;
  editEmployeeById: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  empObj: any;
  getOrganizationsList: any;
  organizationsList: any;
  PostAssignManage: any;
  getEmployeeALlData: any;
  employee_all_data: any;
  getEmployeeMode: any;
  get_Employee_Mode: any;
  getReportingManager: any;
  reporting_manager: any;
  loading: any;
  getShiftList: any;
  shiftList: any;
  getEmployeeSubDept: any;
  subDeptName: any;
  getDesignationtList: any;
  designationList: any;
  getExportEmployeeList: any;
  exportList: any;
  getUserProfileImage: any;
  getProfileImage: any;
  updateImage: any;
  postProfileImage: any;
  locationList: any;
  getLocationList: any;
  getGeoFenceCordinateList: any;
  exportListLoading: any;
  geoFenceCordinate: any;
  getEmployeeStateName:any,
  employeeStateName:any,
}
const CreateEmployeetTablePage: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  createEmployee,
  editEmployeeById,
  getEmployeeDepartment,
  departmentNames,
  getOrganizationsList,
  organizationsList,
  PostAssignManage,
  empObj,
  employee_all_data,
  getEmployeeALlData,
  getEmployeeMode,
  get_Employee_Mode,
  getReportingManager,
  reporting_manager,
  loading,
  getShiftList,
  shiftList,
  getEmployeeSubDept,
  subDeptName,
  getDesignationtList,
  designationList,
  getExportEmployeeList,
  exportList,
  getUserProfileImage,
  getProfileImage,
  updateImage,
  postProfileImage,
  locationList,
  getLocationList,
  getGeoFenceCordinateList,
  geoFenceCordinate,
  exportListLoading,
  getEmployeeStateName,
  employeeStateName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [employeeEditopen, setEmployeeEditopen] = React.useState(false);
  const history = useHistory();
  ///Employee Create Page
  const [employeeId, setEmployeeId] = useState<any>("");
  const [employeeName, setEmployeeName] = useState<any>("");
  const [employeeEmergencyContactNumber, setEmployeeEmergencyContactNumber] =
    useState<any>("");
  const [employeeEmail, setEmployeeEmail] = useState<any>("");
  const [employeeGender, setEmployeeGender] = useState<any>("");
  const [employeeDesignationId, setEmployeeDesignationId] = useState<any>("");
  const [employeeDesignation, setEmployeeDesignation] = useState<any>("");
  const [employeeAge, setEmployeeAge] = useState<any>("");
  const [addressOfEmployee, setAddressOfEmployee] = useState<any>("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [esiNumber, setEsiNumber] = React.useState("");
  const [userGroup, setUserGroup] = useState<any>("");
  const [pfNumber, setPfNumber] = React.useState("");
  const [aadhar, setAadhar] = useState<any>("");
  const [panNumber, setPanNumber] = useState<any>("");
  const [employeeMode, setEMployeeMode] = useState<any>("");
  const [employeeModeId, setEmployeeModeId] = useState<any>("");
  const [employeeStatus, setEmployeeStatus] = useState<any>("");
  const [active_employee, setActive_employee] = useState<any>("");
  const [joinedOn, setJoinedOn] = useState<any>("");
  const [currEmployeeType, setCurrEmployeeType] = useState<any>("");
  const [employeeTitle, setEmployeeTitle] = useState<any>("");
  const [employeeLocation, setEmployeeLocation] = useState<any>("");
  const [searchByCode, setSearchByCode] = useState<any>("");
  const [employeeAttendanceMode, setEmployeeAttendanceMode] =
    useState<any>("geo-fencing");
  const [bankName, setBankName] = useState<any>("");
  const [bandName, setbandName] = useState<any>("");
  const [domain, setDomainName] = useState<any>("");
  
  const [employeeCurrentState, setEmployeeCurrentState] = useState<any>("");
  const [uanNumber, setUanNumber] = useState<any>("");
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [deptName, setDeptName] = useState<any>("");
  const [employeeFatherName, setEmployeeFatherName] = useState<any>("");
  const [employeeDob, setEmployeeDob] = useState<any>("");
  const [employeeManagerName, setEmployeeManagerName] = useState<any>("");
  const [employeeManagerUUId, setEmployeeManagerUUId] = useState<any>("");
  const [employeeManagerId, setEmployeeManagerId] = useState<any>("");
  const [managerCode, setManagerCode] = useState<any>("");
  const [managerEmpNumber, setManagerEmpNumber] = useState<any>("");
  const [managerEmailId, setManagerEmailId] = useState<any>("");
  const [departmentHead, setDepartmentHead] = useState<any>("");
  const [deprHeadSearch, setDeptHeadSearch] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [companyName, setCompanyName] = useState<any>("");
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [createBtn, setCreateBtn] = useState<boolean>(false);
  const [DateOfResignation, setdateOfResignation] = useState<any>("");
  const [DateOfLeaving, setDateOfLeaving] = useState<any>("");
  const [ResonOfLeaving, setResonOfLeaving] = useState<any>("");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [checkedWay, setCheckedWay] = useState<boolean>();
  const [checkedMrr, setCheckedMrr] = useState<any>("");
  const [managerId, setManagerId] = useState<any>("");
  const [emplManagerId, setEmplManagerId] = useState<any>("");
  const [confirmedDate, setConfirmedDate] = useState<any>("");
  const [probationDate, setProbationDate] = useState<any>("");
  const [WeekOffDays, setWeekOffDays] = useState<any>("");
  const [PreviousWeekOffDays, setPreviousWeekOffDays] = useState<any>([]);
  const [weekOff, setWeekOff] = useState<any>("");
  const [shiftListData, setshiftListData] = useState<any>("");
  const [userShiftTime, setUserShiftTime] = useState<any>("");
  const [inactiveDate, setInactiveDate] = useState<any>("");
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [subDepartmentId, setSubDepartmentId] = useState<any>("");
  const [FilterBtn, setFilterBtn] = useState<any>(false);
  const [EmployeePunchInPuchOutLocation, setEmployeePunchInPuchOutLocation] =
    useState<any>([]);
  const [employeeWorkLocation, setEmployeeWorkLocation] = useState<any>([]);
  const [EmployeeWorkLocationId, setEmployeeWorkLocationId] = useState<any>([]);
  const [bioId, setBioId] = useState<string>("");

  useEffect(() => {
    getEmployeeList("get_all=true");
    getEmployeeALlData("");
    getEmployeeDepartment("");
    getOrganizationsList("");
    getEmployeeMode();
    getReportingManager(`is_manager=true`);
    getShiftList();
    getEmployeeSubDept("");
    getDesignationtList();
    getLocationList("");
    getGeoFenceCordinateList("");
    getEmployeeStateName("");
  }, []);

  const handleCreateEmployee = async () => {
    const body = {
      // username: employeeEmail,
      employee_id: employeeId,
      employee_designation: employeeDesignationId
        ? employeeDesignationId
        : designationList.find(
            (item: any) => item.designation === employeeDesignation
          )?.id || "",
      manager: employeeManagerUUId,
      manager_email_id: managerEmailId,
      fullname: employeeName,
      name: employeeName,
      gender: employeeGender?.toLowerCase(),
      phonenumber: employeePhoneNumber,
      emergency_contact_number: employeeEmergencyContactNumber,
      contact_number: employeePhoneNumber,
      email: employeeEmail,
      employee_mode: employeeModeId
        ? employeeModeId
        : get_Employee_Mode.find((item: any) => item.mode === employeeMode)
            ?.id || "",
      bank_account_number: accountNumber,
      ifsc_code: ifscCode,
      esi_number: esiNumber,
      pf_number: pfNumber,
      pan_number: panNumber,
      aadhar_number: aadhar,
      location: employeeLocation,
      employee_title: employeeTitle,
      attendance_mode: employeeAttendanceMode,
      band: bandName,
      domain: domain,
      employee_current_state: employeeCurrentState,
      uan_number: uanNumber,
      status: employeeStatus,
      bank_name: bankName,
      department: department
        ? department
        : departmentNames.find((item: any) => item.name === deptName)?.id || "",
      department_head: departmentHead,
      father_name: employeeFatherName,
      date_of_birth: employeeDob,
      organization: company
        ? company
        : organizationsList?.find((item: any) => item.name === companyName)
            ?.id || "",
      sub_department: subDepartmentId
        ? subDepartmentId
        : subDeptName.find((item: any) => item.name === subDepartment)?.id ||
          "",
      is_manager: checkedWay,
      manager_name: employeeManagerName,
      manager_id: managerEmpNumber,
      date_of_joining: joinedOn,
      usergroup: userGroup,
      probation_date: probationDate,
      confirmed_date: confirmedDate,
      inactive_date: inactiveDate,
      weekly_off_days: WeekOffDays,
      shift: shiftListData,
      resignation_date: DateOfResignation,
      leaving_date: DateOfLeaving,
      reason_of_leaving: ResonOfLeaving,
      employee_work_location: EmployeeWorkLocationId,
      bio_id : bioId,
    };

    if (probationDate === "") {
      delete body.probation_date;
    }

    if (confirmedDate === "") {
      delete body.confirmed_date;
    }
    if (employeeManagerName === "") {
      delete body.manager_name;
    }

    if (DateOfLeaving === "") {
      delete body.leaving_date;
    }
    if (shiftListData === "") {
      delete body.shift;
    }

    if (DateOfResignation === "") {
      delete body.resignation_date;
    }
    await editEmployeeById(body, employeeUUID);
    handleResetFunction();
    setEmployeeEditopen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = employeesList.links && employeesList.links.next.split("?")[1];

      getEmployeeList(`${url}`);
    } else if (newPage < page) {
      let url =
        employeesList.links && employeesList.links.previous.split("?")[1];

      getEmployeeList(`${url}`);
    }
    setPage(newPage as number);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(e.target.value);
  };
  

  const handleEditFunction = (data: any) => {
    setEmployeeName(data?.fullname);
    setEmployeeUUID(data.uuid);
    setEmployeeId(data?.employee_id);
    setEmployeeStatus(data?.status);
    setJoinedOn(data?.date_of_joining);
    setCurrEmployeeType(data?.employee_mode !== null ? data.employee_mode : "");
    setEmployeeTitle(data?.employee_title);
    setEmployeeDesignation(data?.employee_designation);
    setEmployeeLocation(data?.location);
    setEmployeePhoneNumber(data?.contact_number);
    setEmployeeEmergencyContactNumber(data?.emergency_contact_number);
    setEmployeeEmail(data?.email);
    setEMployeeMode(data?.employee_mode);
    setAddressOfEmployee(data?.address);
    setAccountNumber(data?.bank_account_number);
    setIfscCode(data?.ifsc_code);
    setEsiNumber(data?.esi_number);
    setUserGroup(data?.usergroup);
    setUanNumber(data?.uan_number);
    setPfNumber(data?.pf_number);
    setAadhar(data?.aadhar_number);
    setPanNumber(data?.pan_number);
    setEmployeeAttendanceMode(data?.attendance_mode);
    setBankName(data?.bank_name);
    setbandName(data?.band);
    setDomainName(data?.domain);
    setEmployeeCurrentState(data?.employee_current_state);
    setDeptName(data?.department);
    setEmployeeFatherName(data?.father_name);
    setEmployeeDob(data.date_of_birth);
    setEmployeeManagerName(data?.manager_name);
    setEmployeeManagerUUId(data?.manager);
    setManagerEmpNumber(data?.manager_id);
    setEmplManagerId(data?.manager_id);
    setManagerEmailId(data?.manager_email);
    setDepartmentHead(data?.department_head);
    setSubDepartment(data?.sub_department);
    setCreateBtn(data?.create_btn);
    setCheckedWay(data?.is_manager);
    setCheckedMrr(data?.is_mrr);
    setEmployeeGender(data?.gender);
    setCompanyName(data?.organization);
    setInactiveDate(data?.inactive_date);
    setdateOfResignation(data?.resignation_date);
    setUserShiftTime(data?.shift);
    setWeekOffDays(data?.weekly_off_days);
    setBioId(data?.bio_id);
    if (data.employee_work_location) {
      setEmployeeWorkLocation(data.employee_work_location);
      setEmployeeWorkLocationId(
        data.employee_work_location.map((item: any) => item.id)
      );
    }
    const formattedWeekOffDays = data.weekly_off_days.map((day: any) => ({
      text: day,
      value: day,
    }));
    setPreviousWeekOffDays(formattedWeekOffDays);
    setResonOfLeaving(data?.reason_of_leaving);
    setDateOfLeaving(data?.leaving_date);
  };
  const handleResetFunction = () => {
    history.push("/dashboard/payrollManager/employee-list");
  };
  console.log(PreviousWeekOffDays, "PreviousWeekOffDays");

  const handlFilterEmployee = () => {
    setFilterBtn(true);
    const body: any = {
      employee_id: employeeId,
      department: employeeDept != 64 ? employeeDept : "",
      manager: employeeManagerId,
      employee_status: employeeStatus,
      active_employee: active_employee,
    };
    const url = generateEmployeeFilter(body).substring(2);
    getEmployeeList(`get_all=true&${url}`);
    getExportEmployeeList(`get_all=true&export=true&${url}`);
    setPage(0);
  };

  const checkedLead = (event: any, leadId: number) => {
    if (selectedLeads?.length < 10) {
      !event.target.checked
        ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
        : setSelectedLeads((prev: any) => [...prev, leadId]);
    } else {
      return false;
    }
  };
  const HandleAssignManager = async () => {
    let body = {
      employee: selectedLeads.join(", "),
      manager: managerId,
    };
    await PostAssignManage(body);
    setSelectedLeads([]);
    getEmployeeList("get_all=true");
    history.push("/dashboard/payrollManager/employee-list");
  };
  const exportData = () => {
    var rows = [];
    let row = exportList;
    rows.push([
      "Employee ID",
      "Employee Name	",
      "Date of Joining ",
      "Employee Type",
      "Employee Department",
      "Employee Designation",
      "Employee Location",
      "Employee Domain",
      "Employee Dob",
      "Employee Age",
      "Employee Gender",
      "Employee Manager Id",
      "Employee Manager Name",
      "Employee Attendance Mode",
      "Employee email",
      "Phone Number",
      "Employee Status",
      "Bio Id",
      "Employee Bank Name",
      "Employee Bank AC Number",
      "Employee IFSC Code",
      "Employee User Group",
      "Employee Shift Time",
      "State",
      "Employee Weekof ",
    ]);

    for (var i = 0; i < row.length; i++) {
      rows.push([
        row[i]?.employee_id,
        row[i]?.name,
        row[i]?.date_of_joining,
        row[i]?.employee_mode,
        row[i]?.department,
        `"${row[i]?.employee_designation}"`,
        row[i]?.location,
        row[i]?.domain,
        row[i]?.date_of_birth,
        row[i]?.age,
        row[i]?.gender,
        row[i]?.manager_id,
        row[i]?.manager_name,
        row[i]?.attendance_mode,
        row[i]?.email,
        row[i]?.phonenumber,
        row[i]?.status,
        row[i]?.bio_id,
        row[i]?.bank_name,
        row[i]?.bank_account_number,
        row[i]?.ifsc_code,
        row[i]?.usergroup,
        row[i]?.shift,
        row[i]?.state ? row[i]?.state : "NA",
        row[i]?.weekly_off_days.length > 0 ? row[i]?.weekly_off_days.join(", ") : "", 
      ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
      row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "EmployeeList.csv");
    document.body.appendChild(link);

    link.click();
    dispatch({ type: ACTIONS.GET_EXPORT_EMPLOYEE_LIST, payload: [] });
  };

  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [editorOpen, setEditorOpen] = useState<any>(false);
  const [scale, setScale] = useState<any>(1);
  const editorRef = useRef<any>(null);
  const [hoverState, setHoverState] = useState<{ [key: string]: boolean }>({});

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setImageUrl(reader.result);
        setEditorOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showEditIcon, setShowEditIcon] = useState(false);

  const handleSave = (uuid: any, employee_id: any) => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      setImageUrl(canvas.toDataURL());
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          const file = new File([blob], "profile-image.png", {
            type: "image/png",
          });
          setImage(file);
          console.log("File:", file);
        }
      }, "image/png");
      setEditorOpen(false);
      handleUploadImage(uuid);
      getEmployeeList(`employee_id=${employee_id}`);
      // history.push("/dashboard/payrollManager/employee-list")
    }
  };

  const handleUploadImage = (uuid: any) => {
    const formData = new FormData();
    formData.append("profile_picture", image);
    console.log(image, "file1");
    updateImage({ id: uuid, data: formData });
  };

  const handleAutocompleteChange = (event: any, newValue: any) => {
    if (newValue) {
      let obj = JSON.parse(JSON.stringify(newValue, null, ""));
      setshiftListData(obj.id);
      setUserShiftTime(`Total Hours: ${obj.total_hour}`);
    }
  };

  console.log(exportListLoading, "exportListLoading", exportList);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            backgroundColor: "rgb(28, 78, 128)",
            color: "#fff",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "10px",
            borderRadius: "15px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          Employee List
        </h4>
        <Grid
          container
          spacing={3}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeId(obj.employee_id);
                } else {
                  setEmployeeId("");
                }
              }}
              id="checkboxes-tags-demo"
              options={employee_all_data?.results || []}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeALlData(`employee_id=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Employee ID Or Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeDept(obj.id);
                  setDeptHeadSearch(obj.department_head_name);
                } else {
                  setEmployeeDept("");
                }
              }}
              id="department-name"
              options={departmentNames || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeDepartment(`name=${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              renderOption={(option: any) => {
                return option?.name;
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Department Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeManagerId(obj?.employee_id);
                } else {
                  setEmployeeManagerId("");
                }
              }}
              id="checkboxes-tags-demo"
              options={employee_all_data?.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeALlData(`employee_id=${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Manager Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ticket_status"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setEmployeeStatus(obj?.value);
                } else {
                  setEmployeeStatus("");
                }
              }}
              options={[
                {
                  text: "In Active",
                  value: "inactive",
                },
              ]}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return employeeStatus.includes(option.value);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="In Active Employee"
                  variant="outlined"
                  value={employeeStatus}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ticket_status"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setActive_employee(obj?.value);
                } else {
                  setActive_employee("");
                }
              }}
              options={[
                {
                  text: "Active",
                  value: "true",
                },
              ]}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return active_employee.includes(option.value);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Active Employee  "
                  variant="outlined"
                  value={active_employee}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              // disabled={employeeId === ""}
              style={{
                backgroundColor: "#1976d2",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onClick={handlFilterEmployee}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#1565c0";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#1976d2";
                e.currentTarget.style.boxShadow = "none";
              }}
              startIcon={<SearchIcon />}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<RotateLeftIcon />}
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
              onClick={() => handleResetFunction()}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <LoadingButton
              variant="contained"
              fullWidth
              disabled={!FilterBtn || exportListLoading}
              style={{
                backgroundColor:
                  !FilterBtn || exportListLoading ? "gray" : "#009688",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                if (!exportListLoading) {
                  e.currentTarget.style.backgroundColor = "#7b1fa2";
                  e.currentTarget.style.boxShadow =
                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                }
              }}
              onMouseOut={(e) => {
                if (!exportListLoading) {
                  e.currentTarget.style.backgroundColor =
                    !FilterBtn || exportListLoading ? "gray" : "#9c27b0";
                  e.currentTarget.style.boxShadow = "none";
                }
              }}
              loading={exportListLoading}
              loadingPosition="start"
              startIcon={exportListLoading ? null : <ArrowCircleDownIcon />}
              onClick={exportData}
            >
              {exportListLoading ? (
                <span>
                  Preparing data... <br />
                  <small>Please wait, we are preparing data for you.</small>
                </span>
              ) : (
                "Download Employee List"
              )}
            </LoadingButton>
          </Grid>
        </Grid>
        <div
          style={{
            padding: "10px",
            marginTop: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            backgroundColor: "#f8d7da",
            display: "inline-block",
            fontSize: "16px",
            color: "#721c24",
          }}
        >
          <span>
            👉 Please click on the filter button to enable the Download Employee
            List button.
          </span>
        </div>
        <Grid container spacing={2} direction="row">
          {selectedLeads?.length > 0 ? (
            <>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      setManagerId(obj.employee_id);
                    }
                  }}
                  id="checkboxes-tags-demo"
                  options={employee_all_data?.results || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getEmployeeALlData(`employee_id=${newInputValue}`);
                    }, 1000);
                  }}
                  getOptionLabel={(option: any) => {
                    return option?.employee_id + " " + option.name;
                  }}
                  renderOption={(option: any) => {
                    return (
                      <p>
                        <b style={{ color: "blue" }}>Employee Name:</b>
                        <strong>
                          {option?.name.toString()}
                          <br />
                        </strong>
                        <b style={{ color: "red" }}>Employee Id:</b>
                        {option?.employee_id?.toUpperCase()}
                      </p>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Manage Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={HandleAssignManager}
                >
                  Assign
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleResetFunction}
                >
                  Reset
                </Button>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">
                  <input
                    type="checkbox"
                    className="input"
                    name="selectall"
                    id="selectall"
                    onChange={(e) =>
                      e.target.checked
                        ? setSelectedLeads(
                            employeesList?.results &&
                              employeesList?.results.map(
                                (data: any, index: number) => {
                                  if (index < 100) {
                                    return data?.employee_id;
                                  }
                                }
                              )
                          )
                        : setSelectedLeads([])
                    }
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ width: "fit-content" }}
                >
                  Profile
                </StyledTableCell>
                <StyledTableCell align="center">Edit Employee</StyledTableCell>
                <StyledTableCell align="center">Employee Name</StyledTableCell>
                <StyledTableCell align="center">Employee ID</StyledTableCell>
                <StyledTableCell align="center">Employee Age</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Gender
                </StyledTableCell>
                <StyledTableCell align="center">
                  Employee Designation
                </StyledTableCell>
                <StyledTableCell align="center">Employee email</StyledTableCell>
                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Department{" "}
                </StyledTableCell>
                <StyledTableCell align="center">Joining Date</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Status
                </StyledTableCell>
                <StyledTableCell align="center">
                  Bio Id
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody>
                  {employeesList &&
                    employeesList?.results?.length > 0 &&
                    employeesList?.results?.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            <input
                              type="checkbox"
                              name="selectall"
                              id="selectall"
                              checked={selectedLeads.find(
                                (qa: any) => qa === data.employee_id
                              )}
                              onChange={(e) => checkedLead(e, data.employee_id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div style={{ padding: "0px 60px" }}>
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="upload-button"
                                onChange={handleImageChange}
                              />
                              <label
                                htmlFor="upload-button"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                onMouseEnter={() =>
                                  setHoverState({ [data.uuid]: true })
                                }
                                onMouseLeave={() =>
                                  setHoverState({ [data.uuid]: false })
                                }
                              >
                                <Avatar
                                  key={data.id}
                                  // alt="User Avatar"
                                  src={
                                    data.profile_picture
                                      ? data.profile_picture
                                      : "/static/images/avatar/1.jpg"
                                  }
                                  style={{
                                    margin: "2px auto",
                                    backgroundColor: "rgb(14, 63, 108)",
                                    cursor: "pointer",
                                    border: "1px solid black",
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                                {hoverState[data.uuid] && (
                                  <Tooltip title="Edit" key={data.uuid}>
                                    <IconButton
                                      component="span"
                                      style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        transform: "translate(25%, 25%)",
                                        backgroundColor: "white",
                                        padding: "2px",
                                        height: "30px",
                                        width: "30px",
                                        transition:
                                          "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                                        opacity: hoverState[data.uuid] ? 1 : 0,
                                      }}
                                      className="edit-icon"
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </label>

                              <Dialog
                                open={editorOpen}
                                onClose={() => setEditorOpen(false)}
                              >
                                <div style={{ padding: "20px" }}>
                                  {imageUrl && (
                                    <AvatarEditor
                                      ref={editorRef}
                                      image={imageUrl}
                                      width={200}
                                      height={200}
                                      borderRadius={100}
                                      border={50}
                                      scale={scale}
                                    />
                                  )}
                                  <Slider
                                    value={scale}
                                    min={1}
                                    max={3}
                                    step={0.01}
                                    onChange={(e: any, newValue: any) =>
                                      setScale(newValue)
                                    }
                                  />
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleSave(data?.uuid, data.employee_id)
                                    }
                                  >
                                    Upload
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => setEditorOpen(false)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Dialog>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleEditFunction(data);
                                  setEmployeeEditopen(true);
                                }}
                                startIcon={<EditLocationAltIcon />}
                                style={{
                                  borderRadius: "8px",
                                  padding: "8px 16px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "none",
                                  backgroundColor: "#155a8a", // Blue color
                                  color: "#ffffff",
                                  transition:
                                    "background-color 0.3s, box-shadow 0.3s",
                                  boxShadow: "none",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#1976d2"; // Darker blue on hover
                                  e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#155a8a";
                                  e.currentTarget.style.boxShadow = "none";
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.age ? data?.age : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.gender ? data?.gender : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_designation
                              ? data?.employee_designation
                              : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.email ? data?.email : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.phonenumber ? data?.phonenumber : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.department ? data?.department : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.date_of_joining
                              ? data?.date_of_joining
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.status ? data?.status : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.bio_id ? data?.bio_id : "NA"}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </>
            )}

            <TableFooter>
              <StyledTableRow style={{ textAlign: "center" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={6}
                  count={employeesList?.count || 0}
                  rowsPerPage={employeesList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={employeeEditopen}
          onClose={() => setEmployeeEditopen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={employeeEditopen}>
            <div className={classes.paper1}>
              <h3
                style={{
                  color: "#0e3f6c",
                }}
              >
                {" "}
                Edit Employee Master
              </h3>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="flex-start"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_id"
                    label="Employee Id"
                    value={employeeId}
                    onChange={(e) => {
                      setEmployeeId(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_name"
                    type="text"
                    label="Employee Name "
                    value={employeeName}
                    required
                    variant="outlined"
                    onChange={(e) => setEmployeeName(e.target.value as string)}
                    inputProps={{ maxLength: 100 }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="select"
                    select
                    fullWidth
                    variant="outlined"
                    label="Employee Status*"
                    value={employeeStatus}
                    onChange={(e: any) => setEmployeeStatus(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        padding: "0 5px",
                        background: "none",
                      },
                    }}
                  >
                    <MenuItem value="confirmed">Confirmed</MenuItem>
                    <MenuItem value="probation">Probation</MenuItem>
                    <MenuItem value="inactive">In Active</MenuItem>
                  </TextField>
                </Grid>
                {employeeStatus === "probation" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="probation_date"
                      label="Probation Date"
                      type="date"
                      value={(probationDate && probationDate.toString()) || ""}
                      onChange={(e) => setProbationDate(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      inputProps={{
                        max: `${new Date().getFullYear()}-${
                          new Date().getMonth() + 1 < 10
                            ? `0${new Date().getMonth() + 1}`
                            : new Date().getMonth() + 1
                        }-${
                          new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : new Date().getDate()
                        }`,
                      }}
                    />
                  </Grid>
                )}
                {employeeStatus === "confirmed" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="confirmed_date"
                      label="Confirmed Date"
                      type="date"
                      value={(confirmedDate && confirmedDate.toString()) || ""}
                      onChange={(e) => setConfirmedDate(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      inputProps={{
                        max: `${new Date().getFullYear()}-${
                          new Date().getMonth() + 1 < 10
                            ? `0${new Date().getMonth() + 1}`
                            : new Date().getMonth() + 1
                        }-${
                          new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : new Date().getDate()
                        }`,
                      }}
                    />
                  </Grid>
                )}
                {employeeStatus === "inactive" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      className="input"
                      name="Inactive_date"
                      label="Inactive Date"
                      type="date"
                      value={(inactiveDate && inactiveDate.toString()) || ""}
                      onChange={(e) => setInactiveDate(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      inputProps={{
                        max: `${new Date().getFullYear()}-${
                          new Date().getMonth() + 1 < 10
                            ? `0${new Date().getMonth() + 1}`
                            : new Date().getMonth() + 1
                        }-${
                          new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : new Date().getDate()
                        }`,
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="joined_on"
                    label="joined on"
                    type="date"
                    value={(joinedOn && joinedOn.toString()) || ""}
                    onChange={(e) => setJoinedOn(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{
                      max: `${new Date().getFullYear()}-${
                        new Date().getMonth() + 1 < 10
                          ? `0${new Date().getMonth() + 1}`
                          : new Date().getMonth() + 1
                      }-${
                        new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                      }`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="employee_type"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEMployeeMode(obj?.mode);
                        setEmployeeModeId(obj?.id);
                      }
                    }}
                    options={get_Employee_Mode || []}
                    onInputChange={(event, newValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeMode(`name=${newValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      option?.mode ? option?.mode : ""
                    }
                    getOptionDisabled={(option: any) => {
                      return get_Employee_Mode.includes(option?.mode);
                    }}
                    blurOnSelect
                    aria-required
                    freeSolo
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={{ mode: employeeMode }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Type "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="empTitle"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEmployeeTitle(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "MR",
                        value: "mr",
                      },
                      {
                        text: "MRS",
                        value: "mrs",
                      },
                      {
                        text: "MS",
                        value: "ms",
                      },
                      {
                        text: "MISS",
                        value: "miss",
                      },
                      {
                        text: "Dr",
                        value: "dr",
                      },
                      {
                        text: "Professor",
                        value: "prof",
                      },
                      {
                        text: "Advocate",
                        value: "adv",
                      },
                    ]}
                    blurOnSelect
                    aria-required
                    freeSolo
                    onInputChange={(event, newValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        setEmployeeTitle(newValue);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => option.text}
                    // getOptionDisabled={(option: any) => {
                    //   return employeeTitle.includes(option.value);
                    // }}
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={{ text: employeeTitle }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Title "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    key={deptName}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDepartment(obj?.id);
                        setDeptName(obj?.name);
                      }
                    }}
                    id="department-name"
                    options={departmentNames || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeDepartment(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      option?.name ? option?.name : ""
                    }
                    defaultValue={{ name: deptName }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Department Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEmployeeDesignationId(obj.id);
                        setEmployeeDesignation(obj.name);
                      }
                    }}
                    id="department-name"
                    options={designationList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getDesignationtList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.designation;
                    }}
                    renderOption={(option: any) => {
                      return option?.designation;
                    }}
                    defaultValue={{ designation: employeeDesignation }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Designation "
                        variant="outlined"
                        required
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        setEmployeeLocation(obj.location);    
                      }
                    }}
                    id="sub-department-name"
                    defaultValue={{ location: employeeLocation}}
                    options={locationList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getLocationList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.location;
                    }}
                    renderOption={(option: any) => {
                      return option.location;
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Location"
                        required
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    required
                    className="input"
                    name="employee_phone_number"
                    type="text"
                    label="Contact Number"
                    value={employeePhoneNumber}
                    onChange={(e) => {
                      setEmployeePhoneNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        setEmployeeCurrentState(obj.name);
                      }
                    }}
                    id="sub-department-name"
                    defaultValue={{ name: employeeCurrentState }}
                    options={employeeStateName || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeStateName(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.name;
                    }}
                    renderOption={(option: any) => {
                      return option?.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="State Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="email"
                    type="email"
                    value={employeeEmail}
                    className="input"
                    label="Email Id"
                    variant="outlined"
                    onChange={(e) =>
                      setEmployeeEmail(
                        e.target.value
                          .trimStart()
                          .replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "")
                      )
                    }
                    inputProps={{ maxLength: 50 }}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="email"
                    type="text"
                    defaultValue={employeeFatherName || ""}
                    className="input"
                    label="Father Name"
                    variant="outlined"
                    onChange={(e) =>
                      setEmployeeFatherName(e.target.value as string)
                    }
                    inputProps={{ maxLength: 50 }}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="gender"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEmployeeGender(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "Male",
                        value: "male",
                      },
                      {
                        text: "Female",
                        value: "female",
                      },
                      {
                        text: "Other",
                        value: "other",
                      },
                    ]}
                    getOptionLabel={(option: any) => option.text}
                    freeSolo
                    blurOnSelect
                    aria-required
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={{ text: employeeGender }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Gender "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_dob"
                    label="employee dob"
                    type="date"
                    value={(employeeDob && employeeDob.toString()) || ""}
                    onChange={(e) => setEmployeeDob(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{
                      max: `${new Date().getFullYear()}-${
                        new Date().getMonth() + 1 < 10
                          ? `0${new Date().getMonth() + 1}`
                          : new Date().getMonth() + 1
                      }-${
                        new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                      }`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        setManagerEmpNumber(obj?.employee_id);
                        setEmplManagerId(obj?.employee_id);
                        setEmployeeManagerName(obj?.name);
                        setEmployeeManagerUUId(obj?.uuid);
                        setManagerEmailId(obj.email)
                      }
                    }}
                    id="reporting-manager"
                    options={reporting_manager?.results || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getReportingManager(`is_manager=true&employee_id=${newInputValue}`);
                      }, 1000);
                    }}
                    // getOptionLabel={(option: any) => {
                    //   return employeeManagerName && emplManagerId
                    //     ? employeeManagerName
                    //     : option?.employee_id + " " + option.name;
                    // }}
                    getOptionLabel={(option: any) => {
                      return option
                        ? option?.employee_id + " " + option.name
                        : employeeManagerName && emplManagerId
                        ? emplManagerId + "" + employeeManagerName
                        : "";
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}>Manager Name:</b>
                          <strong>
                            {option?.name.toString()}
                            <br />
                          </strong>
                          <b style={{ color: "red" }}>Manager Id:</b>
                          {option?.employee_id?.toUpperCase()}
                        </p>
                      );
                    }}
                    defaultValue={
                      (reporting_manager &&
                        reporting_manager?.results?.map(
                          (opt: any) => opt?.employee_id === emplManagerId
                        )?.name) ||
                      ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        label="Manager Name"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="manager_emp_number"
                    type="text"
                    label=" Manager Employee ID"
                    disabled
                    value={managerEmpNumber}
                    onChange={(e) => {
                      setManagerEmpNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="manager_email_id"
                    type="text"
                    label="Manager Email id"
                    value={managerEmailId}
                    onChange={(e) =>
                      setManagerEmailId(e.target.value as string)
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="dept_head"
                    type="text"
                    label="Department Head "
                    defaultValue={departmentHead ? departmentHead : ""}
                    onChange={(e) =>
                      setDepartmentHead(e.target.value as string)
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_emergency_number"
                    type="text"
                    label="Emergency Contact Number"
                    value={employeeEmergencyContactNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setEmployeeEmergencyContactNumber(value);
                      }
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="uan_number"
                    type="text"
                    value={uanNumber}
                    className="input"
                    label="UAN Number"
                    onChange={(e) => {
                      setUanNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="gender"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setEmployeeAttendanceMode(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "Geo-fencing",
                        value: "Geo-fencing",
                      },
                      {
                        text: "Flexible",
                        value: "Flexible",
                      },
                      {
                        text: "Biometric",
                        value: "Biometric",
                      },
                      {
                        text: "Manual",
                        value: "Manual",
                      },
                    ]}
                    blurOnSelect
                    aria-required
                    freeSolo
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return employeeAttendanceMode.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={{ text: employeeAttendanceMode }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Attendance Mode"
                        variant="outlined"
                        required
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="gender"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDomainName(obj?.value)
                      }
                    }}
                    options={[
                      {
                        text: "Diagnostics",
                        value: "diagnostics",
                      },
                      {
                        text: "Research",
                        value: "research",
                      },
                      {
                        text: "Corporate",
                        value: "corporate",
                      },
                      {
                        text: "Genomics",
                        value: "genomics",
                      },
                      {
                        text: "Strategic Alliance",
                        value: "strategicAlliance",
                      },
                      {
                        text: "Redo",
                        value: "redo",
                      },
                      {
                        text: "Radiology",
                        value: "radiology",
                      },
                    ]}
                    blurOnSelect
                    aria-required
                    freeSolo
                    getOptionLabel={(option: any) => option.text}
                    defaultValue={{ text: domain }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Domain Name"
                        variant="outlined"
                        required
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_bank"
                    type="text"
                    label="Bank Name  "
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value as string)}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="accountNumber"
                    type="text"
                    value={accountNumber}
                    className="input"
                    label="Bank Account Number"
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                    inputProps={{ maxlength: 20 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="IFSC"
                    type="text"
                    value={ifscCode}
                    className="input"
                    label="IFSC Code"
                    onChange={(e) => {
                      setIfscCode(e.target.value);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="pan_number"
                    type="text"
                    label="Pan Number"
                    value={panNumber}
                    onChange={(e) => {
                      setPanNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_bond"
                    type="text"
                    label="Band Name  "
                    value={bandName}
                    onChange={(e) => setbandName(e.target.value as string)}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCompany(obj?.id);
                        setCompanyName(obj?.name);
                      }
                    }}
                    id="company-name"
                    options={organizationsList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getOrganizationsList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.name;
                    }}
                    renderOption={(option: any) => {
                      return option?.name;
                    }}
                    defaultValue={{ name: companyName }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Company Name"
                        variant="outlined"
                        required
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, "")
                        );
                        setSubDepartmentId(obj.id);
                        setSubDepartment(obj?.name);
                      }
                    }}
                    id="sub-department-name"
                    options={subDeptName || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeSubDept(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.name;
                    }}
                    renderOption={(option: any) => {
                      return option?.name;
                    }}
                    defaultValue={{ name: subDepartment }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Sub Department"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="esi_number"
                    type="text"
                    value={esiNumber}
                    className="input"
                    label="ESI Number"
                    onChange={(e) => {
                      setEsiNumber(e.target.value);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="employee_type"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setUserGroup(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "AdminManager ",
                        value: "AdminManager",
                      },
                      {
                        text: "PayrollManager",
                        value: "PayrollManager",
                      },
                      {
                        text: "HRManager",
                        value: "HRManager",
                      },
                      {
                        text: "Employee",
                        value: "Employee",
                      },
                    ]}
                    blurOnSelect
                    aria-required
                    freeSolo
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return userGroup.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={{ text: userGroup }}
                    renderInput={(params) => (
                      <TextField
                        required
                        className="input"
                        {...params}
                        label="User Group "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={handleAutocompleteChange}
                    id="shift-list"
                    options={shiftList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getShiftList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return userShiftTime
                        ? userShiftTime
                        : option.start_time + " To " + option.end_time;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}> Shift :</b>
                          <strong>
                            {option?.start_time} To {option.end_time}
                          </strong>
                          <br />
                          <b style={{ color: "red" }}>Total Hours:</b>
                          {option?.total_hour}
                        </p>
                      );
                    }}
                    defaultValue={userShiftTime ? userShiftTime : null}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Shift Time"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="ticket_status"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item.value);
                      }
                      setWeekOffDays(sourceIds);
                    }}
                    options={[
                      {
                        text: "Monday",
                        value: "Monday",
                      },
                      {
                        text: "Tuesday",
                        value: "Tuesday",
                      },
                      {
                        text: "Wednesday",
                        value: "Wednesday",
                      },
                      {
                        text: "Thursday",
                        value: "Thursday",
                      },

                      {
                        text: "Friday",
                        value: "Friday",
                      },
                      {
                        text: "Saturday",
                        value: "Saturday",
                      },
                      {
                        text: "Sunday",
                        value: "Sunday",
                      },
                    ]}
                    freeSolo
                    defaultValue={PreviousWeekOffDays}
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    multiple
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return WeekOffDays.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Week Off "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="date_of_resignation"
                    label="Date Of Resignation"
                    type="date"
                    value={
                      (DateOfResignation && DateOfResignation.toString()) || ""
                    }
                    onChange={(e) => setdateOfResignation(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="date_of_leaving"
                    label="Date Of Leaving"
                    type="date"
                    defaultValue={
                      (DateOfLeaving && DateOfLeaving.toString()) || ""
                    }
                    onChange={(e) => setDateOfLeaving(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="ResonOfLeaving"
                    type="text"
                    defaultValue={ResonOfLeaving || ""}
                    className="input"
                    label="Reason of Leaving"
                    onChange={(e) => {
                      setResonOfLeaving(e.target.value);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="Employee_Punch_In_Puch_Out_Location"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let panelUserArr: any = [];
                        let panelUserId: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          panelUserArr.push(obj);
                          panelUserId.push(obj.id);
                        });
                        setEmployeeWorkLocation(panelUserArr);
                        setEmployeeWorkLocationId(panelUserId);
                      }
                    }}
                    value={employeeWorkLocation}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getGeoFenceCordinateList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    multiple
                    disableClearable
                    disableCloseOnSelect
                    limitTags={1}
                    options={geoFenceCordinate?.results || []}
                    freeSolo
                    blurOnSelect
                    getOptionDisabled={(option: any) => {
                      return EmployeeWorkLocationId.includes(option.id);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.location;
                    }}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          key={index}
                          label={option?.location}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Employee Punch In Puch Out Location"
                        required
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="biod_id"
                    type="text"
                    defaultValue={bioId || ""}
                    className="input"
                    label="Bio Id"
                    onChange={(e) => {
                      setBioId(e.target.value);
                    }}
                    inputProps={{ maxlength: 15 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <div
                    className="checkList"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{
                          fontSize: "large",
                          fontWeight: "bolder",
                          textAlign: "left",
                          marginLeft: "10px",
                        }}
                      >
                        Manager:
                      </FormLabel>
                      <div style={{ display: "flex", paddingLeft: "20px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={"yes"}
                              checked={checkedWay === true}
                              onChange={(e) => {
                                !e.target.checked
                                  ? setCheckedWay(checkedWay)
                                  : setCheckedWay(true);
                              }}
                            />
                          }
                          label="Yes"
                          style={{ marginRight: "20px" }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={"no"}
                              checked={checkedWay === false}
                              onChange={(e) => {
                                !e.target.checked
                                  ? setCheckedWay(checkedWay)
                                  : setCheckedWay(false);
                              }}
                            />
                          }
                          label="No"
                        />
                      </div>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5d4037" }}
                    fullWidth
                    startIcon={<SyncAltIcon />}
                    onClick={handleCreateEmployee}
                    disabled={
                      employeeName === "" &&
                      employeeId === "" &&
                      employeeDept === "" &&
                      employeeEmail === "" &&
                      employeePhoneNumber === ""
                    }
                  >
                    {"Update Employee"}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0e3f6c" }}
                    fullWidth
                    onClick={() => setEmployeeEditopen(false)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  loading: state.loginReducer.loading,
  organizationsList: state.loginReducer.organizationsList,
  departmentNames: state.loginReducer.departmentNames,
  employee_all_data: state.loginReducer.employee_all_data,
  get_Employee_Mode: state.loginReducer.get_Employee_Mode,
  reporting_manager: state.loginReducer.reporting_manager,
  shiftList: state.loginReducer.shiftList,
  subDeptName: state.loginReducer.subDeptName,
  designationList: state.loginReducer.designationList,
  exportList: state.loginReducer.exportList,
  exportListLoading: state.loginReducer.exportListLoading,
  getProfileImage: state.PayrollReducer.getProfileImage,
  postProfileImage: state.PayrollReducer.postProfileImage,
  locationList: state.loginReducer.locationList,
  geoFenceCordinate: state.loginReducer.geoFenceCordinate,
  employeeStateName: state.loginReducer.employeeStateName,
});
export default connect(mapStateToProps, {
  getEmployeeList,
  getOrganizationsList,
  getEmployeeDepartment,
  editEmployeeById,
  PostAssignManage,
  getEmployeeALlData,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
  getUserProfileImage,
  updateImage,
  getLocationList,
  getGeoFenceCordinateList,
  getEmployeeStateName
})(CreateEmployeetTablePage);
