import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import GroupsIcon from '@mui/icons-material/Groups';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AppBar from '@material-ui/core/AppBar';
import MailLockIcon from '@mui/icons-material/MailLock';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import LockResetIcon from '@mui/icons-material/LockReset';
import Toolbar from '@material-ui/core/Toolbar';
import UpdateIcon from '@mui/icons-material/Update';
import List from '@material-ui/core/List';
import Avatar from "@mui/material/Avatar";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DifferenceIcon from '@mui/icons-material/Difference';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { logout, panelUserLogout } from "../../actions/loginActions";
import { getEmployeeData } from '../actions/PayrollActions';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from '@material-ui/core';
import {
  changePassword,
  getUserProfileImage,
  updateImage,
} from '../actions/PayrollActions';
import Popover from '@material-ui/core/Popover';
import { useHistory } from 'react-router';
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { onMessageListener } from "../../firebase";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import "./style.css"
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { useSelector } from "react-redux";
import { Slider } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import {
  resetPassword,
  resetPasswordReset,
  sendOtp,
  sendOtp1,
  resetPassword1,
} from "../../actions/loginActions";
import Logo from "../../celara.jpeg"
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    menuButton: {
      marginRight: 0,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "rgb(14, 63, 108)",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    menuItem: {
      width: "100%",
    },
    menuItemIcon: {
      color: "#97c05c",
    },
    wrapper: {
      // margin: theme.spacing(1),
      position: "relative",
      margin: "2px",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -9,
      marginLeft: -9,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
  }),
);

interface Props {
  notificationMessage: any;
  passwordStatus: boolean;
  getEmployeeData: any;
  employeeData: any;
  getUserProfileImage: any;
  getProfileImage: any;
  updateImage: any;
  postProfileImage: any;
}
const PhlebopayrollManager: React.FC<Props> = ({
  notificationMessage,
  passwordStatus,
  getEmployeeData,
  employeeData,
  getUserProfileImage,
  getProfileImage,
  updateImage,
  postProfileImage,
}
) => {

  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()
  const [open, setOpen] = React.useState(true);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false)
  const [changeEmailModal, setChangeEmailModal] = React.useState(false);
  const [changeMobileNumber, setChangeMobileNumber] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const id = open ? 'transitions-popper' : undefined;

  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [phone, setphone] = useState("");
  const [clickOtp, setClickOtp] = useState<boolean>(false);
  const [forgot, setForgot] = useState<any>(false);
  const sendOtpHandler = () => {
    dispatch(sendOtp1({ phone }));
  };


  useEffect(() => {
    dispatch(resetPasswordReset());
  }, [dispatch]);

  const loginReducer = useSelector((state: any) => state.loginReducer);
  const {
    otp: otpSent,
    success,
    loading: loadingReset,
    loadingOTP: loadingResetOTP,
  } = loginReducer;

  onMessageListener()
    .then((payload) => {

      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

    })
    .catch((err) => console.log("failed: ", err));


  const handleClose = () => {
    setOpenRightMenu(false);
  };

  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true)
    }
  }, [passwordStatus])

  useEffect(() => {
    getEmployeeData();
  }, [])


  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    }
    await dispatch(changePassword(body))
    setchangePasswordModal(false)
    dispatch(logout())

  }

  const updateEmail = async () => {
    // code here
  }

  const updateMobileNumber = async () => {
    // code here
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleClickAvatar = () => {
    setOpenProfileDialog(true);
  };

  const handleCloseProfileDialog = () => {
    setOpenProfileDialog(false);
  };

  const handleLogout = () => {
    dispatch(panelUserLogout())
    dispatch(logout())
  }
  const handleNotification = () => {
    history.push("/dashboard/payrollManager/inbox");
  };


  const resetPasswordHandler = async () => {
    const body: any = {
      otp: otp,
      password: newPassword,
      mobile: phone,
    };
    if (otp && newPassword && phone) {
      dispatch(resetPassword1(body));
    }
    setphone("");
    setNewPassword("");
    setOtp("");
    setForgot(false)
    setchangePasswordModal(false)
  };

  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [editorOpen, setEditorOpen] = useState<any>(false);
  const [scale, setScale] = useState<any>(1);
  const editorRef = useRef<any>(null);
  const [idnew, setIdNew] = useState('');
  const [imageURL2, setImageURL2] = useState<any>("");

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setImageUrl(reader.result);
        setEditorOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (employeeData && employeeData?.results && employeeData?.results[0]) {
      const uuid = employeeData.results[0].uuid;
      setIdNew(uuid);
    }
  }, [employeeData]);

  useEffect(() => {
    if (idnew) {
      getUserProfileImage(`${idnew}`);
    }
  }, [idnew]);

  useEffect(() => {
    if (getProfileImage) {
      setImageURL2(getProfileImage.profile_picture);
    }

  }, [getProfileImage])

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      setImageUrl(canvas.toDataURL())
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          const file = new File([blob], 'profile-image.png', { type: 'image/png' });
          setImage(file);
          console.log('File:', file);
        }
      }, 'image/png');
      setEditorOpen(false);
      handleUploadImage();
      history.push("/dashboard/payrollManager");
    }
  };

  const handleUploadImage = () => {
    const formData = new FormData();
    formData.append('profile_picture', image);
    console.log(image, "file1")
    dispatch(updateImage({ id: idnew, data: formData }));
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "rgb(14, 63, 108)" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
          <img src={Logo} alt="Celara Logo" style={{ height: '43px', marginRight: '8px' }} />
            Celara
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-owns={openPopover ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleNotification}
              color="inherit"
            >
              <CircleNotificationsIcon
                style={{
                  width: 40,
                  height: 40,
                  margin: "0 auto",
                }} />
            </IconButton>
            <Button onClick={handleClickAvatar}>
              {imageURL2 ? (
                <Avatar
                  alt="User Avatar"
                  src={imageURL2 ? imageURL2 : '/static/images/avatar/1.jpg'}
                  style={{
                    backgroundColor: "rgb(189 189 189 / 55%)",
                    cursor: 'pointer',
                    border: "1px solid black",
                  }}
                />
              ) : (
                <Avatar
                  alt="User Avatar"
                  style={{
                    color: "#fff",
                    cursor: 'pointer'
                  }}
                />
              )}
            </Button>
            <Dialog open={openProfileDialog} onClose={handleCloseProfileDialog} style={{ padding: "20px 30px" }}>
              <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>My Profile</span>
              </DialogTitle>
              <DialogContent>
                <div style={{ padding: "0px 60px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="upload-button"
                  />
                  <label htmlFor="upload-button">
                    <Avatar
                      alt="User Avatar"
                      src={imageURL2 || '/static/images/avatar/1.jpg'}
                      style={{
                        backgroundColor: "rgb(14, 63, 108)",
                        width: 110,
                        height: 110,
                        cursor: 'pointer',
                        border: "1px solid black",
                      }}
                    />
                  </label>

                  <Dialog open={editorOpen} onClose={() => setEditorOpen(false)}>
                    <div style={{ padding: '20px' }}>
                      {imageUrl && (
                        <AvatarEditor
                          ref={editorRef}
                          image={imageUrl}
                          width={200}
                          height={200}
                          borderRadius={100}
                          border={50}
                          scale={scale}
                        />
                      )}
                      <Slider
                        value={scale}
                        min={1}
                        max={3}
                        step={0.01}
                        onChange={(e: any, newValue: any) => setScale(newValue)}
                      />
                      <Button color="primary" onClick={handleSave}>Upload</Button>
                      <Button color="primary" onClick={() => setEditorOpen(false)}>Cancel</Button>
                    </div>
                  </Dialog>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.2rem", margin: "2px 0px" }}>
                  <span>Hello, {employeeData && employeeData?.results && employeeData?.results[0]?.fullname}</span>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>

                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >

                  <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setchangePasswordModal(true)}
                  >
                    Change Password
                  </Button>
                  {/* <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setChangeEmailModal(true)}
                  >
                    Change Email Id
                  </Button>
                  <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setChangeMobileNumber(true)}
                  >
                    Update Mobile Number
                  </Button> */}
                  <Button
                    onClick={() => handleLogout()}
                    style={{ background: "rgb(14, 63, 108)", color: "white", width: "100%", margin: "10px 0px" }}
                  >
                    Logout
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)} >
                Change Password
              </MenuItem>
              <MenuItem onClick={() => {

                handleLogout()
                // }
              }}>Logout</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changePasswordModal}
            onClose={() => setchangePasswordModal(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",
                maxWidth: "none",
              },
            }}
          >
            <DialogTitle id="form-dialog-title" style={{ display: "flex", justifyContent: "space-around" }}>Change Password</DialogTitle>
            {/* <DialogContent>
              <TextField
                id="name"
                placeholder="Old Password"
                type={showOldPassword ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  padding: "14px !important",
                }}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowOldPassword((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
              <TextField
                id="name"
                placeholder="New Password"
                type={showNewPassword ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  // padding: "14px !important",
                }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowNewPassword((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
              <TextField
                id="name"
                placeholder="Confirm Password"
                type={showNewPasswordRepeat ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  padding: "14px !important",
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowNewPasswordRepeat((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showNewPasswordRepeat ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updatePassword()} disabled={oldPassword?.length === 0 || newPassword?.length < 7 || newPassword !== confirmPassword} variant="contained" color="primary"  >
                Update
              </Button>

            </DialogActions> */}
            <>
              <div className={classes.form}>
                <TextField
                  inputProps={{
                    style: {
                      height: "2.4375em",
                    },
                  }}
                  id="outlined-number"
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  type="number"
                  value={phone}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(e) => setphone(e.target.value)}
                  style={{
                    width: "100%",
                    margin: "1rem auto",
                    padding: "0px 8px 0px 0px",
                    height: "2.4375em",
                  }}
                />



                {clickOtp && (
                  <>

                    <TextField
                      inputProps={{
                        style: {
                          height: "2.4375em",
                        },
                      }}
                      id="outlined-number"
                      label="OTP"
                      name="otp"
                      type="text"
                      variant="outlined"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "1rem auto",
                        padding: "0px 8px 0px 0px",
                        height: "3em;",
                      }}
                      disabled={(otpSent && otpSent.status)}
                    />
                    <div style={{ height: "4.4375em" }}>

                      <TextField
                        disabled={!otp || !phone}
                        inputProps={{
                          style: {
                            height: "2.4375em",
                          },
                        }}
                        id="outlined-password-input"
                        label="Set Password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={newPassword}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <div
                              onClick={() =>
                                setShowPassword(
                                  (previousState: any) => !previousState
                                )
                              }
                            >
                              <InputAdornment position="end">
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </InputAdornment>
                            </div>
                          ),
                        }}
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "1rem auto",
                          padding: "0px 8px 0px 0px",
                          height: "2.4375em",
                        }}
                      // disabled={!(otpSent && otpSent.status)}
                      />
                    </div>

                  </>
                )}
                <div className={classes.wrapper} >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingResetOTP || !phone}
                    className={classes.submit}
                    onClick={() => {
                      sendOtpHandler();
                      setClickOtp(true);
                    }}
                    style={{
                      backgroundColor:
                        loadingResetOTP || !phone ? "#7b8794" : "#525354",
                      color: "white",

                    }}
                  >
                    Send OTP
                  </Button>
                </div>
                <div className={classes.wrapper} >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!otp || !newPassword}
                    className={classes.submit}
                    onClick={() => resetPasswordHandler()}
                    style={{ fontWeight: "500", marginTop: ".5rem" }}
                  >
                    Update Password
                  </Button>
                  {loadingReset && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>

              </div>
            </>
          </Dialog>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changeEmailModal}
            onClose={() => setChangeEmailModal(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",

                maxWidth: "none",
              },
            }}
          >
            <DialogTitle >Update Email</DialogTitle>
            <DialogContent>
              <div >
                <TextField
                  placeholder='Email address'
                />
              </div>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updateEmail()} variant="contained" color="primary" style={{ backgroundColor: "#EA4C89" }}  >
                Update Email
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changeMobileNumber}
            onClose={() => setChangeMobileNumber(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",
                maxWidth: "none",
              },
            }}
          >
            <DialogTitle >Update Mobile Number</DialogTitle>
            <DialogContent>
              <TextField style={{ padding: "5px 15px" }}
                placeholder='Enter Mobile Number'
              />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updateMobileNumber()} variant="contained" color="primary" style={{ backgroundColor: "#EA4C89" }}  >
                Update Mobile Number
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/payrollManager">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#413c9f" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>

        <List>

          <Link to="/dashboard/payrollManager/employee-list">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Employee List" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <GroupsIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Employee List" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollManager/employee-master">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Employee Master" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <PersonAddAlt1Icon
                    // fontSize="small"
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Employee Master" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollmanager/designation">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Designation" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <AutoAwesomeMosaicIcon
                    // fontSize="small"
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Designation" />
            </ListItem>
          </Link>
        </List>
        
        <List>
          <Link to="/dashboard/payrollManager/leave">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Manage Leave" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <TimeToLeaveIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Leave" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollManager/employee_location">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Manage Employee Location" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <PunchClockIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Employee Location" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollManager/employee_report">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Employee Report" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <SummarizeIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Employee Report" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollManager/manage-attendance">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Manage Attendance" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <ManageAccountsIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Attendance" />
            </ListItem>
          </Link>
        </List>

        {/* <List>
          <Link to="/dashboard/payrollManager/weekly-roster">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Weekly Roster" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <CalendarViewWeekIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Weekly roster" />
            </ListItem>
          </Link>
        </List> */}

       

        <List>
          <Link to="/dashboard/payrollManager/Attendance-Master">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Attendance Master" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <CoPresentIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Attendance Master" />
            </ListItem>
          </Link>
        </List>
       
        <List>
          <Link to="/dashboard/payrollManager/bulk-uploader">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Bulk Uploader" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <CloudUploadIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Bulk Uploader" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/payrollManager/bulk-leave-uploader">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Leave Bulk Uploader" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <CloudUploadIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leave Bulk Uploader" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/payrollManager/update-BulkUpload">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Update Bulk Upload" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <UpdateIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Update Bulk Upload" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollmanager/password_reset">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Password Reset" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <LockResetIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Password Reset" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/payrollManager/request-center">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Request Center" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <ContactMailIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Request Center" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/payrollmanager/regulization_limit">
            <ListItem button className={classes.menuItem}>
              <Tooltip title="Regulization limit" arrow>
                <ListItemIcon className={classes.menuItemIcon}>
                  <LockResetIcon
                    style={{ color: "#413c9f" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Regulization limit" />
            </ListItem>
          </Link>
        </List>
        <Divider /> 
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#413c9f" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage && notificationMessage?.results && notificationMessage?.results?.length > 0 ? notificationMessage?.results.map((data: any, index: any) => {
        return (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{index + 1 + "." + data?.notification}</Typography>
          </Popover>

        )
      }) : null}

    </div>
  );
}
const mapStateToProps = (state: any) => ({
  notificationMessage: state.PayrollReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
  employeeData: state.PayrollReducer.employeeData,
  getProfileImage: state.PayrollReducer.getProfileImage,
  postProfileImage: state.PayrollReducer.postProfileImage,
})


export default connect(mapStateToProps, {
  getEmployeeData,
  getUserProfileImage,
  updateImage,
})(PhlebopayrollManager);
