import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
    TextField,
    Grid,
    Modal,
    Fade,
    Box,
    Card,
} from "@material-ui/core";
import {
    getEmployeeList,
    getEmployeeDepartment,
    getLocationList,
} from "../../../actions/loginActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../Loader2";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateEmployeeFilter } from "../../../helper/generate";
import { useHistory } from "react-router";
import CustomDrawer from "../../../common/CustomDrawer";
import Button from "@mui/material/Button";
import "./index.css"

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableFooter,
    TablePagination,
} from "@material-ui/core";
import { getEmployeFilterList, updateRegularizationCount } from "../../actions/PayrollActions";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid green",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: "50px",
            width: "600px",
            textAlign: "center"
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    loading: boolean;
    employeesList: any;
    getEmployeeList: any;
    getEmployeeDepartment: Function;
    departmentNames: any;
    locationList: any;
    getLocationList: any;
    employeesFilterList: any;
    getEmployeFilterList: any;
    updateRegularizationCount: any;
}
const Forms: React.FC<Props> = ({
    loading,
    employeesList,
    getEmployeeList,
    getEmployeeDepartment,
    departmentNames,
    locationList,
    getLocationList,
    employeesFilterList,
    getEmployeFilterList,
    updateRegularizationCount,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [employeeUUid, setEmployeeUuid] = useState("");
    const [openAssignLimitModel, setOpenAssignLimitModel] = useState<boolean>(false);
    const [employeeDept, setEmployeeDept] = useState<any>("");
    const [location, setLocation] = useState<any>();
    const [selectedLeads, setSelectedLeads] = useState<any>([]);
    const [limit, setLimit] = useState<any>();
    useEffect(() => {
        getEmployeeList("get_all=true");
        getEmployeeDepartment();
        getLocationList();
        getEmployeFilterList("get_all=true")
    }, []);

    const handleFilter = () => {
        const body: any = {
            employee_id: employeeUUid,
            department: employeeDept !== 64 ? employeeDept : "",
            location: location
        };

        const url = generateEmployeeFilter(body).substring(2);
        getEmployeeList(`get_all=true&${url}`);
        setPage(0);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                employeesList.links && employeesList.links.next.split("?")[1];

            getEmployeeList(`${url}`);
        } else if (newPage < page) {
            let url =
                employeesList.links && employeesList.links.previous.split("?")[1];

            getEmployeeList(`${url}`);
        }
        setPage(newPage as number);
    };

    const handleSubmit = async (e: any) => {
        const data = {
            employee_id: selectedLeads,
            count: limit,
        }
        await updateRegularizationCount(data);
        setOpenAssignLimitModel(false);
        history.push("/dashboard/payrollmanager/regulization_limit")
    };

    const checkedLead = (event: any, leadId: number) => {
        !event.target.checked
            ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
            : setSelectedLeads((prev: any) => [...prev, leadId]);
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h4
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(28, 78, 128)",
                    color: "#fff",
                    textAlign: "center",
                    padding: "10px",
                    borderRadius: "15px",
                }}
            >
                Regularization Limit
            </h4>

            <div>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeDept(obj.id);
                                } else {
                                    setEmployeeDept("");
                                }
                            }}
                            id="department-name"
                            options={departmentNames || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeeDepartment(`name=${newInputValue}`);
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option.name;
                            }}
                            renderOption={(option: any) => {
                                return option?.name;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Department Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeUuid(obj.employee_id);
                                } else {
                                    setEmployeeUuid("");
                                }
                            }}
                            id="checkboxes-tags-demo"
                            options={employeesFilterList?.results || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeFilterList(
                                            `get_all=true&employee_id=${newInputValue}`
                                        );
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option?.employee_id + " " + option.name;
                            }}
                            renderOption={(option: any) => {
                                return (
                                    <p>
                                        <b style={{ color: "blue" }}>Employee Name:</b>
                                        <strong>
                                            {option?.name.toString()}
                                            <br />
                                        </strong>
                                        <b style={{ color: "red" }}>Employee Id:</b>
                                        {option?.employee_id?.toUpperCase()}
                                    </p>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Search By Employee Code Or Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(
                                event,
                                obj
                            ) => {
                                if (obj) {
                                    const loc =
                                        obj.location;
                                    setLocation(loc);
                                } else {
                                    setLocation("");
                                }
                            }}
                            id={"City"}
                            options={
                                locationList || []
                            }

                            onInputChange={(
                                event,
                                newInputValue
                            ) => {
                                clearTimeout(
                                    timer.current
                                );
                                timer.current =
                                    setTimeout(() => {
                                        getLocationList(
                                            `name=${newInputValue}`
                                        );
                                    }, 1000);
                            }}
                            value={location ? { location: location } : null}
                            getOptionLabel={(
                                option: any
                            ) => {
                                return option.location;
                            }}
                            renderOption={(
                                option: any
                            ) => {
                                return option.location;
                            }}
                            renderInput={(
                                params
                            ) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Location"
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (
                                            e
                                        ) => {
                                            if (
                                                e.key ===
                                                "Enter"
                                            ) {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleFilter}
                            startIcon={<SearchIcon />}
                            disabled={loading}
                            style={{
                                backgroundColor: "#1976d2",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#1565c0";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#1976d2";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            startIcon={<RotateLeftIcon />}
                            style={{
                                backgroundColor: "#9c27b0",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#7b1fa2";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#9c27b0";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                            onClick={() =>
                                history.push("/dashboard/payrollmanager/regulization_limit")
                            }
                        >
                            Reset
                        </Button>
                    </Grid>

                </Grid>

                <div
                    style={{
                        padding: "10px",
                        marginTop: "2.5rem",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        backgroundColor: "#f8d7da",
                        display: "inline-block",
                        fontSize: "16px",
                        color: "#721c24",
                    }}
                >
                    <span>
                        👉 Please select the checkbox to enable the Update Regularization Button.
                    </span>
                </div>

                {selectedLeads.length > 0 && <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={selectedLeads?.length === 0 ? true : false}
                        style={{
                            backgroundColor: selectedLeads?.length === 0 ? "gray" : "#009688",
                            color: "#ffffff",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "none",
                            transition: "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "none",
                            marginTop: "10px"
                        }}
                        onClick={() => { setOpenAssignLimitModel(true) }}
                    >
                        Update Regularization Limit
                    </Button>
                </Grid>}
                <TableContainer
                    className={classes.tableContainer}
                >
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    <input
                                        type="checkbox"
                                        className="input"
                                        name="selectall"
                                        id="selectall"
                                        style={{ height: "16px", width: "16px", cursor: "pointer" }}
                                        onChange={(e) => {
                                            e.target.checked
                                                ? setSelectedLeads(
                                                    employeesList?.results &&
                                                    employeesList?.results.map(
                                                        (data: any, index: number) => {
                                                            return data?.employee_id;
                                                        }
                                                    )
                                                )
                                                : setSelectedLeads([])
                                        }
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">Employee Name</StyledTableCell>
                                <StyledTableCell align="center">Employee ID</StyledTableCell>
                                <StyledTableCell align="center">Work Location</StyledTableCell>
                                <StyledTableCell align="center">Department</StyledTableCell>
                                {/* <StyledTableCell align="center">Is Limit Assigned</StyledTableCell> */}
                                <StyledTableCell align="center">Regularization count</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {loading ? (
                            <Loader />
                        ) : (
                            <TableBody>
                                {employeesList &&
                                    employeesList.results &&
                                    employeesList.count > 0 &&
                                    employeesList.results.map((data: any, index: any) => {
                                        return (

                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    <input
                                                        type="checkbox"
                                                        name="selectall"
                                                        id="selectall"
                                                        style={{ height: "16px", width: "16px", cursor: "pointer" }}
                                                        checked={selectedLeads.find(
                                                            (qa: any) => qa === data.employee_id
                                                        )}
                                                        onChange={(e) => checkedLead(e, data.employee_id)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.fullname || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.employee_id || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.location !== null
                                                        ? data.location
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.department || "NA"}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                          {data?.regularization_count !== undefined && data?.regularization_count !== null && data?.regularization_count !== 0 ? "Yes" : "NO"}
                        </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {data?.regularization_count}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        )}
                        <TableFooter>
                            <StyledTableRow style={{ textAlign: "center" }}>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={employeesList?.count || 0}
                                    rowsPerPage={employeesList?.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <CustomDrawer
                    drawerTitle={"Assign Regularization Limit"}
                    open={openAssignLimitModel}
                    toggleDrawer={() => setOpenAssignLimitModel(false)}
                >
                    <div>
                        <Card className="p_40">
                            <form style={{ marginTop: "0px" }} onSubmit={handleSubmit} >
                                <Grid
                                    container
                                    spacing={4}
                                    direction="row"
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <TextField
                                            name="limit"
                                            label="Limit"
                                            className="input"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            value={limit}
                                            onChange={(e) => { setLimit(e.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={handleSubmit}
                                            disabled={!limit}

                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </div>
                </CustomDrawer>
            </div>
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    employeesList: state.loginReducer.employeesList,
    departmentNames: state.loginReducer.departmentNames,
    locationList: state.loginReducer.locationList,
    employeesFilterList: state.PayrollReducer.employeesFilterList,
    loading: state.loginReducer.loading,
});
export default connect(mapStateToProps, {
    getEmployeeList,
    getEmployeeDepartment,
    getLocationList,
    getEmployeFilterList,
    updateRegularizationCount,
})(Forms);
