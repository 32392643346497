import React from "react";
import {
  Box,
  Avatar,
  AvatarGroup,
  TextField,
  FormControl,
  Autocomplete,
  Button,
  Card,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { SnackbarProvider } from "notistack";
import CustomToaster from "../../../../common/CustomSnack";

interface UpdateAttendenceProps {
  formData: (data: FormValues) => void;
  handletoggle: () => void;
  handleAddAttendence: () => void;
}

type FormValues = {
  from_date: string;
  check_in: string;
  check_out: string;
  regularization_category: string;
  comments: string;
};

const schema = Yup.object().shape({
  from_date: Yup.string().required("Date is required"),

  check_in: Yup.string().required("Select in Time is required"),

  check_out: Yup.string().required("Select out Time is required"),
  regularization_category: Yup.string().required("Work Location is required"),
  comments: Yup.string()
    .required("Reason is required")
    .test('no-leading-space-or-period', 'Cannot start with space or period', value => {
      return !(value && /^[. ]/.test(value));
    }),
});

const UpdateAttendence: React.FC<UpdateAttendenceProps> = ({
  formData,
  handletoggle,
  handleAddAttendence,
}) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      from_date: "",
      check_in: "",
      check_out: "",
      regularization_category: "",
      comments: "",
    },

    validationSchema: schema,
    onSubmit: (values) => {
      const formattedCheckIn = `${values.from_date}T${values.check_in}`;
      const formattedCheckOut = `${values.from_date}T${values.check_out}`;
      const formattedDate = `${values.from_date}`;

      // Calculate the time difference
      const checkInTime = moment(formattedCheckIn);
      const checkOutTime = moment(formattedCheckOut);
      const duration = moment.duration(checkOutTime.diff(checkInTime));
      const hoursDifference = duration.asHours();
      const dataWithUuid = {
        ...values,
        id: uuidv4(),
        check_in: formattedCheckIn,
        check_out: formattedCheckOut,
        date: formattedDate,
      };
      if (hoursDifference < 2) {
        alert("The difference between Check-in and Check-out must be at least 2 hours.");
      }
      else {
        formData(dataWithUuid);
        handleAddAttendence();
      }
    
      
    },
  });

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split("T")[0];

  const getJune21st = () => {
    const today = new Date();
    const year = today.getFullYear();
    return new Date(year, 5, 21).toISOString().split("T")[0]; // June is month 5 in JavaScript Date object
  };

  const june21st = getJune21st();

  return (
    <Card className="shadow">
      <form
        onSubmit={formik.handleSubmit}
        className="w-100 border m-0 p-3 d-flex align-items-center justify-content-between"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <AvatarGroup max={4} className="my-2">
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
          </AvatarGroup>

          <Box mt={2} sx={{ width: "100%" }}>
            <TextField
              name="from_date"
              label="Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              className="input"
              variant="outlined"
              inputProps={{
                min: june21st,
                max: formattedYesterday,
                value: formik.values.from_date, // Ensure value is passed here
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
              error={!!formik.errors.from_date && formik.touched.from_date}
              helperText={formik.touched.from_date && formik.errors.from_date}
            />
          </Box>

          {formik?.values?.from_date && (
            <>
              <div
                className="border text-secondary mt-3 p-2 rounded"
                style={{
                  background: "#FFFF8F",
                }}
              >
                <p
                  className="p-0 m-0 text-subtitle"
                  style={{
                    fontSize: "0.9em",
                    fontWeight: 500,
                  }}
                >
                  Shift Range
                </p>
                <p
                  className="p-0 m-0"
                  style={{
                    fontSize: "0.9em",
                    fontWeight: 500,
                  }}
                >
                  5:00AM to 3:59 AM
                </p>
              </div>

              <Box mt={2} sx={{ width: "100%" }}>
                <TextField
                  name="selectInDate"
                  label="Select in Date"
                  className="input"
                  variant="outlined"
                  type="date"
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formik?.values?.from_date}
                />
              </Box>

              <Box mt={2} sx={{ width: "100%" }}>
                <TextField
                  name="check_in"
                  label="Select in Time"
                  className="input"
                  variant="outlined"
                  type="time"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.check_in}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.check_in && formik.touched.check_in}
                  helperText={formik.touched.check_in && formik.errors.check_in}
                />
              </Box>

              <Box mt={2} sx={{ width: "100%" }}>
                <TextField
                  name="selectOutDate"
                  label="Select out Date"
                  className="input"
                  variant="outlined"
                  type="date"
                  disabled
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.from_date}
                />
              </Box>

              <Box mt={2} sx={{ width: "100%" }}>
                <TextField
                  name="check_out"
                  label="Select out Time"
                  className="input"
                  variant="outlined"
                  type="time"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.check_out}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.check_out && formik.touched.check_out}
                  helperText={
                    formik.touched.check_out && formik.errors.check_out
                  }
                />
              </Box>

              <Box mt={2} sx={{ width: "100%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={["work_from_home", "work_from_office"]}
                    value={formik.values.regularization_category}
                    onChange={(event, value) =>
                      formik.setFieldValue("regularization_category", value)
                    }
                    onBlur={() =>
                      formik.setFieldTouched("regularization_category", true)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Work Location"
                        variant="outlined"
                        className="input"
                        error={
                          !!formik.errors.regularization_category &&
                          formik.touched.regularization_category
                        }
                        helperText={
                          formik.touched.regularization_category &&
                          formik.errors.regularization_category
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <Box mt={2} sx={{ width: "100%" }}>
                <TextField
                  name="comments"
                  label="Response for Request"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={4}
                  fullWidth
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.comments && formik.touched.comments}
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Box>
              <Box className="d-flex align-items-center justify-content-center w-100 gap-4 mt-4">
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="mx-2"
                  fullWidth
                  onClick={handletoggle}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </form>
    </Card>
  );
};

export default UpdateAttendence;
